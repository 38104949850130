import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Button, Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns'; 
import {   MuiPickersUtilsProvider,KeyboardTimePicker } from "@material-ui/pickers";
import getToken from 'helpers/auth';
import useForm from 'react-hook-form';
import   { useEffect , useState } from 'react';
import Swal from 'sweetalert2';
import EditIcon from "@material-ui/icons/EditLocation";
import DeleteIcon from "@material-ui/icons/Delete";


import MaterialTable from 'material-table';

import { postForm } from 'js/actions/formsAction';
import { useDispatch } from 'react-redux';
import styles from './styles';

import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TextField
} from '@material-ui/core';
import { SettingsPower } from '@material-ui/icons';

const useStyles = makeStyles(styles);


export default function NavTabs(props) {
  const TIPO_FACTURACION=4;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const alert = props.alert||null 
  console.log("alerta de props ")
  console.log(alert);
  const dias_sem=[ 'Lun' , 'Mar' , 'Mie' , 'Jue' , 'Vie' , 'Sab' , 'Dom' ];

  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [selectedDate2, handleDateChange2] = React.useState(new Date());

  const [type, setType] = React.useState();

  const [days, setDays] = React.useState([]);

  const [types, setTypes] = useState([]);

  const [maxValue, setMaxValue] = React.useState('');


  const sendRules = rules => {
     console.log("rules son :");
     console.log(rules);
     if(rules==null || rules.length <=0 ){

      Swal.fire({
        title: 'Debe crear al menos una regla '  ,
        text: ``,
        icon: 'error',
      }) ;

      return;

     }


     dispatch(postForm(rules));
     props.nextStep();
    //console.log(rules)
  };

  const [state, setState] = React.useState({
    data: [],
    columns: [
      { title: 'Tipo', field: 'type' ,
      render: rowData =>{ if( rowData.type==1 ) {  return 'Corriente' } else if(  rowData.type==2  ) { return  'Potencia'   }else if( rowData.type==TIPO_FACTURACION ){ return 'Facturación' } else   { return '' }  }
    
    },
      { title: 'Dias', field: 'days'  ,  
      render: rowData =>{  if( rowData.days ){ return  rowData.days.map( dayVal => dias_sem[dayVal-1]+ " "  );    }else { return '' }    }},
      { title: 'Valor Mínimo', field: 'min_value' },      
      { title: 'Valor Máximo', field: 'max_value' },
      { title: 'Hora Inicio', field: 'hour_ini' },
      { title: 'Hora Final', field: 'hour_end' }
    ]
  });

  const [rule, setRule] = React.useState({
    data: []
  });



  const actions = [

{
    icon: 'edit',
    tooltip: 'Editar Usuario',
    onClick: (event, rowData) => {
        console.log( rowData )
        setType(rowData.type)   
        setDays(rowData.days  )
        setMaxValue( rowData.max_value  );
    }
  }

];






  useEffect(() => {
    if( alert ){
       if( alert.rules ){

        setState({
          ...state,
          data: alert.rules
        })

        setRule({
          ...state,
          data: alert.rules
        })
          

       }

    }

    dispatch(loadCatalog());

  }, []);



  const loadCatalog = (  ) => {
    return async dispatch => {
       
      axios.get(
        `https://api.metimur.mx/api/alerts/catalog` ,              
        {
          headers: { Authorization: 'Bearer ' + getToken() }
        }
      ) .then(response => {                  
          console.log(response.data.data);    
          setTypes(response.data.data);
      })
      
      .catch(error => {
        //dispatch(postUserAlertFail(error.message));
         //console.log('ERROR API:' + error);
      });      
  
  
  
  
    };
  };
  
  




  const onSubmit = (values,e) => {
    // console.log(values)
    const { days } = values;
    let arDays = [];

    console.log(values);
    console.log("Valores:")
    
    if( days  ){
      arDays= days.split(',');
    }

    let obj = {
      type: values.type,
      max_value: values.max_value,
      min_value: values.min_value,
      hour_ini: values.hour_ini,
      hour_end: values.hour_end,
      days: arDays
    };

    setState({
      ...state,
      data:[...state.data,obj]
    })

    setRule({
      data: [...rule.data, obj]
    });

    e.target.reset();

    setType(null)
    setDays([])
    handleDateChange(new Date())
    handleDateChange2(new Date())


  };

 
  const handleChangeMaxValue = (event) =>{
       setMaxValue( event.target.value );
  }

  const handleChange = event => {
    setType(event.target.value);
  };

  const handleChangeDays = event => {
    setDays(event.target.value);
  };


  const renderDias = ()=>{

      if( type==4  ){
         return null;
      }else {

           return (
            <Grid item lg={1.5} md={2} sm={2}>
            <FormControl className={classes.textField} required>
              <InputLabel id="demo-simple-select-label">Dias</InputLabel>
              <Select
                inputRef={register}
                labelId="demo-simple-select-disabled-label"
                name="days"
                onChange={handleChangeDays}
                value={Object.values(days)}
                multiple>
                <MenuItem value="">
                  <em>Seleccionar Dias</em>
                </MenuItem>
                <MenuItem value={1}>Lunes</MenuItem>
                <MenuItem value={2}>Martes</MenuItem>
                <MenuItem value={3}>Miercoles</MenuItem>
                <MenuItem value={4}>Jueves</MenuItem>
                <MenuItem value={5}>Viernes</MenuItem>
                <MenuItem value={6}>Sabado</MenuItem>
                <MenuItem value={7}>Domingo</MenuItem>
              </Select>
            </FormControl>
          </Grid>


           );




      }




  }




  const renderValorMinimo=()=>{
     
    if( type==4  ){
      return null;
    }else {

       return (

        <Grid item lg={1.5} md={2} sm={2}>
        <TextField
          className={classes.textField}
          inputRef={register}
          label="Valor Mínimo"
          name="min_value"
          required
          variant="outlined"
        />
      </Grid>        



       );

      


    }


  }


  const renderHoraIni=()=>{
    if( type==4  ){
      return null;
    }else {
       return (

        <Grid item lg={1.5} md={2} sm={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              ampm={false}
              value={selectedDate}
              label="Hora Inicial"
              inputRef={register}
              name='hour_ini'
              onChange={date => handleDateChange(date)}
            />
            </MuiPickersUtilsProvider>
          </Grid>         


       );


    }


  }



  const renderHoraFin=()=>{
    if( type==4  ){
      return null;
    }else {
      return (
        <Grid
          item
          lg={1.5}
          md={2}
          sm={2}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              ampm={false}
              inputRef={register}
              label="Hora Final"
              name="hour_end"
              onChange={date => handleDateChange2(date)}
              value={selectedDate2}
            />
          </MuiPickersUtilsProvider>
        </Grid>        

      


       );


    }


  }  



  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width:'100%'}}>
        <Grid container spacing={3}>
          <Grid item lg={1.5} md={2} sm={2}>
            <FormControl className={classes.textField} required>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                inputRef={register}
                labelId="demo-simple-select-disabled-label"
                name="type"
                onChange={handleChange}
                value={type}>
                <MenuItem value="">
                  <em>Seleccionar Tipo</em>
                </MenuItem>
                {types.map( t => (
                  <MenuItem value={t.id}>{t.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {
            renderDias()
          }



          {
            renderValorMinimo()
          }

          <Grid item lg={1.5} md={2} sm={2}>
            <TextField
              className={classes.textField}
              inputRef={register}
              label="Valor Máximo "
              name="max_value"
              onChange={handleChangeMaxValue}
              required
              value={maxValue}
              variant="outlined"
            />
          </Grid>          

          
          {
            renderHoraIni()
          }


          {
            renderHoraFin()
          }
          

          <Grid item lg={12} md={12} sm={12}>
            <Button
              color="secondary"
              size="large"
              type="submit"
              variant="outlined"
              //className={classes.button}
              //onClick={setTable}
              className={classes.textField}
              >
              Guardar Regla
            </Button>
          </Grid>
        </Grid>
      </form>
      <MaterialTable
        style={{width:'100%', marginTop:'30px'}}
        localization={{

          header: {
            actions: "Acciones"
          },

          body: {
            editRow: {
              saveTooltip: "Salvar",
              cancelTooltip: "Cancelar",             
              deleteText: "Está seguro que desea eliminar la regla ?"
            },
            addTooltip: "Agregar",
            deleteTooltip: "Eliminar",
            deleteIcon: 'delete',
            editTooltip: "Editar"
          }
        }}
        icons={{
        
          Delete: () => <DeleteIcon  />
        }}
        data={state.data}
        columns={state.columns}
        options={{ toolbar: false }} 
        actions={actions}
        editable={{

         
          
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.splice(data.indexOf(oldData), 1);
                setState({ ...state, data });                
                setRule({
                  data  
                });

              }, 600);
            })
        }}
      />


       <Button
        color="secondary"
        size="large"
        //type="submit"
        disabled= {!rule.data.length}

        style={{ marginTop:'20px'}}
        onClick={() => sendRules(rule.data)}>
        Guardar Y Continuar
      </Button> 
    </Grid>
  );
}
