/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';

// Material components
import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  Link as LinkMaterial,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import { getWeatherInRange } from 'js/actions/meterActions.js';

import {
  getGeneralReport,
  getGeneralConsume,
  getVoltage,
  fpValidation
} from 'js/actions/chargeDashboardActions';

// Custom components
import {
  Budget,
  Users,
  Profit,
  ConsumptionAndDemandChart,
  ConsumptionAndWeightChart,
  MonthWithMostConsumeChart,
  ComparativeYearConsumeChart,
  DayWithMostConsumeChart,
  AlertCard,
  GeneralReport,
  DailyConsumptionChart,
  GeneralReportDemand,
  DemandPerDayChart,
  Receipt,
  VoltageAnalysis,
  ElectricCurrent,
  LoadFactor,
  ElectricAnalysis,
  HistoricalReport,
  AccumulatedConsumption,
  ConsumptionPerDayChart,
  Temperature,
  GeneralReportAngle,
  ReactiveVsActive,
  OnOff,
  FPDailyAvrgChart,
  FPGauge
} from './components';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import RepAveragePerDayOfWeek from './components/RepAveragePerDayOfWeek';
import TotalsHourEnergy from './components/TotalsHoursEnergy';
import TotalesPorDia from './components/TotalesPorDia';
import { useIntl } from 'react-intl';
import theme from 'theme';
import { isAllowed } from 'helpers';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { getChargeOwnerData } from 'js/actions/chargeActions';
import Swal from 'sweetalert2';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { es, he } from 'date-fns/locale';
import { DatePick } from 'components/DatePicker';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4,
    position: 'relative'
  },
  item: {
    height: '100%'
  },
  breadcrumb: {
    fontSize: '1em',
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit
  },
  bugets: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gap: '0',
      flexWrap: 'wrap'
    }
  },
  itemsBugets: {
    height: '100vh',
    maxHeight: '135px',
    width: '19.5%',
    [theme.breakpoints.down('md')]: {
      width: '49.5%',
      marginBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  lastItemBugets: {
    height: '100vh',
    maxHeight: '135px',
    width: '19.5%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing.unit
    }
  },
  notification: {
    position: 'absolute',
    top: '.5%',
    right: '1%',
    [theme.breakpoints.down('lg')]: {
      right: '1.5%'
    },
    [theme.breakpoints.down('md')]: {
      right: '2.5%'
    },
    [theme.breakpoints.down('sm')]: {
      right: '4%',
      top: '.3%'
    }
  }
});

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { groupId, chargeId } = props.match.params;
  const { charge } = props.location.state;
  const rateHorary = useSelector(state => state.chargeDashboard.generalConsumes.rate_horary);
  const group =
    props.location && props.location.state ? props.location.state.group : null;
  const [heatMap, setHeatMap] = useState({ fee: true, hm: false });
  const [valueInfo, setValueInfo] = useState({ kwh: true, money: false });
  const [chargeVoltageId, setChargeVoltageId] = useState(null);
  const ownerName = useSelector(state => state.charges.chargeOwner);
  const [month, setMonth] = useState();
  const [yearChange, setYearChange] = useState();
  const [changeMonthYear, setChangeMonthYear] = useState(new Date());

  const fp = useSelector(state => state.chargeDashboard.fpValidation)
  const { weather } = useSelector(state => state.meters);

  const formatDate = date => {
    const padToTwoDigits = num => {
      return num.toString().padStart(2, '0');
    };
    const day = padToTwoDigits(date.getDate());
    const month = padToTwoDigits(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${year}${month}${day}`;
  };

  const year = new Date().getFullYear();

  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    } else {
      return month;
    }
  };

  const getLastMonth = () => {
    let month = new Date().getMonth() + 1;

    return `0${month - 1}`;
  };

  let obj = {
    id: charge.id,
    concentrator: charge.id_data.substring(0, charge.id_data.indexOf('_')),
    name: charge.name,
    groupId: groupId,
    bas_kw: Math.round(charge.consumes[0][1] * 100) / 100,
    int_kw: charge.consumes[0][2],
    punt_kw: charge.consumes[0][3],
    bas_kwh: charge.consumes[1][1],
    int_kwh: charge.consumes[1][2],
    punt_kwh: charge.consumes[1][3]
  };

  const user = useSelector(state => state.user.user);
  const concentratorId = obj.concentrator;

  const ids = { concentratorId, chargeId };

  useEffect(() => {
    dispatch(getChargeOwnerData(charge ? charge.id : null));
  }, [charge]);

  useEffect(() => {
    dispatch(getGeneralReport(ids, year, getCurrentMonth()));
  }, []);

  useEffect(() => {
    dispatch(getGeneralConsume(chargeId, year, getCurrentMonth()));
  }, []);

  useEffect(()=> {
    if(rateHorary === 1) {
      setHeatMap({ fee: true, hm: false })
    } else {
      setHeatMap({ fee: false, hm: true })
    }
  },[rateHorary])

  const corpAdmin = useSelector(state => state.user.corporative);
  const corpUser = user.corporative;
  const corporativeId =
    corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id;

  const meterAdmin = useSelector(state => state.user.meter);
  const meterUser = user.meters;
  const meterId =
    meterUser === undefined || meterUser === null ? meterAdmin : meterUser.id;

  const meter_id = meterUser ? meterUser : meterAdmin;
  const [activeCharges, setActiveCharges] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
  const [inactiveCharges, setInactiveCharges] = useState(0);
  const meterInfo = useSelector(state => state.user.user.meters);

  const storedCorpName = localStorage.getItem(`corpName_${corporativeId}`);
  const storedMeterName = localStorage.getItem(`meterName_${meterId}`);
  const storedGroupName = localStorage.getItem(`groupName_${groupId}`);

  const meses = []

  useEffect(() => {
    if (meterUser && meterUser.name) {
      localStorage.setItem(`meterName_${meterId}`, meterUser.name);
    }
  }, [meterUser, meterId]);

  useEffect(() => {
    if (corpAdmin && corpAdmin.name) {
      localStorage.setItem(`corpName_${corporativeId}`, corpAdmin.name);
    }
  }, [corpAdmin, corporativeId]);

  useEffect(() => {
    if (group && group.name) {
      localStorage.setItem(`groupName_${groupId}`, group.name);
    }
  }, [group, groupId]);

  const corpName =
    corpAdmin && corpAdmin.name ? corpAdmin.name : storedCorpName;
  const meterArray =
    meterUser === undefined || meterUser[0] === null
      ? false
      : meterUser[0]
        ? meterUser[0]
        : meterUser[1];
  const meterName =
    meterUser && meterUser.name ? meterUser.name : storedMeterName;
  const meterNamed = meterName || meterArray.name;
  const groupName = group && group.name ? group.name : storedGroupName;

  useEffect(() => {
    dispatch(getVoltage(chargeId, configVoltageChage));
  }, []);

  useEffect(() => {
    dispatch(fpValidation(chargeId));
  }, [])

  const configVoltageChage = data => {
    if (!data) {
      setChargeVoltageId(null);
      return;
    }
    if (data.has_voltage == 1) {
      setChargeVoltageId(data.charge_voltage);
    } else {
      setChargeVoltageId(null);
    }
  };

  const voltageValidation = chargeVoltageId ? 4 : 6;

  const handleHistory = (date) => {
    setChangeMonthYear(date);

    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const year = date.getFullYear();

    setMonth(month);
    setYearChange(year);
    
    dispatch(getGeneralReport(ids, year, month));
    dispatch(getGeneralConsume(chargeId, year, month))
  }

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <Button
        className={className}
        color="secondary"
        onClick={onClick}
        ref={ref}
        size="large"
        variant="contained">
        {value}
      </Button>
    ),
  );

  const handleNextMonth = () => {
    const currentDate = new Date(changeMonthYear);
    let nextMonth = currentDate.getMonth() + 2 > 9 ? currentDate.getMonth() + 2 : `0${currentDate.getMonth() + 2}`; // Obtener el siguiente mes
    let year = currentDate.getFullYear();
  
    // Si estamos en diciembre, avanzamos al próximo año
    if (nextMonth > 12) {
      nextMonth = '01'; // Volver a enero
      year += 1;     // Incrementar el año
    }
  
    const nextDate = new Date(year, nextMonth - 1);
    setChangeMonthYear(nextDate);

    setMonth(nextMonth);
    setYearChange(year);
  
    dispatch(getGeneralReport(ids, nextDate.getFullYear(), nextMonth));
    dispatch(getGeneralConsume(chargeId, nextDate.getFullYear(), nextMonth));
  };

  const handlePrevMonth = () => {
    const currentDate = new Date(changeMonthYear);
    let prevMonth = currentDate.getMonth() > 9 ? currentDate.getMonth() : currentDate.getMonth() === 0 ? 0 : `0${currentDate.getMonth()}`; // Obtener el mes anterior
    let year = currentDate.getFullYear();

    // Si estamos en enero, retrocedemos al año anterior
    if (prevMonth === 0) {
      prevMonth = 12; // Volver a diciembre
      year -= 1;      // Decrementar el año
    }

    const prevDate = new Date(year, prevMonth - 1);
    setChangeMonthYear(prevDate);

    setMonth(prevMonth);
    setYearChange(year);
  
    dispatch(getGeneralReport(ids, prevDate.getFullYear(), prevMonth));
    dispatch(getGeneralConsume(chargeId, prevDate.getFullYear(), prevMonth));
  };

  useEffect(() => {
    const date_end = month ? new Date(yearChange, month, 0) : new Date();
    const date_start = month ? new Date(yearChange, month - 1, 1) : new Date(date_end.getFullYear(), date_end.getMonth(), 1) ;

    if (meterId !== undefined) {
      dispatch(
        getWeatherInRange(
          corporativeId,
          meterId,
          formatDate(date_start),
          formatDate(date_end)
        )
      );
    }
  }, [meterId, month, yearChange]);

  return (
    <DashboardLayout
      cerrado="false"
      changeMonthYear={changeMonthYear}
      chargeName={charge.name}
      handleNextMonth={handleNextMonth}
      handlePrevMonth={handlePrevMonth}
      ids={ids}
      month={month}
      setChangeMonthYear={setChangeMonthYear}
      setMonth={setMonth}
      setYearChange={setYearChange}
      title={'Tablero de Mediciones'}
      year={yearChange}  
    >
      <div className={classes.root}>
        <Typography
          style={{ color: '#A53693', marginBottom: theme.spacing.unit * 2 }}
          variant="h2">
          {intl.formatMessage({ id: 'app.charge.board.title' })}
        </Typography>
        <AlertCard
          className={classes.notification}
          ids={{ groupId: groupId, chargeId: chargeId }}
        />
        <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <Breadcrumbs
            arial-label="breadcrumb"
            className={classes.breadcrumb}
            separator=":">
            {/* {isAllowed(user.permissions,['corporative_read'])&&<LinkMaterial
              color="inherit"
              component={NavLink}
              to="/home"
            >
              {intl.formatMessage({id: 'app.charges.nav.corp'})}
            </LinkMaterial> } */}
            <LinkMaterial
              color="inherit"
              component={NavLink}
              style={{ color: '#A53693' }}
              to={{ pathname: `/corporative/${corporativeId}` }}>
              {intl.formatMessage({ id: 'app.charges.nav.meters' })}
            </LinkMaterial>
            <LinkMaterial
              color="inherit"
              component={NavLink}
              to={{
                pathname: `/meter/${meterId || meterArray.id}`,
                state: { meter_id }
              }}>
              {meterNamed
                ? meterNamed
                : intl.formatMessage({ id: 'app.charges.nav.groupLab2' })}
            </LinkMaterial>
          </Breadcrumbs>
          <Typography style={{ fontWeight: 600, color: '#00AFEF' }}>
            {' '}
            |{' '}
          </Typography>
          <Breadcrumbs
            arial-label="breadcrumb"
            className={classes.breadcrumb}
            separator=":"
            style={{ marginLeft: theme.spacing.unit }}>
            <LinkMaterial
              color="inherit"
              component={NavLink}
              style={{ color: '#A53693' }}
              to={{
                pathname: `/meter/${meterId || meterArray.id}`,
                state: { meter_id }
              }}>
              {intl.formatMessage({ id: 'app.charges.nav.groupLab2' })}
            </LinkMaterial>
            <LinkMaterial
              color="inherit"
              component={NavLink}
              to={{ pathname: `/group/${groupId}` }}>
              {groupName
                ? groupName
                : intl.formatMessage({ id: 'app.charges.nav.chargeLab2' })}
            </LinkMaterial>
          </Breadcrumbs>
          <Typography style={{ fontWeight: 600, color: '#00AFEF' }}>
            {' '}
            |{' '}
          </Typography>
          <Breadcrumbs
            arial-label="breadcrumb"
            className={classes.breadcrumb}
            separator=":"
            style={{ marginLeft: theme.spacing.unit }}>
            <LinkMaterial
              color="inherit"
              component={NavLink}
              style={{ color: '#A53693' }}
              to={{ pathname: `/group/${groupId}` }}>
              {intl.formatMessage({ id: 'app.reports.hourReports.chargeLbl' })}
            </LinkMaterial>
            <Typography variant="h5">
              {charge.name}
              {ownerName && ownerName !== null && ownerName !== undefined
                ? ` - ${ownerName}`
                : ''}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
          width="100%"
        >
          <Tooltip
            aria-label="prev-month"
            title="Mes Anterior">
            <Button
              aria-label="prev-month"
              color="secondary"
              onClick={handlePrevMonth}
              variant="contained"
            >
              <ArrowLeft />
            </Button>
          </Tooltip>
          <div>
            <DatePick
              changeMonthYear={changeMonthYear}
              ids={ids}
              setChangeMonthYear={setChangeMonthYear}
              setMonth={setMonth}
              setYearChange={setYearChange}
            />
          </div>

          <Tooltip
            aria-label="next-month"
            title="Mes Siguiente">
            <Button
              aria-label="next-month"
              color="secondary"
              disabled={
                changeMonthYear.getMonth() === new Date().getMonth() &&
                 changeMonthYear.getFullYear() === new Date().getFullYear()
              }
              onClick={handleNextMonth}
              variant="contained"
            >
              <ArrowRight />
            </Button>
          </Tooltip>
        </Box>
        <Box
          className={classes.bugets}
          container>
          <Budget
            className={classes.itemsBugets}
            ids={{ groupId: groupId, chargeId: chargeId }}
          />
          <Users className={classes.itemsBugets} />
          <Profit
            className={classes.itemsBugets}
            ids={{ groupId: groupId, chargeId: chargeId }}
          />
          <Receipt
            className={classes.itemsBugets}
            ids={{ groupId: groupId, chargeId: chargeId }}
            month={month}
            year={yearChange}
          />
          <HistoricalReport
            chargeId={chargeId}
            className={classes.lastItemBugets}
          />
        </Box>
        <Grid
          container
          spacing={2}>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}>
            <ConsumptionAndDemandChart
              chargeId={chargeId}
              className={classes.item}
              consumes={charge}
              rateHorary={rateHorary}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}>
            <MonthWithMostConsumeChart
              className={classes.item}
              ids={{
                groupId: groupId,
                chargeId: chargeId,
                concentratorId: obj.concentrator
              }}
              newYear={yearChange}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}>
            <ComparativeYearConsumeChart
              className={classes.item}
              ids={{
                groupId: groupId,
                chargeId: chargeId,
                concentratorId: obj.concentrator
              }}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}>
            <div style={{ marginBottom: '10px' }}>
              <DayWithMostConsumeChart
                className={classes.item}
                ids={{
                  groupId: groupId,
                  chargeId: chargeId,
                  concentratorId: obj.concentrator
                }}
                month={month}
                newYear={yearChange}
              />
            </div>
            <div>
              <LoadFactor
                chargeId={chargeId}
                className={classes.item}
                month={month}
                year={yearChange}  
              />
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            <GeneralReportDemand
              className={classes.item}
              ids={{
                groupId: groupId,
                chargeId: chargeId,
                concentratorId: obj.concentrator
              }}
              rateHorary={rateHorary}
              updateMonth={month}
              updateYear={yearChange}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            {/* <Typography variant='h4' style={{marginBottom:'10px'}}>Consumo Diario</Typography>*/}
            {/* <MaxDemandChart
              className={classes.item}
              chargeId={chargeId}
              consumes={charge}
            />  */}
            {/* <GeneralReportChart className={classes.item} ids={{groupId:groupId,chargeId:chargeId,concentratorId:obj.concentrator}} /> */}
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            {
              <DemandPerDayChart
                chargeId={chargeId}
                className={classes.item}
                consumes={charge}
                corporativeId={corporativeId}
                updateMonth={month}
                updateYear={yearChange}
                weather={weather ? weather : 0}
              />
            }
            {/* <GeneralReportChart className={classes.item} ids={{groupId:groupId,chargeId:chargeId,concentratorId:obj.concentrator}} /> */}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            <GeneralReport
              className={classes.item}
              heatMap={heatMap}
              rateHorary={rateHorary}
              setHeatMap={setHeatMap}
              setValueInfo={setValueInfo}
              updateMonth={month}
              updateYear={yearChange}
              valueInfo={valueInfo}
            />
            {/* <GeneralReportChart className={classes.item} ids={{groupId:groupId,chargeId:chargeId,concentratorId:obj.concentrator}} /> */}
          </Grid>

          {/* agregasdo inicio  */}

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            {
              <TotalesPorDia
                chargeId={chargeId}
                className={classes.item}
                consumes={charge}
                heatMap={heatMap}
                updateMonth={month}
                updateYear={yearChange}
                valueInfo={valueInfo}
              />
            }
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            {
              <OnOff
                chargeId={chargeId}
                className={classes.item}
                consumes={charge}
                heatMap={heatMap}
                updateMonth={month}
                updateYear={yearChange}
                valueInfo={valueInfo}
              />
            }
          </Grid>

          <Grid
            item
            lg={rateHorary === 0 ? 12 : valueInfo.kwh ? 7 : 6}
            md={rateHorary === 0 ? 12 : valueInfo.kwh ? 7 : 6}
            xl={rateHorary === 0 ? 12 : valueInfo.kwh ? 7 : 6}
            xs={12}>
            {
              <RepAveragePerDayOfWeek
                chargeId={chargeId}
                className={classes.item}
                consumes={charge}
                valueInfo={valueInfo}
              />
            }
          </Grid>
          {rateHorary === 1 && (
            <Grid
              item
              lg={valueInfo.kwh ? 5 : 6}
              md={valueInfo.kwh ? 5 : 6}
              xl={valueInfo.kwh ? 5 : 6}
              xs={12}>
              {
                <TotalsHourEnergy
                  chargeId={chargeId}
                  className={classes.item}
                  consumes={charge}
                  valueInfo={valueInfo}
                />
              }
            </Grid>
          )}

          {/** agregado fin  */}

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            {
              <DailyConsumptionChart
                chargeId={chargeId}
                className={classes.item}
                consumes={charge}
                rateHorary={rateHorary}
                updateMonth={month}
                updateYear={yearChange}
              />
            }
            {/* <GeneralReportChart className={classes.item} ids={{groupId:groupId,chargeId:chargeId,concentratorId:obj.concentrator}} /> */}
          </Grid>

          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
           
            { <ConsumptionAndWeightChart
              chargeId={chargeId}
              className={classes.item}
              consumes={charge}
            />}  */}
          {/* <GeneralReportChart className={classes.item} ids={{groupId:groupId,chargeId:chargeId,concentratorId:obj.concentrator}} /> */}
          {/* </Grid> */}
          {/*
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
             <PerformanceChart className={classes.item} /> 
          </Grid> */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            <AccumulatedConsumption
              className={classes.item}
              updateMonth={month}
              updateYear={yearChange}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            <ConsumptionPerDayChart
              chargeId={chargeId}
              className={classes.item}
              consumes={charge}
              corporativeId={corporativeId}
              updateMonth={month}
              updateYear={yearChange}
              weather={weather ? weather : 0}
            />
          </Grid>

          {weather && (
            <Grid
              item
              lg={12}
              xs={12}>
              <Temperature
                className={classes.item}
                updateMonth={month}
                updateYear={yearChange}
                weather={weather ? weather : 0}
              />
            </Grid>
          )}
          {/*
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <ProductList className={classes.item} /> 
          </Grid> */}
          {fp && fp.has_fp === 1 && (
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <FPDailyAvrgChart
                className={classes.item}
                ids={ids}
                meterFp={fp.charge_fp}
                meterId={meterId}
              />
            </Grid> 
          )}
          {fp && fp.has_fp === 1 && (
            <Grid
              item
              lg={4}
              md={12}
              xl={3}
              xs={12}
            >
              <FPGauge
                className={classes.item}
                ids={ids}
                meterFp={fp.charge_fp}
                meterId={meterId}
              />
            </Grid>
          )}
             
          {/* {fp && fp.has_fp === 1 && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <GeneralReportAngle 
                charge={charge}
                className={classes.item}
                concentratorId={concentratorId}
                fp={fp}
                updateMonth={month}
                updateYear={yearChange}
              />
            </Grid>
          )}
          {fp && fp.has_fp === 1 && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}>
              <ReactiveVsActive
                charge={charge}
                className={classes.item}
                concentratorId={concentratorId}
                fp={fp}
                updateMonth={month}
                updateYear={yearChange}
              />
            </Grid>
          )} */}
          {chargeVoltageId && (
            <Grid
              item
              lg={4}
              md={12}
              xl={4}
              xs={12}>
              <VoltageAnalysis
                className={classes.item}
                ids={{
                  groupId: groupId,
                  chargeId: chargeId,
                  concentratorId: obj.concentrator
                }}
              />
            </Grid>
          )}
          <Grid
            item
            lg={voltageValidation}
            md={12}
            xl={voltageValidation}
            xs={12}>
            <ElectricCurrent
              className={classes.item}
              id={{
                groupId: groupId,
                chargeId: chargeId
              }}
            />
          </Grid>
          {chargeVoltageId && (
            <Grid
              item
              lg={voltageValidation}
              md={12}
              xl={voltageValidation}
              xs={12}>
              <ElectricAnalysis
                className={classes.item}
                id={{
                  groupId: groupId,
                  chargeId: chargeId,
                  concentratorId: obj.concentrator
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </DashboardLayout>
  );
}