/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { getTopChargeInMonth } from 'js/actions/groupDashboardActions';

// Palette
import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

// Material components
import { FormControl, MenuItem, Select } from '@material-ui/core';

// Shared components
import { Portlet, PortletHeader, PortletLabel, PortletToolbar, PortletContent } from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
const useStyle = makeStyles(styles);

export default function TopChart(props) {
  const intl = useIntl();
  const classes = useStyle();
  const dispatch = useDispatch();
  const { groupId, className,typeEnergy, rateHorary, ... rest } = props;
  const rootClassName = classNames(classes.root, className);
  const chartTopCharge = useSelector(state => state.groupDashboard.topChargeInMonth);
  const [top, setTop] = useState(5);
  const [allGroupNames, setAllGroupNames] = useState([]);

  useEffect(()=>{
    dispatch(getTopChargeInMonth(groupId))
  },[]);

  // const baseKWh = [];
  // const mediaKWh = [];
  // const puntaKWh = [];

  useEffect(()=>{
    const groupNames = chartTopCharge.map(item => item.charge.name);
    setAllGroupNames(groupNames);
  },[chartTopCharge])

  const filteredGroupName = allGroupNames.slice(0, top);

  const baseKWh = chartTopCharge.slice(0, top).map(item => item.charge.consumes[1][2]);
  const mediaKWh = chartTopCharge.slice(0, top).map(item => item.charge.consumes[1][2]);
  const puntaKWh = chartTopCharge.slice(0, top).map(item => item.charge.consumes[1][3]);

  const totalConsumation = chartTopCharge.map(item => (item.total_consumption).toFixed(2))

  const handlerTop = (e) => {
    e.preventDefault();
    setTop(e.target.value);
  }

  const dateSetsOptions = rateHorary === 1 ?
    [
      {
        label: intl.formatMessage({id: 'app.meter.board.topCharges.offkWh'}),
        yAxisID: 'A',
        backgroundColor: '#3A5F0B',
        data: baseKWh
      },
      {
        label: intl.formatMessage({id: 'app.meter.board.topCharges.midkWh'}),
        yAxisID: 'A',
        backgroundColor: '#FFCC00',
        data: mediaKWh
      },
      {
        label: intl.formatMessage({id: 'app.meter.board.topCharges.onkWh'}),
        yAxisID: 'A',
        backgroundColor: 'red',
        data: puntaKWh
      }
    ] : [
      {
        label: intl.formatMessage({id: 'app.charge.board.mainHead.consumtion'}),
        yAxisID: 'A',
        backgroundColor: '#45B880',
        data: totalConsumation
      },
    ]
  
  const data = ({
    labels: filteredGroupName,
    datasets: dateSetsOptions,
  });

  const options = { 
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          let value = tooltipItem.value;
          label += `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} kWh`;
          return label;
        }
      }
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        { 
          barPercentage: 0.9,
          categoryPercentage: 0.8,
          ticks: {
            fontColor: palette.text.secondary,
            padding: 20
        
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position:'left',
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero:true,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
  };

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={typeEnergy === 2 ? intl.formatMessage({id: 'app.meterSolar.board.topCharges.title'}) : intl.formatMessage({id: 'app.meter.board.topCharges.title'})} />
        {allGroupNames.length > 3 ? 
          (
            <PortletToolbar>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  displayEmpty
                  inputProps={{'aria-label': 'Select Top'}}
                  onChange={handlerTop}
                  value={top}
                >
                  <MenuItem value={3}>{intl.formatMessage({id: 'app.meter.board.topCharges.top3'})}</MenuItem>
                  <MenuItem value={5}>{intl.formatMessage({id: 'app.meter.board.topCharges.top5'})}</MenuItem>
                  {allGroupNames.length <= 5 ? (''): (<MenuItem value={10}>{intl.formatMessage({id: 'app.meter.board.topCharges.top10'})}</MenuItem>)}
                </Select>
              </FormControl>
            </PortletToolbar>
          )
          :
          (<PortletToolbar />)
        }

      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Bar
            data={data}
            options={options}
            redraw
          />
        </div>
      </PortletContent>
    </Portlet>
  )

}

TopChart.propTypes = {
  classNames: PropTypes.string,
  classes: PropTypes.object.isRequired
}