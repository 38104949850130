/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormGroup, FormHelperText, FormLabel, InputAdornment, makeStyles, OutlinedInput } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { getChargeList, postDataGroup } from 'js/actions/groupActions';
import { useIntl } from 'react-intl';
import theme from 'theme';
import Swal from 'sweetalert2';
import { AddCircleOutline, Delete } from '@material-ui/icons';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  rateType: null
};

export default function GroupForm(props) {
  const intl = useIntl()
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {meterId, corpId} = props.match.params;
  const {charge_types} = useSelector(state => state.groups);
  const [unificar, setUnificar] = useState({
    yes: false,
    no: true
  })
  const [iva, setIva] = useState(16)
  const [customProperties, setCustomProperties] = useState([]);

  const tarifas = [
    //'DB1',
    //'DB2',
    'PDBT',
    'GDBT',
    //'RABT',
    //'RAMT',
    //'APBT',
    //'APMT',
    'GDMTO',
    'GDMTH',
    //'DIST',
    //'DIT'
  ];

  const [state, setState] = React.useState({
    customTariffValidator: false
  });

  useEffect(()=>{
    dispatch(getChargeList());
  },[dispatch]);

  const values = getValues();
  const { customTariffValidator } = state;

  const onSubmit = values => {
    dispatch(postDataGroup(values,meterId,corpId, iva, customProperties));
  };

  const handleRateType = rateType => {
    setValue('rateType', rateType.target.value);
  };

  const handleChargeType = event => {
    setValue('type_energy', event.target.value); 
  };

  const handleUnificar = event => {
    const {name, checked} = event.target;
    if(name === 'yes' && checked){
      setUnificar({
        yes: true,
        no: false
      })
      setValue('bill', true);
    }else if(name === 'no' && checked){
      setUnificar({
        yes: false,
        no: true
      });
      setValue('bill', false);
    }
  }

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useOnMount(() => {
    register({ name: 'rateType', type: 'text' });
    register({ name: 'bill', type: 'checkbox'});
    register({ name: 'type_energy', type: 'text' });
  });

  const handleAddCustomProperties = () => {
    if(customProperties.length < 3){
      setCustomProperties([...customProperties, {name: '', value: 0, type: 'percentage'}])
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Solo se Pueden Agregar 3 Cargos'
      })
    }
  }

  const handleDeleteItem = (index) => {
    setCustomProperties(customProperties.filter((_, i) => i !== index));
  };

  return (
    <DashboardLayout title="Creacion de Grupos">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.groupsCards.addGroup.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.groupNameLbl'})}
                      name="name"
                      required
                      //value={name}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.description'})}
                      name="description"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.monitChargeLbl'})}
                      name="monitoring_fee"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.fixedChargeLbl'})}
                      name="flat_fee"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <FormControlLabel
                      className={classes.textField}
                      control={
                        <Checkbox
                          checked={customTariffValidator}
                          inputRef={register}
                          name="is_custom_tariff"
                          onChange={handleChange('customTariffValidator')}
                        />
                      }
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.customRateLbl'})}
                      
                    />

                    {customTariffValidator == false ? (
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          inputRef={useOnMount}
                        >
                          {intl.formatMessage({id: 'app.groupsCards.addGroup.rateTypeLbl'})}
                        </InputLabel>
                        <Select
                          id="demo-simple-select-outlined"
                          labelId="demo-simple-select-outlined-label"
                          onChange={handleRateType}
                          value={values.rateType}
                          //labelWidth={labelWidth}
                        >
                          
                          {tarifas
                            ? tarifas.map(el => (
                              <MenuItem value={el}>{el}</MenuItem>
                            ))
                            : <MenuItem />}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        label={intl.formatMessage({id: 'app.groupsCards.addGroup.customRateLbl'})}
                        name="custom_tariff"
                        //value={description}
                        variant="filled"
                      />
                    )}
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2}}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        inputRef={useOnMount}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.typeEnergy'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-label-outlined"
                        onChange={handleChargeType}
                        value={values.type_energy}
                      >
                        {charge_types ? Object.values(charge_types).map(el => (
                          <MenuItem
                            value={el}
                          >{el}</MenuItem>
                        )): (<MenuItem />)}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >{intl.formatMessage({id: 'app.chargesCards.unifyLoad'})}</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.yes}
                              color="secondary"
                              name="yes"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.yes'})}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.no}
                              color="secondary"
                              name="no"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.no'})}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.ivaTitle'})}
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 8}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(8)}}
                            />
                          }
                          label="8%"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 16}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(16)}}
                            />
                          }
                          label="16%"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  {customProperties.length > 0 && (
                    customProperties.map((item, index) => (                 
                      <Grid
                        item
                        key={index}
                        lg={12}
                        md={12}
                        style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: theme.spacing.unit * 2, flexWrap: 'wrap', alignItems: 'center'}}
                        xl={12}
                        xs={12}
                      >
                        <Grid
                          item
                          lg={5}
                          md={12}
                          xl={5}
                          xs={12}
                        >
                          <TextField
                            className={classes.textField}
                            label="Cargo Personalizado"
                            onChange={(e) => {
                              const name = e.target.value;
                              const updatedProperties = customProperties.map((property, i) =>
                                i === index ? { ...property, name } : property
                              );
                              setCustomProperties(updatedProperties);
                            }}
                            value={customProperties[index].name}
                            variant="filled"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                          xl={6}
                          xs={12}
                        >
                          <Grid
                            item
                            lg={5}
                            md={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
                            xl={5}
                            xs={12}
                          >
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={customProperties[index].type === 'percentage'}
                                    color="secondary"
                                    name="percentage"
                                    onChange={() => {
                                      const updatedProperties = customProperties.map((property, i) =>
                                        i === index ? { ...property, type: 'percentage', value: '' } : property
                                      );
                                      setCustomProperties(updatedProperties);
                                    }}
                                  />
                                }
                                label="Porcentaje"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={customProperties[index].type === 'amount'}
                                    color="secondary"
                                    name="amount"
                                    onChange={() => {
                                      const updatedProperties = customProperties.map((property, i) =>
                                        i === index ? { ...property, type: 'amount', value: '' } : property
                                      );
                                      setCustomProperties(updatedProperties);
                                    }}
                                  />
                                }
                                label="Monto"
                              />
                            </FormGroup>
                          </Grid>

                          <Grid
                            item
                            lg={6}
                            md={12}
                            xl={6}
                            xs={12}
                          >
                            {customProperties[index].type === 'percentage' ? (
                              <FormControl
                                fullWidth
                                variant="filled"
                              >
                                <InputLabel htmlFor={`outlined-adornment-percentage-${index}`}>
                              Porcentaje
                                </InputLabel>
                                <OutlinedInput
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                  error={customProperties[index].value > 100}
                                  id={`outlined-adornment-percentage-${index}`}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(',', '.'); // Reemplaza comas por puntos
                                    if (/^-?\d*\.?\d*$/.test(value)) { // Valida que sea un número flotante
                                      value = parseFloat(value) || 0; // Convierte a flotante o 0 si está vacío
                                      if (value >= 0 && value <= 100) { // Verifica que esté dentro del rango
                                        const updatedProperties = customProperties.map((property, i) =>
                                          i === index ? { ...property, value: parseFloat(value) || 0 } : property
                                        );
                                        setCustomProperties(updatedProperties);
                                      }
                                    }
                                  }}
                                  value={customProperties[index].value || ''}
                                />
                                <FormHelperText>
                                  {customProperties[index].value > 100
                                    ? 'El porcentaje no puede exceder 100'
                                    : 'Debe ser un número entre 0 y 100'}
                                </FormHelperText>
                              </FormControl>
                            ) : (
                              <FormControl
                                fullWidth
                                variant="filled"
                              >
                                <InputLabel htmlFor={`outlined-adornment-amount-${index}`}>
                               Monto
                                </InputLabel>
                                <OutlinedInput
                                  id={`outlined-adornment-amount-${index}`}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(',', '.'); // Reemplaza comas por puntos
                                    if (/^-?\d*\.?\d*$/.test(value)) { // Acepta números con decimales
                                    // Formatea para limitar a 2 decimales si es necesario
                                      const parts = value.split('.');
                                      if (parts.length === 2 && parts[1].length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                      }

                                      const updatedProperties = customProperties.map((property, i) =>
                                        i === index ? { ...property, value } : property
                                      );
                                      setCustomProperties(updatedProperties);
                                    }
                                  }}
                                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                  value={customProperties[index].value || ''}
                                />
                                <FormHelperText>Ingrese un monto válido</FormHelperText>
                              </FormControl>
                            )}
                          </Grid>


                        </Grid>
                        <Grid 
                          item
                          lg={1}
                          md={12}
                          style={{display: 'flex', justifyContent: 'flex-end', color:'red', cursor: 'pointer'}}
                          xl={1}
                          xs={12}
                        >
                          <Delete onClick={() => handleDeleteItem(index)} />
                        </Grid>
                      </Grid>
                    ))
                  ) }
                </Grid>
              </Grid>
            
              <Button
                className={classes.signUpButton}
                color="secondary"
                disabled={customProperties.length < 3 ? false : true}
                onClick={handleAddCustomProperties}
                size="large"
                style={{marginTop:'1.5em'}}
                variant="outlined"
              >
                <AddCircleOutline
                  disabled={customProperties.length < 3 ? false : true}
                  style={{marginRight: theme.spacing.unit, color: theme.palette.secondary.main}}
                />
                ¿Deseas Agregar una Cargo Personalizado?
              </Button>
              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                //onClick={this.handleSubmit}
                style={{marginTop:'2.5em'}}
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.groupsCards.addGroup.registerBtn'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}