import * as meterActionTypes from 'js/constants/meterActions-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history';
import Swal from 'sweetalert2'



const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const showMeters = corporativeID => {
  return async dispatch => {
    dispatch(getMeterStarted());
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeID}`,
        getconfig()
      )
      .then(response => {
        dispatch(getMeter(response.data.data.meters));
      })
      .catch(function(error) {
        dispatch(getMeterFailure());
        console.log(error);
      });
  };
};

export const getMeterStarted = () => ({
  type: meterActionTypes.GET_METER_STARTED,
  loading: true
});


export const getMeter = meters => {
  return {
    type: meterActionTypes.GET_METER_SUCCESS,
    meters,
    loading: false
  };
};


export const getMeterFailure = () => {
  return {
    type: meterActionTypes.GET_METER_FAILURE ,
    loading: false
  };
};

export const showAllMeters = corporativeID => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeID}/meters`,
        getconfig()
      )
      .then(response => {
        dispatch(getMeter(response.data.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};



export const postDataMeter = (meter, corpId, city, country, county, state, cp, lat, lng) => {
  return async dispatch => {
    dispatch(postMeterStarted());

    axios
      .post(
        'https://api.metimur.mx/api/meter',
        {
          name: meter.name,
          description: meter.description,
          country: country,
          state: state,
          city: city,
          county: county,
          cp: cp,
          address: meter.address, 
          payment_deadline: meter.payment_deadline ,
          corporative_id: corpId ,
          contracted_demand : meter.contracted_demand,
          coordinates: {
            lat: [lat],
            long: [lng]
          }
        },
        getconfig()
      )
      .then(response => {
        Swal.fire({
          title: 'Alta Correcta',
          text: `Medidor ${meter.name} creado exitosamente`,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${corpId}`);
        });
        dispatch(postMeterSuccess(meter));
      })
      .catch(error => {
        const errorMessage = error.response.data.message
        Swal.fire({
          title: 'Error',
          text: `${errorMessage.join()}`,
          icon: 'error',
        });
        dispatch(postMeterFail(error.message));
      });
  };
};

export const postMeterStarted = () => ({
  type: meterActionTypes.POST_METER_STARTED
});

export const postMeterSuccess = data => {
  return {
    type: meterActionTypes.POST_METER_SUCCESS,
    payload: {
      name: data.name,
      description: data.description,
      country: data.country,
      state: data.cstate,
      city: data.city,
      county: data.county,
      cp: data.cp,
      address: data.address,
      corporative_id: data.corporative_id,
      coordinates: {
        lat: [data.coordinates.lat],
        long: [data.coordinates.lng]
      }
    }
  };
};

export const postMeterFail = error => ({
  type: meterActionTypes.POST_METER_FAILURE,
  state: { error }
});

export const deleteMeter = (meterId, corpId) => {
  return async dispatch => {
    dispatch(deleteMeterSuccess(meterId));
    axios.post(
      'https://api.metimur.mx/api/meter/delete',
      { meter_id: meterId, corporative_id: corpId },
      getconfig()
    );
  };
};

export const deleteMeterSuccess = meterId => {
  return {
    type: meterActionTypes.DELETE_METER_SUCCESS,
    payload: { id: meterId }
  };
};


export const updateDataMeter = (meter, meterData,corpId, city, country, county, state, cp, lat, lng) => {
  return async dispatch => {
    dispatch(updateMeterStarted());

    axios
      .put(
        'https://api.metimur.mx/api/meter',
        {
          meter_id: meterData.id,
          name: meter.name,
          description: meter.description,
          country: country,
          state: state,
          city: city,
          county: county,
          cp: cp,
          address: meter.address, 
          payment_deadline: meter.payment_deadline ,
          corporative_id: meterData.corporative_id ,
          contracted_demand : meter.contracted_demand,
          coordinates: {
            lat: [lat],
            long: [lng]
          }
        },
        getconfig()
      )
      .then(() => {
        Swal.fire({
          title: 'Actualizacion Correcta',
          text: `Medidor ${meter.name} actualizado exitosamente`,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${meterData.corporative_id}`);
        });
      })
      .catch(error => {
        const errorMessage = error.response.data.message
        Swal.fire({
          title: 'Error',
          text: `${errorMessage.join()}`,
          icon: 'error',
        });
        dispatch(postMeterFail(error.message));
      });
  };
};

export const updateMeterStarted = () => ({
  type: meterActionTypes.PUT_METER_STARTED
});


export const updateDataInvoiceMeter = (meter, meterData,corpId) => {
  return async dispatch => {
   // dispatch(updateMeterStarted());
      
    axios
      .post(
        'https://api.metimur.mx/api/meter/invoice',
        {

          meter_id              : meterData.id ,
          administrative_rfc    : meter.administrative_rfc ,
          administrative_name   : meter.administrative_name  ,
          administrative_cp     : meter.administrative_cp ,
          administrative_email  : meter.administrative_email ,
          administrative_email2 : meter.administrative_email2 ,
          administrative_tel    : meter.administrative_tel   ,

          calle : meter.calle , 
          codigo_postal : meter.cp ,
          numero_exterior : meter.numExt ,
          colonia : meter.colonia ,
          estado : meter.state ,
          municipio : meter.city ,
          pais : '52'

 

        },
        getconfig()
      )
      .then(() => {
        Swal.fire({
          title: 'Actualizacion Correcta',
          text:  'Los datos de facturación se actualizarón exitosamente ' ,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${meterData.corporative_id}`);
        });
      })
      .catch(error => {
        dispatch(postMeterFail(error.message));
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};


/*
export const updateDataInvoiceMeter = (meter, meterData,corpId) => {
  return async dispatch => {
   // dispatch(updateMeterStarted());
      alert("updateDataInvoiceMeter")
    axios
      .put(
        'https://api.metimur.mx/api/meter/invoice',
        {

          meter_id              : meterData.id ,
          administrative_rfc    : meter.administrative_rfc ,
          administrative_name   : meter.administrative_name  ,
          administrative_cp     : meter.administrative_cp ,
          administrative_email  : meter.administrative_email ,
          administrative_email2 : meter.administrative_email2 ,
          administrative_tel    : meter.administrative_tel 
        },
        getconfig()
      )
      .then(() => {
        Swal.fire({
          title: 'Actualizacion Correcta',
          text:  'Los datos de facturación se actualizarón exitosamente ' ,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${meterData.corporative_id}`);
        });
      })
      .catch(error => {
        dispatch(postMeterFail(error.message));
        console.log('ERROR API:' + error);
      });
  };
};
*/



export const getDataInvoiceMeter = ( meterID , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterID}/invoicedata`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data );
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};


export const getConsumtionAndWeight = (meterId , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterId}/billing`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data);
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const postFolder = (meterId, name, description, folder, status) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/documentos/folders',
        {
          name: name,
          description: description,
          meter_id: meterId.id,
          corporate_id: meterId.corporative_id,
          father_id: folder ? folder.id : null
        },
        getconfig()
      )
      .then(response => {
        status(response.data.success)
        dispatch(postFolderSuccess(response.data.data));
        dispatch(getFolders(meterId, folder.id));
        Swal.fire({
          'text': 'Carpeta creada exitosamente',
          'icon': 'success',
          customClass: {
            popup: 'swalCustomIndex'
          }
        })
      })
      .catch(error => {
        dispatch(postFolderFail(error.message));
        console.error(error.message)
      });
  }
}

export const postFolderSuccess = data => {
  return {
    type: meterActionTypes.POST_FOLDER_SUCCESS,
    payload: {
      name: data.name,
      description: data.description,
      meter_id: data.meter_id,
      corporative_id: data.corporative_id
    }
  };
};

export const postFolderFail = error => ({
  type: meterActionTypes.POST_FOLDER_FAILURE,
  state: { error }
});

export const postFolderInto = (meterId, name, description, status) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/documentos/folders',
        {
          name: name,
          description: description,
          meter_id: meterId.meter_id,
          corporate_id: meterId.corporative_id,
          father_id: meterId.id
        },
        getconfig()
      )
      .then(response => {
        dispatch(getFolders(meterId, meterId.id));
        status(response.data.success)
        dispatch(postFolderIntoSuccess(response.data.data));
        Swal.fire({
          'text': 'Carpeta creada exitosamente',
          'icon': 'success',
          customClass: {
            popup: 'swalCustomIndex'
          }
        })
      })
      .catch(error => {
        Swal.fire({
          'text': error.message,
          'icon': 'error',
          customClass: {
            popup: 'swalCustomIndex'
          }
        })
        dispatch(postFolderFail(error.message));
      });
  }
}

export const postFolderIntoSuccess = data => {
  return {
    type: meterActionTypes.POST_FOLDER_INTO_SUCCESS,
    payload: {
      name: data.name,
      description: data.description,
      meter_id: data.meter_id,
      corporative_id: data.corporative_id,
      father_id: data.father_id
    }
  };
};

export const postFolderIntoFail = error => ({
  type: meterActionTypes.POST_FOLDER_INTO_FAILURE,
  state: { error }
});


export const getFolders = (meterId, folder) =>  {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/documentos/getfolders',
        {
          meter_id: meterId.id,
          father_id: folder ? folder.id : null
        },
        getconfig()
      )
      .then(response => {
        dispatch(getFoldersSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getFoldersFail(error.message));
      })
  }
}

export const getFoldersSuccess = folder => {
  return {
    type: meterActionTypes.GET_FOLDER_SUCCESS,
    folder
  }
}

export const getFoldersFail = error => ({
  type: meterActionTypes.GET_FOLDER_FAILURE,
  state: { error }
})

export const getIntoFolder = (meterId) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/documentos/getfolders',
        {
          meter_id: meterId.meter_id,
          father_id: meterId.id
        },
        getconfig()
      )
      .then(response => {
        dispatch(getIntoFolderSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getIntoFolderError(error.message))
      })
  }
}

export const getIntoFolderSuccess = folderInfo => {
  return {
    type: meterActionTypes.GET_INTO_FOLDER_SUCCESS,
    folderInfo
  }
}

export const getIntoFolderError = error => ({
  type: meterActionTypes.GET_INTO_FOLDER_FAILURE,
  state: { error }
})

export const editFolder = (folder, name, description, meterId, status) => {
  return async dispatch => {
    axios
      .put(
        'https://api.metimur.mx/api/documentos/folders',
        {
          name: name,
          description: description,
          folder_id: folder
        },
        getconfig()
      )
      .then((response) => {
        Swal.fire({
          'text': 'Carpeta editada exitosamente',
          'icon': 'success',
          customClass: {
            popup: 'swalCustomIndex'
          }
        })
        status(response.data.success)
        dispatch(getFolders(meterId, folder));
      })
      .catch(error => {
        Swal.fire({
          'text': error.message,
          'icon': 'error',
          customClass: {
            popup: 'swalCustomIndex'
          }
        })
      });
  }
}

export const deleteFolder = (folder, meterId, status) => {
  return async dispatch => {
    dispatch(deleteFolderSuccess(folder))
    axios
      .delete(
        'https://api.metimur.mx/api/documentos/folders',
        {
          params: { folder_id: folder },
          ...getconfig(),
        }
      )
      .then((response)=>{
        status(response.data.success)
        dispatch(getFolders(meterId, folder));
      })
  }
}

export const deleteFolderSuccess = folder => {
  return {
    type: meterActionTypes.DELETE_FOLDER_SUCCESS,
    payload: { id: folder }
  }
}

export const getWeatherInRange = (corporative_id, meter_id, date_start, date_end) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/meter/weatherInRange',
        {
          corporative_id:corporative_id,
          meter_id: meter_id,
          date_start: date_start,
          date_end: date_end
        },
        getconfig()
      )
      .then(response=>{
        dispatch(getWeatherRangeSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getWeatherInRangeError(error.message))
        console.error(error.message)
      })
  }
}

export const getWeatherRangeSuccess = weatherRange => {
  return {
    type: meterActionTypes.GET_WEATHER_RANGE_SUCCESS,
    weatherRange
  }
}

export const getWeatherInRangeError = error => {
  return {
    type: meterActionTypes.GET_WEATHER_RANGE_ERROR,
    state: { error }
  }
}

export const postFile = (file, folder, name, description, funcResp) => {
  return async dispatch => {

    const formData = new FormData();
    formData.append('file', file); // Adjunta el archivo
    formData.append('folder_id', folder); // Adjunta el ID de la carpeta
    formData.append('name', name); // Adjunta el nombre
    formData.append('description', description); // Adjunta la descripción

    axios
      .post(
        'https://api.metimur.mx/api/documentos/file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data' 
          },
          ...getconfig(),
        }
      )
      .then(reponse=>{
        Swal.fire({
          'title': reponse.data.message,
          'icon': 'success'
        })
        funcResp(reponse.data.success)
      })
      .catch(error => {
        dispatch(postFileFail(error.message))
      })
  }
}

export const postFileSuccess = file => {
  return {
    type: meterActionTypes.POST_FILE_SUCCESS,
    payload: {
      file: file.file,
      folder_id: file.folder_id,
      name: file.name,
      description: file.description
    }
  }
}

export const postFileFail = error => ({
  type: meterActionTypes.POST_FILE_FAILURE,
  state: { error }
})

export const deleteFile = (file, status) => {
  return async dispatch => {
    axios
      .delete(
        'https://api.metimur.mx/api/documentos/file',
        {
          params: { file_id: file },
          ...getconfig(),
        }
      )
      .then(response => {
        Swal.fire({
          'text': 'Archivo eliminado',
          'icon': 'success'
        })
        status(response.data.success)
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}