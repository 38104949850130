/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Box, Button, Chip, CircularProgress, FormControl, Grid, IconButton, TableHead, InputLabel, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableRow, Tooltip, Typography, Collapse, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMeter, showMeters } from 'js/actions/meterActions';
import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { isAllowed } from 'helpers';
import { setMeterId } from 'js/actions/userActions';
import ReactSpeedometer from 'react-d3-speedometer';
import Swal from 'sweetalert2';
import { Dashboard, FilterList, GroupWork, KeyboardArrowDown, KeyboardArrowUp, Room, Settings } from '@material-ui/icons';

const useStyle = makeStyles(styles);

const containerStyle = {
  width: '100%',
  height: '500px',
};

const LocationMaps = (props) => {
  const [selected, setSelected] = useState(0);
  const [map, setMap] = useState(null);
  // const [mapNeedsUpdate, setMapNeedsUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cityFilter, setCityFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [uniqueStates, setUniqueStates] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const open = Boolean(anchorEl);
  const setSelectedCorp = props.setSelectedCorp;
  const setShowDetails = props.setShowDetails;
  const setOpenDocuments = props.setOpenDocuments;
  const { className, corpId, mapContainer, setMeterSelected, dashboardRef, ...rest } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyle();
  const meters = useSelector(state => state.meters.meters);
  const rootClassName = classNames(classes.root, className);
  const mapRef = useRef(null);
  let loading = useSelector(state => state.meters.loading);
  const consumes = selected !== 0 ? selected.consumes : null;
  const consumesTable = consumes ? [consumes[0], consumes[1]] : null;
  const user = useSelector(state => state.user.user);

  const setAdminMeterId = (meterId) => {
    dispatch(setMeterId(meterId));
  };

  useEffect(()=>{
    dispatch(showMeters(corpId))
  },[corpId]);
  
  // useEffect(() => {
  //   setMapNeedsUpdate(true);
  // },[meters]);

  // Verificación de coordenadas válidas
  const isValidCoordinate = (lat, long) => {
    const latNum = parseFloat(lat);
    const longNum = parseFloat(long);
    return !isNaN(latNum) && !isNaN(longNum) && latNum >= -90 && latNum <= 90 && longNum >= -180 && longNum <= 180;
  };

  // Generar la lista única de estados al cargar los datos
  useEffect(() => {
    const states = [...new Set(meters.map(item => item.state))];
    setUniqueStates(states);
  }, [meters]);

  // Actualizar las ciudades únicas cuando cambia el estado seleccionado
  useEffect(() => {
    if (stateFilter) {
      const cities = [
        ...new Set(meters.filter(item => item.state === stateFilter).map(item => item.city)),
      ];
      setUniqueCities(cities);
    } else {
      setUniqueCities([]);
    }
  }, [stateFilter, meters]);

  // Manejador de cambios para los filtros
  const handleFilterChange = (filterType, value) => {
    if (filterType === 'state') {
      setStateFilter(value);
      setCityFilter(''); // Reinicia el filtro de ciudades al cambiar el estado
    } else if (filterType === 'city') {
      setCityFilter(value);
    }
  };

  // Datos filtrados según los filtros actuales
  const filteredData = meters.filter(item =>
    (!stateFilter || item.state === stateFilter) &&
    (!cityFilter || item.city === cityFilter)
  );

  
  // Memorizar la lista de ubicaciones válidas
  const metersWithLatLong = useMemo(() => {
    return filteredData.map((meter) => {
      if (meter.coordinates && isValidCoordinate(meter.coordinates.lat[0], meter.coordinates.long[0])) {
        return {
          ...meter,
          latitude: parseFloat(meter.coordinates.lat[0]),
          longitude: parseFloat(meter.coordinates.long[0]),
        };
      } else if (isValidCoordinate(meter.latitude, meter.longitude)) {
        return {
          ...meter,
          latitude: parseFloat(meter.latitude),
          longitude: parseFloat(meter.longitude),
        };
      } else {
        return {
          ...meter,
          latitude: null,
          longitude: null,
        };
      }
    }).filter(meter => meter.latitude !== null && meter.longitude !== null);
  }, [filteredData]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBsCZxqh6pCOq1A1TCZTzXHyBzYgGYEUpo',
  });

  
  const customMarker = {
    url: 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png'
  };

  // Manejo de la carga del mapa
  const onLoad = useCallback((mapInstance) => {
    mapRef.current = mapInstance; // Asigna el mapa al ref
    setMap(mapInstance); // Asigna también al estado
    if (metersWithLatLong.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      metersWithLatLong.forEach(meter => {
        bounds.extend(new window.google.maps.LatLng(meter.latitude, meter.longitude));
      });
      mapInstance.fitBounds(bounds);
    }
  }, [metersWithLatLong]);

  // Ajustar los límites usando el ref
  const adjustMapBounds = useCallback(() => {
    if (mapRef.current && metersWithLatLong.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      metersWithLatLong.forEach((meter) => {
        bounds.extend(new window.google.maps.LatLng(meter.latitude, meter.longitude));
      });
      mapRef.current.fitBounds(bounds); // Ahora funciona porque mapRef.current tiene la instancia del mapa
    }
  }, [metersWithLatLong]);
  
  // Actualizar el mapa automáticamente cuando cambien los datos filtrados
  useEffect(() => {
    if (map && metersWithLatLong.length > 0) {
      adjustMapBounds();
    }
  }, [filteredData, metersWithLatLong, map, adjustMapBounds]);
  
  const mapOptions = {
    streetViewControl: false, 
    mapTypeControl: false,
    zoomControl: true,
    fullscreenControl: false,
  };

  const columns = [
    {
      title: '' ,
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        width: '10%',
        MinWidth: '10%'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.baseCol'}),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.intermidateCol'}),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.tipCol'}),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    }
  ];

  const data = [];

  let obj;

  if(consumesTable !== null && consumesTable !== undefined) {
    consumesTable.forEach(element => {
      obj = {
        med: element[0],
        bas: element[1],
        int: element[2],
        punt: element[3]
      };
  
      data.push(obj);
    });
  }

  const handleShowDetails = () => {
    setSelectedCorp(selected);
    setShowDetails(true);
    setAnchorEl(null)
  }

  const handleShowDocuments = () => {
    setSelectedCorp(selected);
    setOpenDocuments(true);
    setAnchorEl(null)
  }

  const deleteMeterData = (meterId, corpId) => {
    Swal.fire({
      title: `${intl.formatMessage({
        id: 'app.metersCards.deleteMetBtn.title'
      })} `,
      text: `${intl.formatMessage({
        id: 'app.metersCards.deleteMetBtn.text'
      })} ${meterId.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: 'app.metersCards.deleteMetBtn.confirmation'
      })
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({
            id: 'app.metersCards.deleteMetBtn.titleConfirmation'
          }),
          text: intl.formatMessage({
            id: 'app.metersCards.deleteMetBtn.textConfirmation'
          }),
          icon: 'success'
        });
        dispatch(deleteMeter(meterId.id, corpId));
      }
    });
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDashboard = (local) => {
    setMeterSelected(local);
    setTimeout(()=> {
      if(dashboardRef.current !== null) dashboardRef.current.scrollIntoView({ behavior: 'smooth', block: 'end'})
    },1000)
  }

  function Rows (local) {
    const [openInfo, setOpenInfo] = useState(false);

    const toggleRow = (id) => {
      setOpenInfo((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

    let minVoltage=0;
    let maxVoltage=500;

    if( local.local !== 0 ){
      minVoltage= Math.floor( local.local.rated_voltage*0.85);
      maxVoltage= Math.floor( local.local.rated_voltage*1.15);
    }

 
    let voltPart0= Math.floor (local.local.rated_voltage*0.85);
    let voltPart1= Math.floor (local.local.rated_voltage*0.9  ) ;
    let voltPart2= Math.floor (local.local.rated_voltage*0.95  ); 
    let voltPart3= Math.floor (local.local.rated_voltage*1.05  ); 
    let voltPart4= Math.floor (local.local.rated_voltage*1.1  );
    let voltPart5= Math.floor (local.local.rated_voltage*1.15  );

    let voltValue= local.local.voltage;
    if( local.local.voltage<minVoltage){
      voltValue=minVoltage;
    }else if(local.local.voltage>maxVoltage ){
      voltValue=maxVoltage;
    }

    let fpValue= typeof local.local.fp === 'object' && local.local.fp !== undefined ? local.local.fp.value * 100 : 0;

    const columns = [
      {
        title: '' ,
        field: 'med',
        cellStyle: {
          fontWeight: '900',
          fontSize: '1em',
          textAlign: 'center',
          width: '10%',
          MinWidth: '10%'
        }
      },
      {
        title: intl.formatMessage({id: 'app.metersCards.card.gridCard.baseCol'}),
        field: 'bas',
  
        cellStyle: {
          backgroundColor: '#8CBA42',
          color: '#FFF',
          textAlign: 'center',
          fontWeight: '500',
          width: '30%',
          fontSize: '.95em'
        }
      },
      {
        title: intl.formatMessage({id: 'app.metersCards.card.gridCard.intermidateCol'}),
        field: 'int',
        cellStyle: {
          backgroundColor: '#EAAB36',
          color: '#FFF',
          textAlign: 'center',
          fontWeight: '500',
          width: '30%',
          fontSize: '.95em'
        }
      },
      {
        title: intl.formatMessage({id: 'app.metersCards.card.gridCard.tipCol'}),
        field: 'punt',
        cellStyle: {
          backgroundColor: '#D64539',
          color: '#FFF',
          textAlign: 'center',
          fontWeight: '500',
          width: '30%',
          fontSize: '.95em'
        }
      }
    ];

    const consumes = local.local.consumes !== 0 ? local.local.consumes : null;
    const consumesTable = consumes ? [consumes[0], consumes[1]] : null;

    const data = [];

    let obj;

    if(consumesTable !== null && consumesTable !== undefined) {
      consumesTable.forEach(element => {
        obj = {
          med: element[0],
          bas: element[1],
          int: element[2],
          punt: element[3]
        };
  
        data.push(obj);
      });
    }
      
    return (
      <>
      <TableRow
        className={classes.tableRow}
        key={local.local.id}
      >
        <TableCell>
          <Tooltip title="Vista Rapida">
            <IconButton
              aria-label="expand row"
              onClick={() => toggleRow(local.local.id)}
              size="small"
            >
              {openInfo[local.local.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          component="th"
          scope="row"
        >
          {local.local.name}
        </TableCell>
        <TableCell
          align="left"
        >$ {local.local.consumes.total}</TableCell>
        <TableCell
          align="left"
        >{local.local.consumes.total_kwh} kWh</TableCell>
        <TableCell
          align="left"
        >{local.local.consumes.demand_max} kW</TableCell>
        <TableCell
          align="left"
        >{local.local.consumes.co2} kg</TableCell>
        <TableCell
          onClick={() => handleSelected(local.local, local.local.coordinates.lat[0], local.local.coordinates.long[0])}
          style={{cursor:'pointer'}}
        >
          <Tooltip title="Ver Ubicación">
            <Room />
          </Tooltip>
        </TableCell>
        <TableCell
          onClick={() => setAdminMeterId(local.local.id)}
          style={{cursor:'pointer'}}
        >
          <Tooltip title="Ir a Grupo">
            <Link
              style={{ textDecoration: 'none', color: '#000'}}
              to={{
                pathname: `/meter/${local.local.id}`  ,
                state: {local}
              }}
            >
              <GroupWork />
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell
          onClick={() => handleDashboard(local.local)}
          style={{cursor:'pointer'}}
        >
          <Tooltip title="Dashboard">
            <Dashboard />
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow
        className={classes.tableRowSecond}
        style={{height: '0px'}}
      >
        <TableCell
          colSpan={12}
          style={{ margin: 0, height:'0px' }}
        >
          <Collapse
            in={openInfo[local.local.id]}
            timeout="auto"
            unmountOnExit
          >
            <Box padding="2.5rem 1.5rem">
              <Typography
                component="div"
                gutterBottom
                style={{marginBottom: '1rem'}}
                variant="h6"
              >
                Informacion general de {local.local.name}
              </Typography>
              <MaterialTable
                className={classes.table}
                columns={columns}
                data={data}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  padding: 0,
                  rowStyle: {
                    fontSize: 15,
                  },
                  headerStyle: {
                    textAlign: 'center',
                    padding: '0',
                    fontSize: '0.9em',
                    fontWeight: '900',
                    minWidth: '25%'
                  }
                }}
                sx={{borderRadius: 1}}
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
              >
                <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
                  <ReactSpeedometer
                    // currentValueText={fpValue}
                    customSegmentStops={[70,80,90,100 ]}
                    fontFamily={'Lato, Helvetica Neue,Arial'}
                    forceRender
                    height={120}
                    labelFontSize={'12px'}
                    maxValue={100}
                    minValue={70}
                    needleColor={'#000'}
                    needleHeightRatio={0.7}
                    paddingHorizontal={17}
                    paddingVertical={17}
                    ringWidth={30}
                    segmentColors={['#EB0000', '#FAC800', '#00C454' ]}
                    textColor={'#000'}
                    value={fpValue}
                    valueTextFontSize={'20px'}
                    valueTextFontWeight={'600'} 
                    width={135}
            
                  />
                  <Typography
                    className={classes.description}
                    style={{  margin :'0px' ,  padding: '0px'  ,  color: '#000' , fontWeight :'600' }}
                    variant="h5"
                  >
                    {intl.formatMessage({id: 'app.metersCards.card.fpLabel'})}
                  </Typography>
                </div>

                {local.local.voltage !== undefined && local.local.voltage !== undefined &&
                  (
                    <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
                      <ReactSpeedometer
                        currentValueText={'' +  local.local.voltage}
                        customSegmentStops={[voltPart0, voltPart1, voltPart2 , voltPart3 , voltPart4 , voltPart5 ]}
                        fontFamily={'Arial'}
                        forceRender
                        height={120}
                        labelFontSize={'12px'}
                        maxValue={maxVoltage}
                        minValue={minVoltage}
                        needleColor={'#000'}
                        needleHeightRatio={0.7}
                        paddingHorizontal={17}
                        paddingVertical={17}
                        ringWidth={30}
                        segmentColors={['#EB0000', '#FAC800', '#00C454','#FAC800' , '#EB0000' ]}
                        textColor={'#000'}
                        value={voltValue}
                        valueTextFontSize={'20px'}
                        valueTextFontWeight={'600'}
                        width={135}
              
                      />
                      <Typography
                        className={classes.description}
                        style={{  margin :'0px' ,  padding: '0px'  ,   color: '#000' , fontWeight :'600' }}
                        variant="h5"
                      >               
                        {intl.formatMessage({id: 'app.metersCards.card.voltageLabel'})}
                      </Typography>
                    </div>
                  )
                }
              </Box>
              <Grid
                item
                xs={12}
              >
                <Typography
                  style={{ fontSize: '.9em', textAlign: 'center' }}
                  variant="body1"
                >
                  {intl.formatMessage({id: 'app.metersCards.card.meassCurrMonth'})}
                </Typography>
                <Typography
                  style={{ fontSize: '.9em', textAlign: 'center'    }}
                  variant="body1"
                > 
                  {intl.formatMessage({id: 'app.metersCards.card.co2Msg'})}
                </Typography>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> 
    </>    
    )
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  
  const sortedMeters = filteredData.slice().sort((a, b) => {
    const aVal = getNestedValue(a, orderBy);
    const bVal = getNestedValue(b, orderBy);
  
    // Convierte a número si es una propiedad en "consumes" y aVal/bVal es una cadena válida
    const numericFields = ['consumes.total', 'consumes.total_kwh', 'consumes.co2', 'consumes.demand_max'];
    
    const aValNum = numericFields.includes(orderBy) 
      ? parseFloat((aVal || '0').toString().replace(/,/g, '')) 
      : aVal;
    const bValNum = numericFields.includes(orderBy) 
      ? parseFloat((bVal || '0').toString().replace(/,/g, '')) 
      : bVal;
  
    if (typeof aValNum === 'number' && typeof bValNum === 'number') {
      return order === 'asc' ? aValNum - bValNum : bValNum - aValNum;
    }
    
    if (typeof aValNum === 'string' && typeof bValNum === 'string') {
      return order === 'asc' ? aValNum.localeCompare(bValNum) : bValNum.localeCompare(aValNum);
    }
  
    return 0;
  });

  const handleSelected = (local, lat, long) => {
    mapContainer.current.scrollIntoView({ behavior: 'smooth', block: 'start'})
    map.panTo({ lat: lat, lng: long });
    map.setZoom(16);
  }

  return isLoaded ? (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
      >
        <Paper
          className={rootClassName}
          {...rest}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding=".5rem 1rem"
          >
            <Chip
              className={classes.chip}
              label="Locación de Nodos"
            />
          </Box>
          {metersWithLatLong.length !== 0 && loading === false ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              onLoad={onLoad}
              options={mapOptions}
              ref={mapRef}
            >
              {metersWithLatLong.map((local) => (
                <Marker
                  icon={customMarker}
                  key={local.id}
                  onClick={() => setSelected(local)}
                  position={{ lat: local.latitude, lng: local.longitude }}
                />
              ))}
              {selected && (
                <InfoWindow
                  onCloseClick={() => setSelected(0)}
                  position={{ lat: selected.latitude, lng: selected.longitude }}
                >
                  <Box
                    alignItems="stretch"
                    display="flex"
                    flexDirection="column"
                    height={200}
                    justifyContent="space-between"
                    style={{margin: '0 4px'}}
                    width={400}
                  >
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        container
                        item
                        justifyContent="space-between"
                        lignItems="flex-start"
                        spacing={2}
                        style={{margin: '2px 2px 1rem', justifyContent: 'space-between'}}
                        xs={12}
                      >
                        <Grid
                          container
                          direction="column"
                          item
                          justifyContent="center"
                          lg={7}
                          xs={7}
                        >
                          <Typography variant="h5">
                            {selected.name}             
                          </Typography>
                          <Typography
                            className={classes.heightLimit}
                            variant="h5"
                          >             
                            {selected.description}
                          </Typography>
                          <Typography
                            style={{backgroundColor: 'white'}}
                            variant="body1"
                          >             
                            {intl.formatMessage({id: 'app.metersCards.card.city'})}: {selected.city}<br />
                            {intl.formatMessage({id: 'app.metersCards.card.state'})}: {selected.state}
                          </Typography>
                        </Grid>
                        <Grid
                          direction="column"
                          item
                          justifyContent="right"
                          lg={4}
                          style={{ textAlign: 'right' }}
                          xs={4} 
                        >
                          <Grid
                            className={classes.buttonMore}
                            item
                            lg={12}
                            style={{paddingRigth: 0}}
                            xs={12}
                          >
                            <IconButton
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              aria-label="settings"
                              className={classes.settings}
                              onClick={handleShowMenu}
                            >
                              <Tooltip title={intl.formatMessage({ id: 'app.chargesCards.addCharge.stepper.setting.title' })}>
                                <Settings />
                              </Tooltip>
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              elevation={1}
                              id="simple-menu"
                              keepMounted
                              onClose={handleCloseMenu}
                              open={open}
                            >
                              <MenuItem onClick={handleShowDetails}>
                                {intl.formatMessage({id: 'app.details.btn'})}
                              </MenuItem>
                              {/* <MenuItem
                                    onClick={() =>
                                      getConsumesLastMonthData(
                                        meter.corporative_id,
                                        meter.id,
                                        new Date().getFullYear(),
                                        new Date().getMonth()
                                      )
                                    }
                                  >
                                    <Link
                                      style={{ textDecoration: 'none', color: '#000' }}
                                      to={{
                                        pathname: `/invoiceDataMeter/${meter.id}`,
                                        state: { meter }
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'app.metersCards.preInvBtn' })}
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      getConsumesLastMonthData(
                                        meter.corporative_id,
                                        meter.id,
                                        new Date().getFullYear(),
                                        new Date().getMonth()
                                      )
                                    }
                                  >
                                    <Link
                                      style={{ textDecoration: 'none', color: '#000' }}
                                      to={{
                                        pathname: `/preinvoice/${meter.id}`,
                                        state: { meter }
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'app.metersCards.invDataBtn' })}
                                    </Link>
                                  </MenuItem> */}
                              {isAllowed(user.permissions, ['meter_delete']) && (
                                <MenuItem onClick={() => deleteMeterData(selected, corpId)}>{intl.formatMessage({ id: 'app.metersCards.deleteMetBtn' })}</MenuItem>
                              )}
                              {isAllowed(user.permissions, ['meter_update']) && (
                                <MenuItem>
                                  <Link
                                    style={{ textDecoration: 'none', color: '#000' }}
                                    to={{
                                      pathname: `/updateMeter/${selected.id}`,
                                      state: { selected }
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'app.metersCards.editMeterBtn' })}
                                  </Link>
                                </MenuItem>
                              )}
                              {isAllowed(user.permissions, ['meter_update']) && (
                                <MenuItem onClick={handleShowDocuments}>Documentos</MenuItem>
                              )}
                            </Menu>
                          </Grid>
                        </Grid>
                      </Grid>
                  
                      <Grid
                        item
                        xs={12}
                      >
                        <Link
                          onClick={() => setAdminMeterId(selected.id)}
                          to={{
                            pathname: `/meter/${selected.id}`  ,
                            state: {
                              selected
                            }
                          }}
                        >
                          <Button
                            color="secondary"
                            style={{ width: '100%' }}
                            variant="contained"
                          >
                            {intl.formatMessage({id: 'app.metersCards.card.groupsBtn'})}s
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </InfoWindow>
              )}
            </GoogleMap>
          ):(
            <Box
              alignItems="center"
              display="flex"
              height={500}
              justifyContent="center"
              width={'100%'}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid
        item
        lg={12}
        xl={12}
        xs={12}
      >
        <Paper
          className={rootClassName}
          {...rest}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding=".5rem 1rem"
            position="relative"
          >
            <Chip
              className={classes.chip}
              label="Inventario de Nodos"
            />
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Typography variant="h6">{filteredData.length}</Typography>
              <Typography
                style={{marginLeft: '.5rem'}}
                variant="h6"
              >Filtar</Typography>
              <IconButton onClick={() => setMenuOpen(!menuOpen)}>
                <FilterList />
              </IconButton>
            </Box>
          </Box>
          {menuOpen && (
            <Box
              className={classes.filter}
              display="flex"
              justifyContent="center"
              my={2}
            >
              <FormControl style={{ margin: '0 10px', minWidth: 120 }}>
                <InputLabel>Estado</InputLabel>
                <Select
                  onChange={(e) => handleFilterChange('state', e.target.value)}
                  value={stateFilter}
                >
                  <MenuItem value=""><em>Todos</em></MenuItem>
                  {uniqueStates.map(state => (
                    <MenuItem
                      key={state}
                      value={state}
                    >
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                disabled={!stateFilter}
                style={{ margin: '10px', minWidth: 120 }}
              >
                <InputLabel>Municipio/Delegación</InputLabel>
                <Select
                  onChange={(e) => handleFilterChange('city', e.target.value)}
                  value={cityFilter}
                >
                  <MenuItem value=""><em>Todos</em></MenuItem>
                  {uniqueCities.map(city => (
                    <MenuItem
                      key={city}
                      value={city}
                    >
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box
            alignItems="start"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding="1rem"
            style={{overflowX: 'auto'}}
          >
            <Table
              aria-label="collapsible table"
              size="small"
            >
              <TableHead>
                <TableRow style={{backgroundColor: '#e2e2e2'}}>
                  <TableCell />
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={() => handleRequestSort('name')}
                    >
                      Locación
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'consumes.total'}
                      direction={orderBy === 'consumes.total' ? order : 'asc'}
                      onClick={() => handleRequestSort('consumes.total')}
                    >
                      Importe Total
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'consumes.total_kwh'}
                      direction={orderBy === 'consumes.total_kwh' ? order : 'asc'}
                      onClick={() => handleRequestSort('consumes.total_kwh')}
                    >
                      Consumo
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'consumes.demand_max'}
                      direction={orderBy === 'consumes.demand_max' ? order : 'asc'}
                      onClick={() => handleRequestSort('consumes.demand_max')}
                    >
                      Demanda Máxima
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'consumes.co2'}
                      direction={orderBy === 'consumes.co2' ? order : 'asc'}
                      onClick={() => handleRequestSort('consumes.co2')}
                    >
                      CO2
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Ubicación
                  </TableCell>
                  <TableCell>
                    Grupos
                  </TableCell>
                  <TableCell>
                    Dashboard
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMeters.length > 0 ? sortedMeters.map((local) => (
                  <Rows
                    columns={columns}
                    data={data}
                    key={local.id}
                    local={local}
                  />
                )) : <CircularProgress />}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  ) : null;
};

export default LocationMaps;