/* eslint-disable react/prop-types */
/* eslint-disable no-class-assign */
import React, { Component } from 'react';

import palette from 'theme/palette';


// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';
import { injectIntl } from 'react-intl';

class SalesChart extends Component {


  render() {
    const { classes, className, consumes, typeEnergy, rateHorary,  ...rest } = this.props;
    // const { charges } = this.props.charges
    const { intl } = this.props;

    const consumesGroup = [consumes[0],consumes[1]]
    
    const dataConsumes = [];

    consumesGroup.forEach(element=>{
      dataConsumes.push(element.filter( e=> e!=='kW' && e!=='kWh'))
    })  

    const labels = typeEnergy === 2 ? 
      [
        intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.base'}),
        intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.interm'}),
        intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.tip'})]
      : rateHorary === 1 ? 
        [
          intl.formatMessage({id: 'app.group.board.demandAndConsump.dataSet.base'}),
          intl.formatMessage({id: 'app.group.board.demandAndConsump.dataSet.interm'}),
          intl.formatMessage({id: 'app.group.board.demandAndConsump.dataSet.tip'})
        ]: [intl.formatMessage({id: 'app.charge.board.demandAndConsump.title'})] 

    const dataSetsOptions = rateHorary === 1 ? [
      {
        label: ' kW',
        yAxisID:'A',
        backgroundColor: ['#8CBA42', '#ebc057','#ED685E'],
        data: dataConsumes[0].map(e => e.replace(',', ''))
      },
      {
        label: ' kWh',
        yAxisID:'B',
        backgroundColor: ['#3A5F0B', '#FFCC00','red'],
        data: dataConsumes[1].map(e => e.replace(',', ''))
      },
    ] : [
      {
        label: ' kWh',
        yAxisID:'B',
        backgroundColor: ['#A53693'],
        data: [parseFloat(consumes.total_kwh.replace(/,/g, ''))]            
      },
      {
        label: ' kW',
        yAxisID:'A',
        backgroundColor: ['#00AFEF'],
        data: [parseFloat(consumes.demand_max.replace(/,/g, ''))]
      },
    ];

    const data = {
      labels: labels,
      datasets: dataSetsOptions,
    }
  
    const options = { responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      cornerRadius: 20,
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.border,
        backgroundColor: palette.common.white,
        titleFontColor: palette.text.primary,
        bodyFontColor: palette.text.secondary,
        footerFontColor: palette.text.secondary
      },
      layout: { padding: { top:20 }  },
      scales: {
        xAxes: [
          { 
            
            barPercentage: 0.9,
            categoryPercentage: 0.8,
            ticks: {
              fontColor: palette.text.secondary,
              fontSize: 16,
              padding: 20
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ],
        yAxes: [
          {
            id: 'A',
            position:'left',
            ticks: {
              fontColor: '#730065',
              beginAtZero:true,
              callback: function(value) {
                return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
              }
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: palette.divider,
              drawBorder: false,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
              zeroLineColor: palette.divider
            }
          },
          {
            id: 'B',
            position:'right',
            ticks: {
              fontColor: '#0080BC',
              beginAtZero:true,
              callback: function(value) {
                return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
              }       
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: palette.divider,
              drawBorder: false,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
              zeroLineColor: palette.divider
            }
          }
        ]
      },
      animation: {
        onComplete: function () {
          var chartInstance = this.chart,
            ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = '#000';
          ctx.textBaseline = 'top';
          ctx.font = '20px Lato, Helvetica Neue, Arial, 700';

          this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = dataset.data[index];
              //  ctx.fillText(data, bar._model.x, bar._model.y + 5);
              if( data ){
                ctx.fillText(data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), bar._model.x, bar._model.y - 20 );
              }
            });
          });
        }
      }
    };

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title={typeEnergy === 2 ? intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.title'}) : rateHorary === 1 ? intl.formatMessage({id: 'app.group.board.demandAndConsump.title'}) : intl.formatMessage({id: 'app.charge.board.demandAndConsump.title'})}  />
          <PortletToolbar />
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Bar
              data={data}
              options={options}
            />
            {rateHorary === 1 ? (
              <div
                className={classes.labelCharts}
                style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}
              >
                <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '2rem'}}>
                  <Typography>kW</Typography>
                  <Typography>kWh</Typography>
                </div>
                <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                  <Typography>kW</Typography>
                  <Typography>kWh</Typography>
                </div>
                <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingRight: '4rem'}}>
                  <Typography>kW</Typography>
                  <Typography>kWh</Typography>
                </div>
              </div>
            ):(
              <div
                className={classes.labelCharts}
                style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '2rem'}}>
                  <Typography>{intl.formatMessage({id: 'app.group.board.mainHead.maxDemand'})}</Typography>
                  <Typography>{intl.formatMessage({id: 'app.charge.board.highConsumDayCurrMonth.consumption'})}</Typography>
                </div>
              </div>
            )}
            
          </div>
        </PortletContent>
      </Portlet>
    );
  }
}

SalesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};


SalesChart = injectIntl(SalesChart);

export default withStyles(styles)(SalesChart);
