export default theme => ({
  root: {
    backgroundColor: '#00AFEF',
    borderColor: '#00AFEF',
    padding: theme.spacing.unit * 3,
    cursor: 'pointer'
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  details: {},
  title: {
    fontWeight: 700,
    color: theme.palette.common.white,
    fontSize:'1em',
  },
  value: {
    color: theme.palette.common.white,
    marginTop: theme.spacing.unit,
    transition: 'all 0.5s ease-in-out'
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: '#00AFEF',
    width: '2rem',
    height: '2rem',
    fontSize: '2rem'
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center'
  },
  difference: {
    alignItems: 'center',
    color: theme.palette.success.dark,
    display: 'inline-flex',
    fontWeight: 700
  },
  caption: {
    marginLeft: theme.spacing.unit,
    color: '#fff'
  }
});
