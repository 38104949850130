import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid
} from '@material-ui/core';

import { showMeters, deleteMeter } from 'js/actions/meterActions';
import { showGroups, deleteGroup } from 'js/actions/groupActions';
import { showCharge, deleteCharge } from 'js/actions/chargeActions';

import { getReportTotalesStarted } from 'js/actions/reportsActions';

import { useDispatch, useSelector } from 'react-redux';

 
import Swal from 'sweetalert2';
import { ReportRounded } from '@material-ui/icons';
import { isAllowed } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  }
}));



export default function InputAdornments() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const user = useSelector(state => state.user.user);
  const corporatives = useSelector(state => state.corporatives.corporatives);
  const meters = useSelector(state => state.meters.meters);
  let groups = useSelector(state => state.groups.groups);
  let charges = useSelector(state => state.charges.charges);
  //let year = new Date().getFullYear()

  // const corpAdmin = useSelector(state => state.user.corporative);
  // const corpUser = user.corporative;
  // const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id;


  const [corporative, setCorporative] = useState();
  const [meter, setMeter] = useState();
  const [group, setGroup] = useState([]);
  const [charge, setCharge] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [type, setType] = useState();



  function submitReport(e) {
    if(  !meter ||  !group  || !charge  || !year || !month  ){

      Swal.fire({
        title: '',
        text: 'Se requiere seleccionar todos los campos para generar el reporte',
        icon: 'warning'
      });

      e.preventDefault();
      return;
    }

    // let urlParam="https://api.metimur.mx/api/report/excel/totals/meter_id/"+meter+"/group_ids/"+ group +"/year/"+ year +"/month/"+ month;
    //getReportTotalesStarted(urlParam);
    
    
  }




  const getSelector = corp => {
    if (corp == 1) {
      return (
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Corporativo
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="corporative"
            onChange={handleCorporative}
            value={corporative}
            //labelWidth={labelWidth}
          >
            {corporatives
              ? corporatives.map(el => (
                <MenuItem value={el.id}>{el.name}</MenuItem>
              ))
              : console.log('cargando tarifas')}
          </Select>
        </FormControl>
      );
    } else {
      return (
        // <FormControl className={classes.textField} variant="outlined">
        //   <InputLabel id="demo-simple-select-outlined-label">
        //     Corporativo
        //   </InputLabel>
        //   <Select
        //     id="demo-simple-select-outlined"
        //     labelId="demo-simple-select-outlined-label"
        //     name="corporative"
        //     inputRef={register}
        //     onChange={handleCorporative}
        //     value={corporativeId}
        //     //labelWidth={labelWidth}
        //   >
        //     <MenuItem value={corporativeId}>{user.corporative.name}</MenuItem>
        //   </Select>
        // </FormControl>
        null
      );
    }
  };

  const onSubmit = values => {
    //dispatch(getTotalReports(user));
    // console.log(user);

    if(  !meter ||  !group  || !charge  || !year || !month  ){

      Swal.fire({
        title: '',
        text: 'Se requiere seleccionar todos los campos para generar el reporte',
        icon: 'warning'
      });

        
      return;
    }

    let urlParam='https://api.metimur.mx/api/report/excel/totals/meter_id/'+meter+'/group_ids/'+ group +'/year/'+ year +'/month/'+ month;
    dispatch(getReportTotalesStarted(urlParam));





  };

  const getMeters = corporativeId => {
    dispatch(showMeters(corporativeId));
  };

  const getGroups = meterId => {
    dispatch(showGroups(meterId));
  };

  const getCharges = groupId => {
    dispatch(showCharge(groupId));
  };

  const handleCorporative = corporative => {
    setCorporative(corporative.target.value);
    setCharge( [] )
    setGroup([] )
    getMeters(corporative.target.value);
  };

  const handleMeter = meter => {
    console.log('HandleMeter ....');
    setMeter(meter.target.value);
    setGroup([])
    getGroups(meter.target.value);
   

    setCharge( [] );
  };

  const handleGroup = group => {
    console.log('HandleGroup ....');    
    setGroup(group.target.value);
    //getCharges(group.target.value);
  };

  const handleCharge = charge => {
    setCharge(charge.target.value);
    //getCity(state.target.value);
  };

  const handleYear = year => {
    setYear(year.target.value);
    //getCity(state.target.value);
  };

  const handleMonth = month => {
    setMonth(month.target.value);
    //getCity(state.target.value);
  };

  const handleType = type => {
    setType(type.target.value);
    //getCity(state.target.value);
  };

  return (
    <div>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            {getSelector(user.adser)}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Medidor
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="meter"
                onChange={handleMeter}
                value={meter}
                //labelWidth={labelWidth}
              >
                {meters
                  ? meters.map(el => (
                    <MenuItem value={el.id}>{el.name}</MenuItem>
                  ))
                  : console.log('cargando tarifas')}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Grupo
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                multiple
                name="group"
                onChange={handleGroup}
                value={group}
                //labelWidth={labelWidth}
              >
                {isAllowed(user.permissions, ['download_report_structure']) && (
                  <MenuItem value="TODAS">TODAS</MenuItem>
                )}
                {groups
                  ? groups.map(el => (
                    <MenuItem value={el.id}>{el.name}</MenuItem>
                  ))
                  : console.log('cargando tarifas')}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item lg={3} md={3} xs={3}>
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Medicion
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                name="charge"
                multiple
                inputRef={register}
                onChange={handleCharge}
                value={charge}
                //labelWidth={labelWidth}
              >
                <MenuItem value="TODAS">TODAS</MenuItem>
                {charges
                  ? charges.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                  : console.log('cargando tarifas')}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Año
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="year"
                onChange={handleYear}
                value={year}
                //labelWidth={labelWidth}
              >
                <MenuItem value="2019">2019</MenuItem>
                <MenuItem value="2020">2020</MenuItem>
                <MenuItem value="2021">2021</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Mes
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="month"
                onChange={handleMonth}
                value={month}
                //labelWidth={labelWidth}
              >
                <MenuItem value="1">Enero</MenuItem>
                <MenuItem value="2">Febrero</MenuItem>
                <MenuItem value="3">Marzo</MenuItem>
                <MenuItem value="4">Abril</MenuItem>
                <MenuItem value="5">Mayo</MenuItem>
                <MenuItem value="6">Junio</MenuItem>
                <MenuItem value="7">Julio</MenuItem>
                <MenuItem value="8">Agosto</MenuItem>
                <MenuItem value="9">Septiembre</MenuItem>
                <MenuItem value="10">Octubre</MenuItem>
                <MenuItem value="11">Noviembre</MenuItem>
                <MenuItem value="12">Diciembre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Tipo de Reporte
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                name='type'
                inputRef={register}
                onChange={handleType}
                value={type}
                //labelWidth={labelWidth}
              >
                <MenuItem value='CONSUME'>Consumo</MenuItem>
                <MenuItem value='DEMAND'>Demanda</MenuItem>
              
                  
              </Select>
            </FormControl>
          </Grid> */}

          <a
            href={`https://api.metimur.mx/api/report/excel/totals/meter_id/${meter}/group_ids/${group}/year/${year}/month/${month}`}
            onClick={submitReport}
            style={{ color: '#fff' }}
          >          
      
            <Button
              className={classes.signUpButton}
              color="secondary"
              //onClick={this.handleSubmit}
              size="large"
              style={{
                backgroundColor: '#FEC12C',
                width: '250px',
                height: '45px',
                marginTop: '20px'
              }}
              //type="submit"
              variant="contained"
            >
              Descargar
            </Button>
          </a>  
          
        </Grid>
      </form>
    </div>
  );
}
