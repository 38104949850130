export default theme => ({
  root:{
    position: 'relative',
  },
  tableRowSecond: {
    heigth: '0px',
    '& .MuiTableCell-body': {
      padding:'0px !important'
    }
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  chip: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  filter: {
    position: 'absolute',
    top: '10%',
    right: '1%',
    zIndex: 99,
    backgroundColor: 'white',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    width: '20rem',
    padding: '1rem',
    boxShadow: '0 0 5px #999999'
  },
  title: {
    padding: theme.spacing.unit * 2
  }
})