/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { CircularProgress, withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { TrendingUpOutlined } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class Budget extends Component {
  render() {
    const { classes, className, meters, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    const consumeTotal = meters ? meters.reduce((sum, consume) => {
      if (typeof consume.consumes.total_kwh === 'string'){
        return sum + parseFloat(consume.consumes.total_kwh.replace(/,/g, ''));
      } else {
        return sum + consume.consumes.total_kwh
      }
    }, 0) : 0;


    const formatt = (number) => {
      return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              Consumo Acumulado al Día
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {consumeTotal ? `${formatt(consumeTotal)} kWh` : (<CircularProgress style={{color: 'white'}}/>)} 
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <TrendingUpOutlined className={classes.icon} />
          </div>
        </div>
      </Paper>
    );
  }
}

Budget.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Budget);
