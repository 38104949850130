/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import axios from 'axios';
import getToken, { isAllowed } from 'helpers/auth';
import { Link } from 'react-router-dom';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from '@material-ui/core';

import { showMeters, deleteMeter } from 'js/actions/meterActions';
import { showGroups, deleteGroup } from 'js/actions/groupActions';
import { showCharge, deleteCharge } from 'js/actions/chargeActions';

import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { MTablePagination } from 'material-table';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useIntl } from 'react-intl';
import { error } from 'highcharts';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  signUpButton: {
    backgroundColor:'#FEC12C', 
    width:'250px', 
    height:'45px', 
    marginTop:'20px',
    [theme.breakpoints.down('md')]:{
      width: '100%'
    }
  }
}));

export default function InputAdornments() {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const corporatives = useSelector(state => state.corporatives.corporatives);
  const meters = useSelector(state => state.meters.meters);
  let groups = useSelector(state => state.groups.groups);
  let charges = useSelector(state => state.charges.charges);
  const user = useSelector(state => state.user.user);
  //let year = new Date().getFullYear()

  const [corporative, setCorporative] = useState();
  const [meter, setMeter] = useState();
  const [group, setGroup] = useState();
  const [charge, setCharge] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [type, setType] = useState();
  const [linkRep, setLinkRep] = useState();
  const [blocking, setBlocking] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  const corpAdmin = useSelector(state => state.user.corporative);
  const corpUser = user.corporative;

  const currYear = new Date().getFullYear();
  
  const years = [currYear-3 , currYear-2 , currYear-1 , currYear  ];

  useEffect(() => {    
    if( user.adser!=1  && corpUser ){     
      getMeters(corpUser.id);
    }

  }, []);

  const onSubmit = report => {
    setState({ ...state, data: [] });  
    dispatch(doSearch(report));
  };

  function doSearch(report){
    setState({ ...state, data: [] });  
    return async dispatch => {               
      axios({
        method: 'post',
        url: 'https://api.metimur.mx/api/invoice/files' , 
        data: {
          corporative_id :  corporative  ,
          meter_id :  meter ,
          group_id : group   ,
          charges_ids : charge   ,
          year :  year ,
          month : month 
        
        },
        headers : {
          Authorization: 'Bearer ' + getToken()
        }  
      }).then(function (response) { 
        setState({ ...state, data: response.data.data });
      //  endBlocking();
      }).catch(function () {
        Swal.fire({
          title: '',
          text: intl.formatMessage({id: 'app.invoices.error'}),
          icon: 'error'
        });          
      });
    };
  }


  function endBlocking( ){
    setBlocking(false);
  }


  function submitReport(e) {
    if( !group || !year || !month || !charge || !type  ){
      Swal.fire({
        title: '',
        text: intl.formatMessage({id: 'app.invoices.error'}),
        icon: 'warning'
      });
      e.preventDefault();
    }
    console.log('The link was clicked.'+ linkRep );
  }

  const getSelector = corp => {
    if (corp == 1) {
      return (
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel id="demo-simple-select-outlined-label">
            {intl.formatMessage({id: 'app.invoices.corporateLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="corporative"
            onChange={handleCorporative}
            value={corporative}
            //labelWidth={labelWidth}
          >
            {corporatives
              ? corporatives.map(el => (
                <MenuItem value={el.id}>{el.name}</MenuItem>
              ))
              : (<MenuItem />)}
          </Select>
        </FormControl>
      );
    } else {
      return null
    }
  };


  const getLinkReceipt = url => {
    if( url ){
      return ( 
        <Link
          className={classes.iconTable}
          download
          target="_blank"
          to={{
            pathname: `${url}`               
          }}
        >
          <img
            alt=""
            border="0"
            height="40"
            src="/images/download_pdf.png"
            title={intl.formatMessage({id: 'app.invoices.linkReceipDonloadFac'})}
            width="40"
          />
        </Link>
      )
    }else{
      return (
        <img
          alt=""
          border="0"
          height="25"
          src="/images/cruz_roja.png"
          title={intl.formatMessage({id: 'app.invoices.linkReceipNoAvail'})}
          width="25"
        />
      )
    }
  }

  const getLinkInvoice = url => {
    if( url ){
      return ( 
        <Link
          className={classes.iconTable}
          download
          target="_blank"
          to={{
            pathname:  `${url}`
          }}
        >
          <img
            alt=""
            border="0"
            height="40"
            src="/images/download_pdf.png"
            title={intl.formatMessage({id: 'app.invoices.linkInvDonloadFac'})}
            width="40"
          />
        </Link>
      )
    }else{
      return (
        <img
          alt=""
          border="0"
          height="25"
          src="/images/cruz_roja.png"
          title={intl.formatMessage({id: 'app.invoices.linkInvNoAvail'})}
          width="25"
        />
      )
    }
  }

  const getLinkInvoiceXML = url => {
    if( url ){
      return (
        <Link
          className={classes.iconTable}
          download
          target="_blank"
          to={{
            pathname: `${url}`               
          }}
        >
          <img
            alt=""
            border="0"
            height="40"
            src="/images/download_xml.png"
            title={intl.formatMessage({id: 'app.invoices.linkInvDonloadFacXML'})}
            width="40"
          />
        </Link>
      )
    }else{
      return (
        <img
          alt=""
          border="0"
          height="25"
          src="/images/cruz_roja.png"
          title={intl.formatMessage({id: 'app.invoices.linkInvNoAvailXML'})}
          width="25"
        />
      )
    }      
  }

  const [state, setState] = React.useState({
    columns: [
      
      { title: intl.formatMessage({id: 'app.invoices.grid.CharNameCol'}), field: 'charge_name' },       
      {
        title: intl.formatMessage({id: 'app.invoices.grid.pdfReceiptCol'}),
        style: { textAlign: 'center' } ,
        render: rowData => (
           
          getLinkReceipt(rowData.url_stream)  
 
        )
      },     
      {
        title: intl.formatMessage({id: 'app.invoices.grid.pdfPreInvCol'}),
        style: { textAlign: 'center' } ,
        render: rowData => (
           
          getLinkReceipt(rowData.url_receipt_preinvoiced)  
 
        )
      },              
      {
        title:  intl.formatMessage({id: 'app.invoices.grid.pdfIvnvoiceCol'}),
        style: { textAlign: 'center' } ,
        render: rowData => (
          getLinkInvoice( rowData.url_pdf )           
        )
      },
      {
        title: intl.formatMessage({id: 'app.invoices.grid.xmlCol'}) ,

        render: rowData => (          
          getLinkInvoiceXML(rowData.url_xml)                     
        )
      }
    ],
  });

  const handleRowSelect = (rows) => {
    setSelectedRows(rows);
  };

  const invoiceName = () => {
    const nameZip = [];
    const groupName = groups.find(groupId => groupId.id === group);
    const meterObj = meters.find(meterId => meterId.id === meter);
    const meterName = meterObj ? meterObj.name : ''; 
    nameZip.push(meterName);
    nameZip.push(groupName.name);
    console.log(meterName);
    console.log(meter);
    nameZip.push(month);
    nameZip.push(year);
    return nameZip.join('-');
  }

  const handleDownloadZip = async () => {
    const zip = new JSZip();
    const zipName = invoiceName();
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      zIndex: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    await Promise.all(selectedRows.map(async (row) => {
      try {
        const response = await axios.get(row.url_stream, { responseType: 'blob' });
        zip.file(`${row.charge_name}.pdf`, response.data);
        
      } catch (error) {
        console.error(`Error al descargar el archivo ${row.charge_name}: ${error.message}`);
        const errorMessage = error;
      }
    }));

    if(error){
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage({id: 'app.invoices.downloadMessageSuccess'})
      });
    }else{
      Toast.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'app.invoices.downloadMessageFailure'})
      });
    }
  
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, `${zipName}.zip`);
    });
  };

  console.log(selectedRows);

  const DownloadButton = () => {
    return (
      <Button
        color="primary"
        disabled={selectedRows.length === 0}
        onClick={handleDownloadZip}
        style={{ height: 'fit-content', marginRight: '1rem' }}
        variant="contained" 
      >
        {intl.formatMessage({id: 'app.invoices.receiptbBtn'})}
      </Button>
    )
  }

  const CustomPagination = (props) => {
    const { ActionsComponent, ...other } = props;
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <ActionsComponent {...other} />
        </div>
        <DownloadButton />
      </div>
    );
  }

  const getMeters = corporativeId => {
    dispatch(showMeters(corporativeId));
  };

  const getGroup = meterId => {
    dispatch(showGroups(meterId));
  };

  const getCharges = groupId => {
    const p= [];
    p[0]=groupId;
    dispatch(showCharge( p ));
  };

  const handleCorporative = corporative => {
    setCorporative(corporative.target.value);
    setCharge([]);
    setGroup(null);
    getMeters(corporative.target.value);
  };

  const handleMeter = meter => {
    setMeter(meter.target.value);
    setCharge([]);
    setGroup(null);
    getGroup(meter.target.value);
  };

  const handleGroup = group => {
    setGroup(group.target.value);
    setCharge([]);
    getCharges(group.target.value  );    

    /*
    if( group.target.value.length==1  ){         
          getCharges(group.target.value  );             
    }else{
         getCharges(0); 
    } */


  };

  const handleCharge = chargeVal => {
    setCharge(chargeVal.target.value);
    //getCity(state.target.value);
  };

  const handleYear = year => {
    setYear(year.target.value);
    //getCity(state.target.value);
  };

  const handleMonth = month => {
    setMonth(month.target.value);
    //getCity(state.target.value);
  };

  const handleType = type => {
    setType(type.target.value);
    //getCity(state.target.value);
  };

  return (
    <div>
      <BlockUi
        blocking={blocking}
        tag="div"
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        //onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            lg={12}
            md={12}
            spacing={3}
            xs={12}
          >
            <Grid
              item
              lg={getSelector(user.adser) === null ? 0 : 3}
              md={getSelector(user.adser) === null ? 0 : 6}
              style={{padding: getSelector(user.adser) === null ? '0' : '12px'}}
              xs={getSelector(user.adser) === null ? 0 : 6}
            >
              {getSelector(user.adser)}
            </Grid>
            <Grid
              item
              lg={3}
              md={getSelector(user.adser) === null ? 12 : 6}
              xs={getSelector(user.adser) === null ? 12 : 6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.invoices.meterLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="meter"
                  onChange={handleMeter}
                  value={meter}
                //labelWidth={labelWidth}
                >
                  {meters
                    ? meters.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : (<MenuItem />)}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.invoices.groupLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"            
                  name="group"
                  onChange={handleGroup}
                  value={group}
                //labelWidth={labelWidth}
                >
                  {groups
                    ? groups.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : (<MenuItem />)}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.invoices.chargeLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  multiple
                  name="charge"
                  onChange={handleCharge}
                  value={charge}
                //labelWidth={labelWidth}
                >
                  {isAllowed(user.permissions, ['download_report_structure']) && (
                    <MenuItem value="TODAS">TODAS</MenuItem>
                  )}
                  {charges
                    ? charges.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : (<MenuItem />)}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.invoices.yearLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="year"
                  onChange={handleYear}
                  value={year}
                //labelWidth={labelWidth}
                >               
                  {                 
                    years.map(yr => (
                      <MenuItem value={yr}>{yr}</MenuItem>
                    ))                                    
                  }

                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.invoices.monthLb'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="month"
                  onChange={handleMonth}
                  value={month}
                //labelWidth={labelWidth}
                >
                  <MenuItem value="01">{intl.formatMessage({id: 'app.months.jan'})}</MenuItem>
                  <MenuItem value="02">{intl.formatMessage({id: 'app.months.feb'})}</MenuItem>
                  <MenuItem value="03">{intl.formatMessage({id: 'app.months.mar'})}</MenuItem>
                  <MenuItem value="04">{intl.formatMessage({id: 'app.months.apr'})}</MenuItem>
                  <MenuItem value="05">{intl.formatMessage({id: 'app.months.may'})}</MenuItem>
                  <MenuItem value="06">{intl.formatMessage({id: 'app.months.jun'})}</MenuItem>
                  <MenuItem value="07">{intl.formatMessage({id: 'app.months.jul'})}</MenuItem>
                  <MenuItem value="08">{intl.formatMessage({id: 'app.months.aug'})}</MenuItem>
                  <MenuItem value="09">{intl.formatMessage({id: 'app.months.sep'})}</MenuItem>
                  <MenuItem value="10">{intl.formatMessage({id: 'app.months.oct'})}</MenuItem>
                  <MenuItem value="11">{intl.formatMessage({id: 'app.months.nov'})}</MenuItem>
                  <MenuItem value="12">{intl.formatMessage({id: 'app.months.dec'})}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Button
              className={classes.signUpButton}
              color="secondary"
              //onClick={this.handleSubmit}
              size="large"
              type="submit"
              variant="contained"
            >
              {intl.formatMessage({id: 'app.invoices.searchBtn'})}
            </Button>
          </Grid>
        </form>
      </BlockUi>
      <div style={{ marginTop: '30px'  }}  >
        <MaterialTable
          actions={state.actions}
          columns={[
      
            { title: intl.formatMessage({id: 'app.invoices.grid.CharNameCol'}), field: 'charge_name', headerStyle: { textAlign: 'center'} },     
            {
              title: intl.formatMessage({id: 'app.invoices.grid.pdfReceiptCol'}),
              style: { textAlign: 'center' } ,
              render: rowData => (
             
                getLinkReceipt(rowData.url_stream)  
   
              )
            },     
            {
              title: intl.formatMessage({id: 'app.invoices.grid.pdfPreInvCol'}),
              style: { textAlign: 'center' } ,
              render: rowData => (
             
                getLinkReceipt(rowData.url_receipt_preinvoiced)  
   
              )
            },              
            {
              title:  intl.formatMessage({id: 'app.invoices.grid.pdfIvnvoiceCol'}),
              style: { textAlign: 'center' } ,
              render: rowData => (
                getLinkInvoice( rowData.url_pdf )           
              )
            },
            {
              title: intl.formatMessage({id: 'app.invoices.grid.xmlCol'}) ,
  
              render: rowData => (          
                getLinkInvoiceXML(rowData.url_xml)                     
              )
            }
          ]}
          components={{
            Pagination: props => <CustomPagination
              {...props}
              ActionsComponent={MTablePagination}
            />,
          }}
          data={state.data}
          onSelectionChange={handleRowSelect}
          options={{
            selection: true,
            selectionProps: rowData => ({
              disabled: rowData.url_stream === null,
              color: 'primary'
            })
          }}
          title={intl.formatMessage({id: 'app.invoices.grid.title'})}
        />
      </div>
    </div>
  );
}
