import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { postForm } from 'js/actions/formsAction';
import {postUserAlerts} from 'js/actions/userAlertsAction'
 
 
import {
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { deleteLastPostForm } from 'js/actions/formsAction';

const useStyles = makeStyles(styles);

function NameRoleForm(props) {

  const alertEx = props.alert||null 

  const alert_prior=[ 'BAJO' , 'MEDIO' , 'ALTO' ];
  const dias_sem=[ 'Lun' , 'Mar' , 'Mie' , 'Jue' , 'Vie' , 'Sab' , 'Dom' ];


  const classes = useStyles();
  const { ids } = props
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const { data } = formData;
  const rules = data[1].data;
  const contacts = data[2].data

  const alert = {
    id : alertEx ? alertEx.id : null ,
    name_alert: data[0].data.name_alert,
    message_alert: data[0].data.message_alert,
    lvl_alert:data[0].data.lvlAlert,
    group_id: alertEx ? alertEx.group_id : ids.groupId,
    charge_id: alertEx ? alertEx.charge_id : ids.chargeId,
    rules:rules,
    contacts:[],
  }

  contacts.forEach(person => {
    alert.contacts.push(person)
  });

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }

  function completeAlertUser(alert) { 
    dispatch(postUserAlerts(alert));
     
  }

  let getRuleType = type =>{
    switch (type) {
      case '1':
        return ('CORRIENTE')
      
      case '2':
        return 'POTENCIA'

      default:
        break;
    }
  }

  return (
    <div className={classes.fields}>
      <div className={classes.form}>
        <Typography variant="h4">Confirmacion de Alerta</Typography>
        <Typography variant="body1"> Nombre de Alerta: {alert.name_alert} </Typography>
        <Typography variant="body1"> Mensaje : {alert.message_alert}</Typography>
        <Typography variant="body1"> Prioridad : { alert_prior[ alert.lvl_alert -1 ]}</Typography>
        <Typography variant="body1">
          Reglas:
        </Typography>
        <List component="div">
          {rules.map(rule => (
            <ListItem
              className={classes.listItem}
              component="div"
            >
              <Typography>Tipo:{ getRuleType(rule.type) }&nbsp;&nbsp;&nbsp; </Typography>
              <Typography>Valor Mínimo:{  rule.min_value }&nbsp;&nbsp;&nbsp; </Typography>
              <Typography>Valor Máximo:{ rule.max_value }&nbsp;&nbsp;&nbsp; </Typography>
              <Typography>Días de la semana:{ rule.days.map( dayVal => dias_sem[dayVal-1]+ ' ' )  }&nbsp;&nbsp;&nbsp; </Typography>
            </ListItem>
          ))}
        </List>
        <div
          style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
        >
          <Button
            color="secondary"
            onClick={handleBack}
            size="large"
            style={{width: '45%'}}
            variant="outlined"
          >
            Regresar
          </Button>
          <Button
            color="secondary"
            onClick={() => completeAlertUser(alert)}
            size="large"
            style={{width: '45%'}}
            variant="contained"
          >
            Confirmar
          </Button>
        </div>
      </div>
    </div>    
  );
}

export default NameRoleForm;
