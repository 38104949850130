export default theme => ({
  root:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid rgb(128 128 128 / 50%)'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  contentContainer: {
    display: 'flex'
  },
  buttonUpload:{
    height: '8rem',
    width: '8rem',
    border: '1px solid rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    cursor: 'pointer',
    paddingLeft: '3.2rem'
  },
  selectContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  folder: {
    height: '7rem',
    width: '8rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  file: {
    height: '8rem',
    width: '8rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'contain',
    border: '1px solid rgb(128 128 128 / 50%)',
  },
  image: {
    height: '7rem',
    width: '8rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  document: {
    height: '7rem',
    width: '8rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  documentItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    '&:hover $actions': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  actions: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    opacity: 0,
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
  },
})