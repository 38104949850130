import React, { useState } from 'react';
import { Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { ExpandLess, ExpandMore, FileCopy } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  ...styles,
  fixedHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  thumbnail: {
    objectFit: 'cover'
  },
  item: {
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#DEDEDE'
    }
  },
  currentVideo: {
    backgroundColor: '#DEDEDE'
  },
  toggleButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const PDFList = (props) => {
  const { pdf } = props;
  const classes = useStyles();
  const [openCategories, setOpenCategories] = useState({}); // Estado para manejar las categorías abiertas/cerradas
  

  const groupedPDF = pdf.reduce((acc, item) => {
    const category = item.catergory || 'Sin Categoría'; // Usa una categoría por defecto si no hay
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});

  const toggleCategory = (category) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  
  return (
    <List
      component="div"
      disablePadding
      style={{ maxHeight: '650px', overflow: 'auto' }}
    >
      {/* Mapear cada categoría y sus elementos */} 
      {pdf.length === 0 ? (
        <Typography 
          align="center"
          variant="h6"
        >
          No hay documentos disponibles
        </Typography>
      ) : 
        Object.entries(groupedPDF).map(([category, items]) => (
          <React.Fragment key={category}>
            {/* Encabezado fijo y botón para colapsar/expandir */}
            <div 
              className={classes.fixedHeader}
              onClick={() => toggleCategory(category)}
            >
              <Typography variant="h6">{category}</Typography>
              <IconButton
                className={classes.toggleButton}
                onClick={() => toggleCategory(category)}
              >
                {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
            <Divider />
            {/* Lista de elementos */}
            <Collapse
              in={!!openCategories[category]}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {items.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      alignItems="flex-start"
                      className={classes.item}
                      key={index}
                    >
                      <ListItemIcon>
                        <FileCopy />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            {item.title}
                            <Typography
                              className={classes.inline}
                              color="textSecondary"
                              component="span"
                              style={{ marginLeft: '1rem' }}
                              variant="body2"
                            >
                            Subido: {item.uploadTime}
                            </Typography>
                          </React.Fragment>
                        }
                        style={{ marginLeft: '1rem' }}
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
    </List>
  );
};