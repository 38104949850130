import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { ExpandMore, ExpandLess, FileCopy } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import styles from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles,
  fixedHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor:'pointer'
  },
  thumbnail: {
    objectFit: 'cover'
  },
  item: {
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#DEDEDE'
    }
  },
  currentVideo: {
    backgroundColor: '#DEDEDE'
  },
  toggleButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const TotalList = (props) => {
  const { pdf, videos, currentVideo, handleVideo } = props;
  const classes = useStyles();
  const [openCategories, setOpenCategories] = useState({}); // Estado para manejar las categorías abiertas/cerradas

  const formatText = (text) => (text && text.length > 150 ? text.slice(0, 150) + '...' : text);

  // Combinar listas y agrupar por categoría
  const allList = [...videos, ...pdf];
  const groupedItems = allList.reduce((acc, item) => {
    const category = item.catergory || 'Sin Categoría'; // Usa una categoría por defecto si no hay
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});

  const toggleCategory = (category) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <List
      component="div"
      disablePadding
      style={{ maxHeight: '650px', overflow: 'auto' }}
    >
      {allList.length === 0 ? (
        <Typography 
          align="center"
          variant="h6"
        >
          No hay elementos para mostrar
        </Typography>
      ) : 
        Object.entries(groupedItems).map(([category, items]) => (
          <React.Fragment key={category}>
            {/* Encabezado fijo y botón para colapsar/expandir */}
            <div 
              className={classes.fixedHeader}
              onClick={() => toggleCategory(category)}
            >
              <Typography variant="h6">{category}</Typography>
              <IconButton
                className={classes.toggleButton}
              >
                {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
            <Divider />
            {/* Contenido colapsable */}
            <Collapse
              in={!!openCategories[category]}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {items.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      alignItems="flex-start"
                      className={clsx(classes.item, {
                        [classes.currentVideo]: currentVideo && currentVideo.id === item.id,
                      })}
                      onClick={item.thumbnailUrl ? () => handleVideo(item) : null}
                    >
                      {item.thumbnailUrl ? (
                        <ListItemAvatar>
                          <img
                            alt={item.title}
                            className={classes.thumbnail}
                            height={60}
                            src={item.thumbnailUrl}
                            width={100}
                          />
                        </ListItemAvatar>
                      ) : (
                        <ListItemIcon>
                          <FileCopy />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={
                          <React.Fragment>
                            {item.title}
                            <Typography
                              className={classes.inline}
                              color="textSecondary"
                              component="span"
                              style={{ marginLeft: '1rem' }}
                              variant="body2"
                            >
                              Subido: {item.uploadTime}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={item.thumbnailUrl && formatText(item.description)}
                        style={{ marginLeft: '1rem' }}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))
      }
    </List>
  );
};