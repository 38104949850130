import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

import { showMeters, deleteMeter } from 'js/actions/meterActions';
import { showGroups, deleteGroup } from 'js/actions/groupActions';
import { showCharge, deleteCharge } from 'js/actions/chargeActions';

import { getReportTotalesStarted } from 'js/actions/reportsActions';

import { useDispatch, useSelector } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

 
import Swal from 'sweetalert2';
import { ReportRounded } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { isAllowed } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  signUpButton: {
    backgroundColor:'#FEC12C',
    width:'250px',
    height:'45px',
    marginTop:'20px',
    [theme.breakpoints.down('md')]:{
      width: '100%'
    }
  }
}));



export default function InputAdornments() {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const user = useSelector(state => state.user.user);
  const corporatives = useSelector(state => state.corporatives.corporatives);
  const meters = useSelector(state => state.meters.meters);
  let groups = useSelector(state => state.groups.groups);
  let charges = useSelector(state => state.charges.charges);
  //let year = new Date().getFullYear()

  // const corpAdmin = useSelector(state => state.user.corporative);
  // const corpUser = user.corporative;
  // const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id;


  const [corporative, setCorporative] = useState();
  const [meter, setMeter] = useState();
  const [group, setGroup] = useState([]);
  const [charge, setCharge] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [type, setType] = useState();
  const [includePrice, setIncludePrice] = useState(false);
  const [blocking, setBlocking] = useState();


  const currYear = new Date().getFullYear();
  
  const years = [ currYear-3 , currYear-2 , currYear-1 , currYear ];




  function submitReport(e) {
    if(  !meter ||  !group  || !charge  || !year || !month  ){

      Swal.fire({
        title: '',
        text: 'Se requiere seleccionar todos los campos para generar el reporte',
        icon: 'warning'
      });

      e.preventDefault();
      return;
    }

    // let urlParam="https://api.metimur.mx/api/report/excel/totals/meter_id/"+meter+"/group_ids/"+ group +"/year/"+ year +"/month/"+ month;
    //getReportTotalesStarted(urlParam);
    
    
  }




  const getSelector = corp => {
    if (corp == 1) {
      return (
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel id="demo-simple-select-outlined-label">
            {intl.formatMessage({id: 'app.reports.totReports.corpLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="corporative"
            onChange={handleCorporative}
            value={corporative}
            //labelWidth={labelWidth}
          >
            {corporatives
              ? corporatives.map(el => (
                <MenuItem value={el.id}>{el.name}</MenuItem>
              ))
              : console.log('cargando tarifas')}
          </Select>
        </FormControl>
      );
    } else {
      return (
        // <FormControl className={classes.textField} variant="outlined">
        //   <InputLabel id="demo-simple-select-outlined-label">
        //     Corporativo
        //   </InputLabel>
        //   <Select
        //     id="demo-simple-select-outlined"
        //     labelId="demo-simple-select-outlined-label"
        //     name="corporative"
        //     inputRef={register}
        //     onChange={handleCorporative}
        //     value={corporativeId}
        //     //labelWidth={labelWidth}
        //   >
        //     <MenuItem value={corporativeId}>{user.corporative.name}</MenuItem>
        //   </Select>
        // </FormControl>
        null
      );
    }
  };

  const onSubmit = values => {    
    if(  !meter ||  !group  || !charge  || !year || !month  ){

      Swal.fire({
        title: '',
        text: 'Se requiere seleccionar todos los campos para generar el reporte',
        icon: 'warning'
      });        
      return;
    }

    let flagPrice=  includePrice ? '1' : '0' 
    let urlParam='https://api.metimur.mx/api/report/excel/totals/meter_id/'+meter+'/group_ids/'+ group +'/year/'+ year +'/month/'+ month+ '/'+ flagPrice ;
    setBlocking(true);
    dispatch(getReportTotalesStarted( endBlocking ,  urlParam));

 
  };


  function endBlocking( ){
    setBlocking(false);
  }

  const getMeters = corporativeId => {
    dispatch(showMeters(corporativeId));
  };

  const getGroups = meterId => {
    dispatch(showGroups(meterId));
  };

  const getCharges = groupId => {
    dispatch(showCharge(groupId));
  };

  const handleCorporative = corporative => {
    setCorporative(corporative.target.value);
    setCharge( [] )
    setGroup([] )
    getMeters(corporative.target.value);
  };

  const handleMeter = meter => {
    console.log('HandleMeter ....');
    setMeter(meter.target.value);
    setGroup([])
    getGroups(meter.target.value);
   

    setCharge( [] );
  };

  const handleGroup = group => {
    console.log('HandleGroup ....');    
    setGroup(group.target.value);
    //getCharges(group.target.value);
  };

  const handleCharge = charge => {
    setCharge(charge.target.value);
    //getCity(state.target.value);
  };

  const handleYear = year => {
    setYear(year.target.value);
    //getCity(state.target.value);
  };

  const handleMonth = month => {
    setMonth(month.target.value);
    //getCity(state.target.value);
  };

  const handleType = type => {
    setType(type.target.value);
    //getCity(state.target.value);
  };

 
  const handleChangeIncludePrice = flag => {
    setIncludePrice(flag.target.checked);    
  };


  return (
    <div>
      <BlockUi
        blocking={blocking}
        tag="div"
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              md={6}
              style={{display: user.adser ? 'block' : 'none'}}
              xs={6}
            >
              {getSelector(user.adser)}
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.totReports.meterLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="meter"
                  onChange={handleMeter}
                  value={meter}
                //labelWidth={labelWidth}
                >
                  {meters
                    ? meters.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : console.log('cargando tarifas')}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.totReports.groupLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  multiple
                  name="group"
                  onChange={handleGroup}
                  value={group}
                //labelWidth={labelWidth}
                >
                  {isAllowed(user.permissions, ['download_report_structure']) && (
                    <MenuItem value="TODAS">TODAS</MenuItem>
                  )}
                  {groups
                    ? groups.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : console.log('cargando tarifas')}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item lg={3} md={3} xs={3}>
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Medicion
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                name="charge"
                multiple
                inputRef={register}
                onChange={handleCharge}
                value={charge}
                //labelWidth={labelWidth}
              >
                <MenuItem value="TODAS">TODAS</MenuItem>
                {charges
                  ? charges.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                  : console.log('cargando tarifas')}
              </Select>
            </FormControl>
          </Grid> */}
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.totReports.yearLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="year"
                  onChange={handleYear}
                  value={year}
                //labelWidth={labelWidth}
                >
                
                  {                 
                    years.map(yr => (
                      <MenuItem value={yr}>{yr}</MenuItem>
                    ))                                    
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={user.adser ? 12 : 6}
              xs={user.adser ? 12 : 6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.totReports.monthLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="month"
                  onChange={handleMonth}
                  value={month}
                //labelWidth={labelWidth}
                >
                  <MenuItem value="1">{intl.formatMessage({id: 'app.months.jan'})}</MenuItem>
                  <MenuItem value="2">{intl.formatMessage({id: 'app.months.feb'})}</MenuItem>
                  <MenuItem value="3">{intl.formatMessage({id: 'app.months.mar'})}</MenuItem>
                  <MenuItem value="4">{intl.formatMessage({id: 'app.months.apr'})}</MenuItem>
                  <MenuItem value="5">{intl.formatMessage({id: 'app.months.may'})}</MenuItem>
                  <MenuItem value="6">{intl.formatMessage({id: 'app.months.jun'})}</MenuItem>
                  <MenuItem value="7">{intl.formatMessage({id: 'app.months.jul'})}</MenuItem>
                  <MenuItem value="8">{intl.formatMessage({id: 'app.months.aug'})}</MenuItem>
                  <MenuItem value="9">{intl.formatMessage({id: 'app.months.sep'})}</MenuItem>
                  <MenuItem value="10">{intl.formatMessage({id: 'app.months.oct'})}</MenuItem>
                  <MenuItem value="11">{intl.formatMessage({id: 'app.months.nov'})}</MenuItem>
                  <MenuItem value="12">{intl.formatMessage({id: 'app.months.dec'})}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
 
            <Grid
              item
              lg={3}
              md={12}
              xs={12}
            >
              <FormControlLabel
                className={classes.textField}
                control={
                  <Checkbox        
                                               
                    checked={includePrice}
                    inputRef={register}
                    name="include_price"
                    onChange={handleChangeIncludePrice}
                  />
                }
                label={intl.formatMessage({id: 'app.reports.totReports.includePrice'})}
              />
            </Grid>  


            {/* <Grid
            item
            lg={3}
            md={3}
            xs={3}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Tipo de Reporte
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                name='type'
                inputRef={register}
                onChange={handleType}
                value={type}
                //labelWidth={labelWidth}
              >
                <MenuItem value='CONSUME'>Consumo</MenuItem>
                <MenuItem value='DEMAND'>Demanda</MenuItem>
              
                  
              </Select>
            </FormControl>
          </Grid> */}

        
      
            <Button
              className={classes.signUpButton}
              color="secondary"
              //onClick={this.handleSubmit}
              size="large"
              type="submit"
              variant="contained"
            >
              {intl.formatMessage({id: 'app.reports.totReports.downloadBtn'})}
            </Button>
        
          
          </Grid>
        </form>
      </BlockUi>
    </div>
  );
}
