export default theme => ({
  root: {},
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    '& > *':{
      color: 'white !important',
      border: 'none !important'
    }
  },
  chartWrapper: {
    height: '400px',
    position: 'relative'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
