/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material components
import {
  Box,
  Button,
  FormControl,
  //Checkbox,
  // CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { showCountrySates, showCities, showCPS, showCounties} from 'js/actions/zepomexActions'
import {  updateDataMeter} from 'js/actions/meterActions'
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';

const useStyles = makeStyles(styles);

export default function MeterForm(props) {
  const intl = useIntl()
  const { meter } = props.location.state;
  const { getValues, setValue, register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { corpId } = props.match.params;
  const [country, setCountry] = useState(meter.country);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const defaultLat = 19.432608;
  const defaultLng = -99.133209;
  const [lat, setLat] = useState( meter.coordinates && 
    meter.coordinates.lat && 
    meter.coordinates.lat.length > 0 ? 
    meter.coordinates.lat[0] : defaultLat
  );
  const [lng, setLng] = useState(meter.coordinates && 
    meter.coordinates.long && 
    meter.coordinates.long.length > 0 ? 
    meter.coordinates.long[0] : defaultLng
  );
  const [address, setAddress] = useState(meter.address);
  const [fullAddress, setFullAddress] = useState(`${meter.address}, ${meter.county}, ${meter.cp}, ${meter.city}, ${meter.state}, ${meter.country}`);
  const GOOGLE_MAPS_API_KEY = 'AIzaSyBsCZxqh6pCOq1A1TCZTzXHyBzYgGYEUpo';

  const [state, setState] = useState(meter.state);
  const [city, setCity] = useState(meter.city);
  const [cp, setCP] = useState(meter.cp);
  const [county, setCounty] = useState(meter.county);

  const estados = useSelector(state => state.zepomex.states.estado);
  const ciudades = useSelector(state => state.zepomex.cities.municipios);
  const cps = useSelector(state => state.zepomex.cp.cp);
  const colonias = useSelector(state => state.zepomex.counties);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.initMap = () => {
      if (lat && lng) {
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat, lng },
          zoom: 15,
          streetViewControl: false,
          fullscreenControl: false,
        });
        setMap(map);

        const marker = new window.google.maps.Marker({
          position: { lat, lng },
          map: map,
          draggable: true,
          visible: true,
        });
        setMarker(marker);

        marker.addListener('dragend', async () => {
          const newPosition = marker.getPosition();
          setLat(newPosition.lat());
          setLng(newPosition.lng());
          updateAddressFromCoordinates(newPosition);
        });

        map.addListener('click', (event) => {
          const clickedLocation = event.latLng;
          setLat(clickedLocation.lat());
          setLng(clickedLocation.lng());
          marker.setPosition(clickedLocation);
          marker.setVisible(true);
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: clickedLocation }, (results, status) => {
            if (status === 'OK' && results[0]) {
              updateAddressDetails(results[0]);
            }
          });
        });
      } else {
        getCoordinatesFromAddress(address).then((coordinates) => {
          if (coordinates) {
            setLat(coordinates.lat);
            setLng(coordinates.lng);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No se pudo obtener las coordenadas de la dirección.',
            });
          }
        });
      }
    };
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [lat, lng]);

  const updateAddressFromCoordinates = async (coordinates) => {
    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({ location: coordinates });
    if (response.results[0]) {
      updateAddressDetails(response.results[0]);
    }
  };

  const updateAddressDetails = (place) => {
    const addressComponents = place.address_components;
    const addressDetails = {
      streetNumber: '',
      route: '',
      locality: '',
      intersection: '',
      administrativeAreaLevel1: '',
      country: '',
      postalCode: '',
      neighborhood: '',
      sublocality: ''
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) addressDetails.streetNumber = component.long_name;
      if (types.includes('route')) addressDetails.route = component.long_name;
      if (types.includes('intersection')) addressDetails.intersection = component.long_name;
      if (types.includes('locality')) addressDetails.locality = component.long_name;
      if (types.includes('administrative_area_level_1')) addressDetails.administrativeAreaLevel1 = component.long_name;
      if (types.includes('country')) addressDetails.country = component.long_name;
      if (types.includes('postal_code')) addressDetails.postalCode = component.long_name;
      if (types.includes('neighborhood')) addressDetails.neighborhood = component.long_name;
      if (types.includes('sublocality')) addressDetails.sublocality = component.long_name;
    });

    setValue('state', addressDetails.administrativeAreaLevel1);
    setValue('city', addressDetails.locality);
    setValue('cp', addressDetails.postalCode);
    setValue('county', addressDetails.neighborhood || addressDetails.sublocality);

    setFullAddress(address + ', ' + county + ', ' + cp + ', ' + city + ', ' + state + ', ' + country)
  };

  const searchAddress = () => {
    // Construye la dirección completa usando los valores disponibles
    const fullAddress = `${address}, ${county}, ${cp}, ${city}, ${state}, ${country}`;
    setFullAddress(fullAddress)
    // Inicializa el geocoder
    const geocoder = new google.maps.Geocoder();
  
    // Usa el fullAddress para hacer la geocodificación
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const { location } = results[0].geometry;
  
        // Actualiza las coordenadas (lat, lng)
        setLat(location.lat());
        setLng(location.lng());
  
        // Actualiza el marcador y el mapa
        if (marker) {
          marker.setPosition(location);
          marker.setVisible(true);
          map.setCenter(location);
          map.setZoom(15);
        }
  
        // Llama a updateAddressDetails para actualizar los detalles de la dirección
        updateAddressDetails(results[0]);
      } else {
        // Muestra una alerta si no se encuentra la dirección
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo encontrar la dirección',
        });
      }
    });
  };

  const onSubmit = values => {
    dispatch(updateDataMeter(values,meter,corpId,city, country, county, state, cp, lat, lng));
  };

  const getCoordinatesFromAddress = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({ address });
    if (response.results[0]) {
      return {
        lat: response.results[0].geometry.location.lat(),
        lng: response.results[0].geometry.location.lng(),
      };
    } else {
      return null;
    }
  };

  useEffect(()=>{
    dispatch(showCountrySates())
  }, [country]);
  
  useEffect(()=>{
    dispatch(showCities(state))
  }, [state])

  useEffect(()=>{
    dispatch(showCPS(city))
  }, [city])

  useEffect(()=>{
    dispatch(showCounties(cp))
  }, [cp])
  
  return (
    <DashboardLayout title={meter.name}>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.metersCards.editMeter.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.name}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.mterNameLbl'})}
                      name="name"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.description}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.descriptLbl'})}
                      name="description"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.country}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.countryLbl'})}
                      name="country"
                      onChange={(e) => setCountry(e.target.value)}
                      required
                      variant="outlined"
                    />
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.stateLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="state"
                        onChange={(e)=>setState(e.target.value)}
                        value={state || meter.state}
                      >
                      
                        { estados ?  estados.map(el => (
                          <MenuItem value={el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}>{el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}</MenuItem>
                        ))  : <MenuItem /> 
                        } 
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.cityLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        name="city"
                        onChange={(e)=>setCity(e.target.value)}
                        value={city || meter.city}
                      >
                       
                        { ciudades ?  ciudades.map(el => (
                          <MenuItem value={el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}>{el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}</MenuItem>
                        ))  : <MenuItem /> 
                        }
                            
                      </Select>
                    </FormControl>          
                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.payment_deadline}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.payDeadLineLbl'})}
                      name="payment_deadline"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    /> 
                  
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.contracted_demand}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.contractedDemandLbl'})}                      
                      //onChange={this.handleChange}
                      name="contracted_demand"
                      variant="outlined"
                    />  

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.postalCodeLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="cp"
                        onChange={(e)=>setCP(e.target.value)}
                        value={cp || meter.cp}
                      >
                        { cps ?  cps.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : <MenuItem /> 
                        }
                            
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.neighborhoodLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="county"
                        onChange={(e)=>setCounty(e.target.value)}
                        value={county || meter.county}
                      >
                        {colonias && colonias.colonia && Array.isArray(colonias.colonia) && colonias.colonia.length > 0 ? (
                          colonias.colonia.map((el, index) => (
                            <MenuItem
                              key={index}
                              value={el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}
                            >
                              {el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                          >No hay colonias disponibles</MenuItem>
                        )}
                            
                      </Select>
                    </FormControl>

                    <TextField
                      className={classes.textField}
                      defaultValue={meter.address}
                      innerRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.addressLbl'})}
                      name="address"
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </div>

                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div>
                    {fullAddress && (
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <TextField
                          className={classes.textField}
                          inputRef={register}
                          label="Direccion completa"
                          name="address"
                          required
                          style={{width: '55%', marginTop: '0px'}}
                          value={fullAddress}
                          variant="outlined"
                        />
                        <TextField 
                          className={classes.textField}
                          inputRef={register}
                          label="Latitud"
                          name="lat"
                          required
                          style={{width: '20%', marginTop: '0px'}}
                          value={lat}
                          variant="outlined"
                        />
                        <TextField
                          className={classes.textField}
                          inputRef={register}
                          label="Longitud"
                          name="lng"
                          required
                          style={{width: '20%', marginTop: '0px'}}
                          value={lng}
                          variant="outlined"
                        />
                      </Box>
                    )}
                    <Button
                      className={classes.signUpButton}
                      color="secondary"
                      onClick={searchAddress}
                      size="large"
                      variant="contained"
                    >
                      Buscar Dirección
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div
                    id="map"
                    style={{ height: '500px' }}
                  />
                </Grid>
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metersCards.editMeter.submit'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}