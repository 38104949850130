/* eslint-disable react/no-multi-comp */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import {
  Add,
  Folder,
  InsertDriveFile,
  Edit,
  Delete,
  Home
} from '@material-ui/icons';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import {
  postFolder,
  getFolders,
  editFolder,
  deleteFolder,
  getIntoFolder,
  postFile,
  deleteFile
} from 'js/actions/meterActions';

const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      className={classes.root}
      disableTypography
      {...other}
    >
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    gap: '1rem',
    flexWrap: 'wrap',
    height: '500px'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    borderTop: '1px solid rgb(128 128 128 / 50%)'
  }
}))(MuiDialogActions);

const UploadDocuments = props => {
  const { open, selectedCorp, setOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showSelect, setShowSelect] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [openFolder, setOpenFolder] = useState(false);
  const [openEditFolder, setOpenEditFolder] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [fileType, setFileType] = useState(null);
  const [fileName, setFileName] = useState();
  const [fileDescription, setFileDescription] = useState();
  const [folderName, setFolderName] = useState();
  const [newFolderName, setNewFolderName] = useState();
  const [fileLink, setFileLink] = useState()
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [folderDescription, setFolderDescription] = useState();
  const [newFolderDescription, setNewFolderDescription] = useState();
  const [breadcrumbFolders, setBreadcrumbFolders] = useState([]);
  const folderData = useSelector(state => state.meters.folder);
  const isLoading = useSelector(state => state.meters.loading);
  const folderInfo = useSelector(state => state.meters.folderInfo);
  const [loadFolder, setLoadFolder] = useState(false);

  useEffect(() => {
    if (selectedCorp !== null) {
      dispatch(getFolders(selectedCorp));
    }
  }, [selectedCorp]);

  useEffect(()=>{
    if(currentFolder && loadFolder){
      dispatch(getIntoFolder(currentFolder));
      setLoadFolder(false)
    }
  },[currentFolder, loadFolder])

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleNewFolder = () => {
    setOpenFolder(true);
  };

  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    if (selectedValue === 'upload') {
      handleButtonClick();
    } else if (selectedValue === 'createFolder') {
      handleNewFolder();
    }
    setShowSelect(false);
  };

  const handleFolderClick = folder => {
    setCurrentFolder(folder); // Al hacer clic en una carpeta, cambia el estado de ubicación
    setBreadcrumbFolders(prev => [...prev, folder]);
    dispatch(getIntoFolder(folder));
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png',
      'image/gif'
    ];

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        alert(
          'El archivo debe ser de tipo: pdf, doc, docx, jpg, jpeg, png, gif'
        );
        return;
      }

      const maxSize = 5 * 1024 * 1024; // 5MB en bytes
      if (selectedFile.size > maxSize) {
        alert('El archivo no debe superar los 5MB');
        return;
      }

      setFile(URL.createObjectURL(selectedFile)); // Para obtener la URL local temporal del archivo
      setFileType(selectedFile.type);
      setFileName(selectedFile.name); // Usar el nombre real del archivo
      setOpenFile(true);
    }
  };

  const postNewFolder = () => {
    setOpenFolder(false);
    dispatch(postFolder(selectedCorp, folderName, folderDescription, currentFolder, status))
      .then(() => {
        dispatch(getFolders(selectedCorp, currentFolder));
      })
      .then(() => {
        setFolderName('');
        setFolderDescription('');
      });
  };

  const postNewFile = () => {
    if (!selectedFile) {
      alert('No hay archivo seleccionado');
      return;
    }
    setOpenFile(false);
    dispatch(
      postFile(selectedFile, currentFolder.id, fileName, fileDescription, status)
    ).then(() => {
      dispatch(getIntoFolder(currentFolder));
      setFile(null);
      setFileName('');
      setFileDescription('');
      setSelectedFile(null);
    });
  };

  const putFolder = folder => {
    dispatch(
      editFolder(
        folder.id,
        newFolderName === undefined ? folder.name : newFolderName,
        newFolderDescription === undefined
          ? folder.description
          : newFolderDescription,
        selectedCorp,
        status
      )
    ).then(() => {
      setOpenEditFolder(false);
      setFolderToEdit(null);
    });
  };

  const deleteFolders = folder => {
    dispatch(deleteFolder(folder, selectedCorp, status));
  };

  // Función para acortar el nombre
  const titleFormat = (title, maxLength) => {
    return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
  };

  const editFolderName = folder => {
    setFolderToEdit(folder);
    setOpenEditFolder(true);
  };

  const handleCloseEditFolder = () => {
    setOpenEditFolder(false);
    setFolderToEdit(null);
  };

  const handleDeleteFile = file => {
    dispatch(deleteFile(file, status));
  };

  const status = (data) => {
    setLoadFolder(data)
  }
  
  // Componente para listar documentos
  const DocumentList = ({ classes }) => {
    const currentData = currentFolder ? folderInfo : folderData;

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1rem',
          width: '100%',
          marginTop: '2.5rem'
        }}
      >
        <div className={classes.selectContainer}>
          <Button
            className={classes.buttonUpload}
            onClick={() => setShowSelect(!showSelect)}
            type="button"
          >
            <Add style={{ color: '1px solid rgb(128 128 128 / 50%)' }} />
            <FormControl
              style={{ opacity: '0' }}
              variant="outlined"
            >
              <Select
                id="buttonUpload"
                labelId="buttonUpload"
                onChange={handleSelectChange}
                onClose={() => setShowSelect(false)}
                open={showSelect}
                value=""
              >
                {breadcrumbFolders.length > 0 && (
                  <MenuItem value="upload">Subir Archivo</MenuItem>
                )}
                <MenuItem value="createFolder">Crear Carpeta</MenuItem>
              </Select>
            </FormControl>
          </Button>
        </div>
        {/* Listar las carpetas */}
        {currentData ? (
          currentData.folders.map((folder, index) => (
            <div
              className={classes.documentItem}
              key={index}
            >
              <Tooltip
                arrow
                title={folder.name}
              >
                <div style={{ position: 'relative' }}>
                  <Folder
                    className={classes.folder}
                    onClick={() => handleFolderClick(folder)}
                  />
                  <Typography style={{ textAlign: 'center' }}>
                    {titleFormat(folder.name, 8)}
                  </Typography>
                  <div className={classes.actions}>
                    <Tooltip
                      arrow
                      title="Editar"
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => editFolderName(folder)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title="Eliminar"
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteFolders(folder.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Tooltip>
            </div>
          ))
        ) : (
          <Typography style={{ textAlign: 'center', width: '100%' }}>
            No hay carpetas en este directorio.
          </Typography>
        )}

        {/* Listar los archivos */}
        {currentData && currentData.files.length > 0
          ? currentData.files.map((file, index) => (
            <div
              className={classes.documentItem}
              key={index}
            >
              <Tooltip
                arrow
                title={file.name}
              >
                <div style={{ position: 'relative' }}>
                  {file.temporary_url && !file.path.endsWith('pdf') ? (
                    <div
                      className={classes.file}
                      onClick={() => {
                        setFileLink(file.temporary_url)
                        setOpenFilePreview(true)
                      }}
                      style={{backgroundImage: `url(${file.temporary_url})`}}

                    />
                  ) : (
                    <InsertDriveFile
                      className={classes.folder}
                      onClick={()=> {window.open(file.temporary_url, '_blank')}}
                    />
                  )}
                  <Typography style={{ textAlign: 'center' }}>
                    {titleFormat(file.name, 8)}
                  </Typography>
                  <div className={classes.actions}>
                    {/* <Tooltip
                      arrow
                      title="Editar"
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => editFolderName(folder)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      arrow
                      title="Eliminar"
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteFile(file.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Tooltip>
            </div>
          ))
          : ''}
        {isLoading && <CircularProgress />}
      </div>
    );
  };

  const newFolderDialog = (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={() => setOpenFolder(false)}
      open={openFolder}
    >
      <DialogTitle id="form-dialog-title">Crear nueva carpeta</DialogTitle>
      <DialogContent style={{ height: 'auto' }}>
        <TextField
          autoFocus
          fullWidth
          id="name"
          label="Nombre de Carpeta"
          onChange={e => setFolderName(e.target.value)}
          type="name"
          value={folderName}
          variant="outlined"
        />
        <TextField
          autoFocus
          fullWidth
          id="description"
          label="Descripción de Carpeta"
          onChange={e => setFolderDescription(e.target.value)}
          type="name"
          value={folderDescription}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => setOpenFolder(false)}
        >
          Cancelar
        </Button>
        <Button
          color="secondary"
          disabled={!folderName || !folderDescription}
          onClick={() => postNewFolder()}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );

  const newFileDialog = (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={() => setOpenFile(false)}
      open={openFile}
    >
      <DialogTitle id="form-dialog-title">Subir Archivo</DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        {file && fileType.startsWith('image/') && (
          <img
            alt="preview"
            src={file}
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
        )}
        {file && fileType === 'application/pdf' && (
          <Document file={file}>
            <Page
              pageNumber={1}
              width={200}
            />
          </Document>
        )}
        <TextField
          autoFocus
          fullWidth
          id="name"
          label="Nombre de Archivo"
          onChange={e => setFileName(e.target.value)}
          type="name"
          variant="outlined"
        />
        <TextField
          autoFocus
          fullWidth
          id="description"
          label="Descripción de Archivo"
          onChange={e => setFileDescription(e.target.value)}
          type="name"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            setOpenFile(false);
            setFileName('');
            setFileDescription('');
          }}
        >
          Cancelar
        </Button>
        <Button
          color="secondary"
          disabled={!fileName || !fileDescription}
          onClick={() => postNewFile()}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const filePreview = (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={() => setOpenFilePreview(false)}
      open={openFilePreview}
    >
      <DialogContent style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div
          style={{backgroundImage: `url(${fileLink})`, width: '500px', height: '450px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {setOpenFilePreview(false); setFileLink('')}}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={open}
      style={{ zIndex: '999' }}
      width="500px"
    >
      <DialogTitle
        id="max-width-dialog-title"
        onClose={handleClose}
      >
        {selectedCorp ? selectedCorp.name : ''}
      </DialogTitle>
      <DialogContent>
        <input
          accept="image/*,.pdf"
          id="fileInput"
          name="fileInput"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          type="file"
        />
        {newFolderDialog}
        {newFileDialog}
        {filePreview}
        <Breadcrumbs style={{ position: 'absolute' }}>
          <Typography
            onClick={() => {
              setCurrentFolder(null);
              setBreadcrumbFolders([]); // Resetea al folder raíz
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              cursor: 'pointer'
            }}
          >
            <Home /> Inicio
          </Typography>
          {breadcrumbFolders &&
            breadcrumbFolders.length > 0 &&
            breadcrumbFolders.map((folder, index) => (
              <Typography
                key={index}
                onClick={() => {
                  setCurrentFolder(folder); // Navega a una carpeta anterior
                  setBreadcrumbFolders(breadcrumbFolders.slice(0, index + 1)); // Recorta el historial
                  dispatch(getIntoFolder(folder));
                }}
              >
                {folder.name}
              </Typography>
            ))}
        </Breadcrumbs>
        <DocumentList classes={classes} />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
      {openEditFolder && folderToEdit && (
        <Dialog
          aria-labelledby="form-dialog-title"
          onClose={handleCloseEditFolder}
          open={openEditFolder}
        >
          <DialogTitle id="form-dialog-title">
            Editar {folderToEdit.name}
          </DialogTitle>
          <DialogContent style={{ height: 'auto' }}>
            <TextField
              autoFocus
              fullWidth
              label="Nombre de Carpeta"
              onChange={e => setNewFolderName(e.target.value)}
              value={newFolderName || folderToEdit.name} // Usa el nombre de la carpeta seleccionada
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Descripción de Carpeta"
              onChange={e => setNewFolderDescription(e.target.value)}
              value={newFolderDescription || folderToEdit.description} // Usa la descripción de la carpeta seleccionada
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={handleCloseEditFolder}
            >
              Cancelar
            </Button>
            <Button
              color="secondary"
              onClick={() => putFolder(folderToEdit)}
            >
              Editar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default UploadDocuments;