/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/styles';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Material icons
import {
  Timeline as AlertIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles)


export default function Users(props) {
  const classes = useStyles();
  const {className, corpId, meters,...rest} = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <Paper
      {...rest}
      className={rootClassName}
    >
      <Link
        to={{
          pathname:`/corporative/${corpId}`,
          state:{corpId}
        }}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              Nodos
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {meters.length > 0 ? meters.length : (<CircularProgress style={{color: 'white'}} />)}
            </ Typography>
          </div>
          <div className={classes.iconWrapper}>
            <AlertIcon className={classes.icon} />
          </div>
        </div>
      </Link>
    </Paper>
  )
}


