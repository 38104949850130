/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material components
import {
  Box,
  Button,
  FormControl,
  //Checkbox,
  // CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { showCountrySates, showCities, showCPS, showCounties} from 'js/actions/zepomexActions'
import {  postDataMeter} from 'js/actions/meterActions'
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { error } from 'highcharts';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  state: null,
  city: null,
  cp: null,
  county: null,
  country: null,
};


export default function MeterForm(props) {
  const intl = useIntl()
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { corpId } = props.match.params;
  const [address, setAddress] = useState();
  const [fullAddress, setFullAddress] = useState('');
  const [country, setCountry] = useState();
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [cp, setCP] = useState();
  const [county, setCounty] = useState();

  const estados = useSelector(state => state.zepomex.states.estado);
  const ciudades = useSelector(state => state.zepomex.cities.municipios);
  const cps = useSelector(state => state.zepomex.cp.cp);
  const colonias = useSelector(state => state.zepomex.counties.colonia);

  const GOOGLE_MAPS_API_KEY = 'AIzaSyBsCZxqh6pCOq1A1TCZTzXHyBzYgGYEUpo'  

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.initMap = () => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 23.6345, lng: -102.5528 },
        zoom: 5,
        streetViewControl: false,
        fullscreenControl: false,
      });
      setMap(map);

      const marker = new window.google.maps.Marker({
        map: map,
        draggable: true,
        visible: false,
      });
      setMarker(marker);

      marker.addListener('dragend', async () => {
        const newPosition = marker.getPosition();
        setLat(newPosition.lat());
        setLng(newPosition.lng());
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({ location: newPosition });
        if (response.results[0]) {
          updateAddressDetails(response.results[0]);
        }
      });

      map.addListener('click', (event) => {
        const clickedLocation = event.latLng;
        setLat(clickedLocation.lat());
        setLng(clickedLocation.lng());
        marker.setPosition(clickedLocation);
        marker.setVisible(true);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: clickedLocation }, (results, status) => {
          if (status === 'OK' && results[0]) {
            updateAddressDetails(results[0]);
          }
        });
      });
    };
  }, []);

  const updateAddressDetails = (place) => {
    const addressComponents = place.address_components;
    const addressDetails = {
      streetNumber: '',
      route: '',
      locality: '',
      administrativeAreaLevel1: '',
      country: '',
      postalCode: '',
      neighborhood: '',
      sublocality: ''
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) addressDetails.streetNumber = component.long_name;
      if (types.includes('route')) addressDetails.route = component.long_name;
      if (types.includes('locality')) addressDetails.locality = component.long_name;
      if (types.includes('administrative_area_level_1')) addressDetails.administrativeAreaLevel1 = component.long_name;
      if (types.includes('country')) addressDetails.country = component.long_name;
      if (types.includes('postal_code')) addressDetails.postalCode = component.long_name;
      if (types.includes('neighborhood')) addressDetails.neighborhood = component.long_name;
      if (types.includes('sublocality')) addressDetails.sublocality = component.long_name;
    });

    setValue('state', addressDetails.administrativeAreaLevel1);
    setValue('city', addressDetails.locality);
    setValue('cp', addressDetails.postalCode);
    setValue('county', addressDetails.neighborhood || addressDetails.sublocality);

    // Update state variables
    setFullAddress(address + ', ' + county + ', ' + cp + ', ' + city + ', ' + state + ', ' + country)
  };

  const searchAddress = () => {
    // Construye la dirección completa usando los valores disponibles
    const fullAddress = `${address || ''}, ${county || ''}, ${cp || ''}, ${city || ''}, ${state || ''}, ${country || ''}`;
    setFullAddress(fullAddress)
    // Inicializa el geocoder
    const geocoder = new google.maps.Geocoder();
  
    // Usa el fullAddress para hacer la geocodificación
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const { location } = results[0].geometry;
  
        // Actualiza las coordenadas (lat, lng)
        setLat(location.lat());
        setLng(location.lng());
  
        // Actualiza el marcador y el mapa
        if (marker) {
          marker.setPosition(location);
          marker.setVisible(true);
          map.setCenter(location);
          map.setZoom(15);
        }
  
        // Llama a updateAddressDetails para actualizar los detalles de la dirección
        updateAddressDetails(results[0]);
      } else {
        // Muestra una alerta si no se encuentra la dirección
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo encontrar la dirección',
        });
      }
    });
  };

  const onSubmit = async (values) => {
    try {
      dispatch(postDataMeter(values,corpId,city, country, county, state, cp, lat, lng));
    } catch (error) {
      console.error('Error submitting data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error obtaining location. Please check the address.',
      });
    }
  };
  

  useOnMount(() => {
    register({ name: 'state', type: 'text' });
    register({ name: 'city', type: 'text' });
    register({ name: 'cp', type: 'text' });
    register({ name: 'county', type: 'text' });
  });

  const values = getValues();

  useEffect(()=>{
    dispatch(showCountrySates())
  }, [country]);
  
  useEffect(()=>{
    dispatch(showCities(state))
  }, [state])

  useEffect(()=>{
    dispatch(showCPS(city))
  }, [city])

  useEffect(()=>{
    dispatch(showCounties(cp))
  }, [cp])

  return (
    <DashboardLayout title="Creacion de Medidores">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.metersCards.addMeter.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.mterNameLbl'})}
                      name="name"
                      required
                      //onChange={this.handleChange}
                      // value={name}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.descriptLbl'})}
                      name="description"
                      required
                      //onChange={this.handleChange}
                      //value={description}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.countryLbl'})}
                      name="country"
                      onChange={(e) => setCountry(e.target.value)}
                      required
                      variant="outlined"
                    />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.stateLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={(e)=>setState(e.target.value)}
                        value={state}
                      >
                        { estados ?  estados.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : <MenuItem /> 
                        }   
                      </Select>
                    </FormControl>

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.cityLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={(e)=>setCity(e.target.value)}
                        value={city}
                      >
                        { ciudades ?  ciudades.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : <MenuItem />
                        }   
                      </Select>
                    </FormControl>

                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.postalCodeLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={(e)=>setCP(e.target.value)}
                        value={cp}
                      >
                        { cps ?  cps.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : <MenuItem /> 
                        }
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.neighborhoodLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={(e)=>setCounty(e.target.value)}
                        value={county}
                      >
                        { colonias ?  colonias.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : <MenuItem /> 
                        }   
                      </Select>
                    </FormControl>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.addressLbl'})}
                      name="address"
                      onChange={(e)=>setAddress(e.target.value)}
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.payDeadLineLbl'})}    
                      name="payment_deadline"
                      required
                      //onChange={this.handleChange}
                      //value={address}
                      variant="outlined"
                    />      

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.contractedDemandLbl'})}    
                      name="contracted_demand"
                      required
                      //onChange={this.handleChange}
                      //value={address}
                      variant="outlined"
                    />                                    
                      
                  </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div>
                    {fullAddress && (
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <TextField
                          className={classes.textField}
                          inputRef={register}
                          label="Direccion completa"
                          name="address"
                          required
                          style={{width: '55%', marginTop: '0px'}}
                          value={fullAddress}
                          variant="outlined"
                        />
                        <TextField 
                          className={classes.textField}
                          inputRef={register}
                          label="Latitud"
                          name="lat"
                          required
                          style={{width: '20%', marginTop: '0px'}}
                          value={lat}
                          variant="outlined"
                        />
                        <TextField
                          className={classes.textField}
                          inputRef={register}
                          label="Longitud"
                          name="lng"
                          required
                          style={{width: '20%', marginTop: '0px'}}
                          value={lng}
                          variant="outlined"
                        />
                      </Box>
                    )}
                    <Button
                      className={classes.signUpButton}
                      color="secondary"
                      onClick={searchAddress}
                      size="large"
                      variant="contained"
                    >
                      Buscar Dirección
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div
                    id="map"
                    style={{ height: '500px' }}
                  />
                </Grid>
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metersCards.addMeter.submit'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}