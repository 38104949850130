export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    backgroundColor: '#A53693'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    color: '#fff',
    fontWeight: 700,
    fontSize:'1em',
  },
  value: {
    marginTop: theme.spacing.unit,
    color: '#fff'
  },
  linkNoDecoration: {
    '&:hover': {
      textDecoration: 'none' 
    }
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: '#A53693',
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center'
  },
  difference: {
    alignItems: 'center',
    color: theme.palette.danger.dark,
    display: 'inline-flex',
    fontWeight: 700
  },
  caption: {
    color: '#fff'
  }
});