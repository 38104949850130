import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  ...styles,
  fixedHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  thumbnail: {
    objectFit: 'cover'
  },
  item: {
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#DEDEDE'
    }
  },
  currentVideo: {
    backgroundColor: '#DEDEDE'
  },
  toggleButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const VideoList = (props) => {
  const { videos, currentVideo, handleVideo } = props;
  const classes = useStyles();
  const [openCategories, setOpenCategories] = useState({}); 
  
  const formatText = (text) => (text && text.length > 150 ? text.slice(0, 150) + '...' :  text)

  const groupedVideos = videos.reduce((acc, item) => {
    const category = item.catergory || 'Sin Categoría'; // Usa una categoría por defecto si no hay
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});

  const toggleCategory = (category) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <List
      component="div"
      disablePadding
      style={{ maxHeight: '650px', overflow: 'auto' }}
    >
      {/* Mapear cada categoría y sus elementos */}
      {videos && videos.length === 0 ? (
        <Typography
          align="center"
          variant="h6"
        >No hay videos disponibles</Typography>
      ):
        Object.entries(groupedVideos).map(([category, items]) => (
          <React.Fragment key={category}>
            {/* Encabezado fijo y botón para colapsar/expandir */}
            <div 
              className={classes.fixedHeader}
              onClick={() => toggleCategory(category)}
            >
              <Typography variant="h6">{category}</Typography>
              <IconButton
                className={classes.toggleButton}
                onClick={() => toggleCategory(category)}
              >
                {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
            <Divider />
            {/* Lista de elementos */}
            <Collapse
              in={!!openCategories[category]}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {items.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      alignItems="flex-start"
                      className={clsx(classes.item, {
                        [classes.currentVideo]: currentVideo && currentVideo.id === item.id,
                      })}
                      key={index}
                      onClick={item.thumbnailUrl ? () => handleVideo(item) : null}
                    >
                      <ListItemAvatar>
                        <img
                          alt={item.title}
                          className={classes.thumbnail}
                          height={60}
                          src={item.thumbnailUrl}
                          width={100}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            {item.title}
                            <Typography
                              className={classes.inline}
                              color="textSecondary"
                              component="span"
                              style={{ marginLeft: '1rem' }}
                              variant="body2"
                            >
                            Subido: {item.uploadTime}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={formatText(item.description)}
                        style={{ marginLeft: '1rem' }}
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
    </List>
  );
};