import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core';
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletToolbar } from 'components';
import { options } from './chart';
import styles from './styles';

const SalesChart = ({ classes, className, meters, ...rest }) => {
  const [top, setTop] = useState(10);

  const rootClassName = className ? `${classes.root} ${className}` : classes.root;

  const dataLabels = meters
    .sort((a, b) => {
      const valueA = parseFloat((a.consumes && a.consumes.total || '').replace(/,/g, '') || 0);
      const valueB = parseFloat((b.consumes && b.consumes.total || '').replace(/,/g, '') || 0);
      return valueB - valueA;
    })
    .slice(0, top)
    .map(item => ({
      name: item.name,
      value: item.consumes && item.consumes.total ? parseFloat(item.consumes.total.replace(/,/g, '')) : 0,
    }));


  const generateColors = (num) =>
    Array.from({ length: num }, () => `#${Array.from({ length: 6 }, () => Math.floor(Math.random() * 16).toString(16)).join('')}`);

  const data = {
    labels: dataLabels.map((item) => item.name),
    datasets: [
      {
        label: 'Total',
        backgroundColor: generateColors(dataLabels.length),
        data: dataLabels.map((item) => item.value),
      },
    ],
  };


  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title="Importe Total por Locación" />
        {meters.length > 3 && (
          <PortletToolbar >
            <FormControl
              className={classes.selected}
              sx={{ m: 1, minWidth: 120 }}
            >
              <Select
                onChange={(e) => setTop(e.target.value)}
                value={top}
              >
                <MenuItem value={3}>Top 3</MenuItem>
                <MenuItem value={5}>Top 5</MenuItem>
                {meters.length > 5 && <MenuItem value={10}>Top 10</MenuItem>}
                <MenuItem value={meters.length}>Todos</MenuItem>
              </Select>
            </FormControl>
          </PortletToolbar>
        )}
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </PortletContent>
    </Portlet>
  );
};

SalesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  meters: PropTypes.array.isRequired,
};

export default withStyles(styles)(SalesChart);
