/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// Externals
import classNames from 'classnames';
// Material helpers

// Material components
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';


// Shared components
import {
  Portlet,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { VideoList } from '../VideoList';
import { PDFList } from '../PDFList';
import { TotalList } from '../TotalList';

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`scrollable-auto-tab-${index}`}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


function ListTabs (props) {
  const classes = useStyles();
  const { className, videos, currentVideo, handleVideo, pdf, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const all = videos && pdf ? videos.length + pdf.length : 0;

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <AppBar
        color="default"
        indicatorColor="primary"
        position="static"
        textColor="primary"
        variant="fullWidth"
      >
        <Tabs
          indicatorColor="secondary"
          onChange={handleChange}
          textColor="secondary"
          value={value}
          variant="fullWidth"
        >
          <Tab
            label={'Todos ('+ all + ')'}
            {...a11yProps(0)}
          />
          <Tab
            label={'Videos'}
            {...a11yProps(1)}
          />
          <Tab
            label={'Documentos'}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel
        index={0}
        value={value}
      >
        <TotalList 
          currentVideo={currentVideo}
          handleVideo={handleVideo}
          pdf={pdf}
          videos={videos}
        />
      </TabPanel>
      <TabPanel
        index={1}
        value={value}
      >
        <VideoList
          currentVideo={currentVideo}
          handleVideo={handleVideo}
          videos={videos}
        />
      </TabPanel>
      <TabPanel
        index={2}
        value={value}
      >
        <PDFList pdf={pdf} />
      </TabPanel>
      <PortletFooter className={classes.portletFooter} />
    </Portlet>
  )
}

export default ListTabs;
