/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { CorporativeCard } from '../../components';
import { showCorporatives } from 'js/actions/index';
import {deleteCorporative} from 'js/actions/index'
import { setCorpId } from 'js/actions/userActions.js';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2'


export default function CorporativeGrid(props) {
  const intl = useIntl();
  const corporatives = useSelector(state => state.corporatives.corporatives);
  let corporativeStatus = useSelector(state => state.corporatives.loading)
  const dispatch = useDispatch();
  const { editButton, deleteButton } = props;
  const user = useSelector(state=>state.user.user);
  let isLoading = corporativeStatus

  const setCorporativeId = (corpId) =>{
    dispatch(setCorpId(corpId))
  }

  useEffect(() => {
    dispatch(showCorporatives());
  }, []);

  const deleteCorporativeData = (corpid) => {
    Swal.fire({
      title: `${intl.formatMessage({id:'app.groupsCards.deleteGroupBtn.title'})} `,
      text: `${intl.formatMessage({id:'app.groupsCards.deleteGroupBtn.text'})} ${corpid.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.confirmation'})
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.titleConfirmation'}),
          text: intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.textConfirmation'}),
          icon: 'success'
        });
        dispatch( deleteCorporative(corpid.id))
      }
    });
  }

  const getEditButton = (corporative) =>{
    if(editButton == false){
      return(
        <div />
      )
    }
    else{
      return(
        <Link
          to={{
            pathname: `/updateCorporative/${corporative.id}`,
            state: {corporative: corporative, edit:true}
          }}
        >
          <Button
            color="secondary"
            variant="outlined"
          >{intl.formatMessage({id: 'app.corporate.grid.btnEdit'})}</Button>
        </Link>
       
      )
    }
  }

  const getDeleteButton = (corpid) =>{
    if(deleteButton == false){
      return(
        <div />
      )
    }
    else{
      return(
        <Button onClick={()=> deleteCorporativeData(corpid)}>{intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn'})}</Button>
      )
    }
  }

  return (
    <div>
      {isLoading ? (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><CircularProgress /></div>):
        (
      <>
        {
          corporatives.length >= 1 ? 
            (<Grid
              container
              spacing={4}
            >
              {corporatives.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0).map(corporative => (
                <Grid
                  item
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <Link
                    onClick={()=>setCorporativeId({id:corporative.id,logo:corporative.logo,name:corporative.name})}
                    to={{
                      pathname: `/dashboard/${corporative.id}`,
                      state: corporative
                    }}
                  >
                    <CorporativeCard
                      corporative={corporative}
                      deleteButton={deleteButton}
                      editButton={editButton}
                      item
                      key={corporative.id}
                    />
                  </Link>
                  <div>
                    {getEditButton(corporative)}
                    {getDeleteButton(corporative)}
                  </div>
                </Grid>
              ))}
            </Grid> )
            :
            (<Grid
              container
              spacing={4}
            >
              <p>Corporativos no encontrados</p>
            </Grid>)
        }
      </>
        )}
    </div>
  )

  // if (corporatives.length >= 1) {
  //   return (
  //     <Grid
  //       container
  //       spacing={4}
  //     >
  //       {corporatives.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0).map(corporative => (
  //         <Grid
  //           item
  //           lg={3}
  //           md={6}
  //           xs={12}
  //         >
  //           <Link
  //             onClick={()=>setCorporativeId({id:corporative.id,logo:corporative.logo,name:corporative.name})}
  //             to={{
  //               pathname: `/corporative/${corporative.id}`,
  //               state: corporative
  //             }}
  //           >
  //             <CorporativeCard
  //               corporative={corporative}
  //               deleteButton={deleteButton}
  //               editButton={editButton}
  //               item
  //               key={corporative.id}
  //             />
  //           </Link>
  //           <div>
  //             {getEditButton(corporative)}
  //             {getDeleteButton(corporative)}
  //           </div>
  //         </Grid>
  //       ))}
  //     </Grid>
  //   );
  // } else {
  //   return (
  //     <Grid
  //       container
  //       spacing={4}
  //     >
  //       <p>Corporativos no encontrados</p>
  //     </Grid>
  //   );
  // }
}
