/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import { Button, Grid, TextField, InputAdornment, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
  form: {
    marginBottom: '20px',
  },
});

function UserConcentratorForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    lada: '52',
    cel_number: '',
  });

  const alertEx = props.alert||null 

  const [tableData, setTableData] = useState([]);
  const [errors, setErrors] = useState({ email: '', cel_number: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const handleEditContact = (rowData, index) => {
    setFormData(rowData); // Cargar datos del contacto al formulario
    setIsEditing(true);
    setEditIndex(index); // Guardar índice del contacto a editar
  };
  
  const handleSaveContact = () => {
    if (validateForm()) {
      if (isEditing) {
        // Actualizar contacto existente
        const updatedTableData = [...tableData];
        const fullPhone = `+${formData.lada}${formData.cel_number}`;
        updatedTableData[editIndex] = { ...formData, cel_number: fullPhone };
        setTableData(updatedTableData);
        Swal.fire({
          title: 'Contacto actualizado exitosamente',
          icon: 'success',
        });
      } else {
        // Agregar un nuevo contacto
        const fullPhone = `+${formData.lada}${formData.cel_number}`;
        setTableData([...tableData, { ...formData, cel_number: fullPhone }]);
        Swal.fire({
          title: 'Contacto agregado exitosamente',
          icon: 'success',
        });
      }
  
      // Resetear formulario
      setFormData({ name: '', lastname: '', email: '', lada: '52', cel_number: '' });
      setErrors({ email: '', cel_number: '' });
      setIsEditing(false);
      setEditIndex(null);
    }
  };  

  useEffect(() => {
    if (alertEx !== null && alertEx.contacts !== null) {
      setTableData(alertEx.contacts);
    }
  }, [alertEx]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    // Validación en tiempo real
    if (id === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setErrors({
        ...errors,
        email: emailRegex.test(value) ? '' : 'Correo inválido',
      });
    } else if (id === 'cel_number') {
      const phoneRegex = /^[0-9]{7,}$/;
      setErrors({
        ...errors,
        cel_number: phoneRegex.test(value) ? '' : 'El número de teléfono debe tener al menos 8 dígitos',
      });
    }
  };

  const handleLadaChange = (e) => {
    setFormData({ ...formData, lada: e.target.value });
  };

  const validateForm = () => {
    const { name, lastname, email, cel_number } = formData;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{7,}$/;

    const newErrors = {
      email: emailRegex.test(email) ? '' : 'Correo inválido',
      cel_number: phoneRegex.test(cel_number) ? '' : 'El número de teléfono debe tener al menos 7 dígitos',
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error) && name && lastname && email && cel_number;
  };

  const handleAddContact = () => {
    if (validateForm()) {
      const fullPhone = `+${formData.lada}${formData.cel_number}`;
      setTableData([...tableData, { ...formData, cel_number: fullPhone }]);
      setFormData({ name: '', lastname: '', email: '', lada: '52', cel_number: '' });
      setErrors({ email: '', cel_number: '' });
      Swal.fire({
        title: 'Contacto agregado exitosamente',
        icon: 'success',
      });
    }
  };

  const handleDeleteContact = (rowData) => {
    setTableData(tableData.filter((contact) => contact !== rowData));
  };

  const sendRules = (rules) => {
    if (!rules || rules.length <= 0) {
      Swal.fire({
        title: 'Debe crear al menos una regla',
        icon: 'error',
      });
      return;
    }
    dispatch(postForm(rules));
    props.nextStep();
  };

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid
        item
        xs={12}
      >
        <form
          className={classes.form}
          noValidate
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={3}
              md={6}
              sm={12}
            >
              <TextField
                fullWidth
                id="name"
                label="Nombre"
                margin="normal"
                onChange={handleInputChange}
                value={formData.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              sm={12}
            >
              <TextField
                fullWidth
                id="lastname"
                label="Apellido"
                margin="normal"
                onChange={handleInputChange}
                value={formData.lastname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              sm={12}
            >
              <TextField
                error={!!errors.email}
                fullWidth
                helperText={errors.email}
                id="email"
                label="Correo"
                margin="normal"
                onChange={handleInputChange}
                value={formData.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              sm={12}
            >
              <TextField
                error={!!errors.cel_number}
                fullWidth
                helperText={errors.cel_number}
                id="cel_number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        value={formData.lada}
                        onChange={handleLadaChange}
                        style={{ minWidth: 70 }}
                      >
                        <MenuItem value="52">+52</MenuItem>
                        <MenuItem value="57">+57</MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
                label="Teléfono"
                margin="normal"
                onChange={handleInputChange}
                value={formData.cel_number}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {isEditing ? (
            <Button
              color="secondary"
              fullWidth
              onClick={handleSaveContact}
              style={{ marginTop: '20px' }}
              variant="contained"
            >
              Guardar Cambios
            </Button>
          ):(
            <Button
              color="secondary"
              disabled={
                !(formData.name.length > 3 &&
                  formData.lastname.length > 3 &&
                  formData.email.includes('@') &&
                  formData.cel_number.length > 7)
              }            
              fullWidth
              onClick={handleAddContact}
              style={{ marginTop: '20px' }}
              variant="contained"
            >
              Agregar Contacto
            </Button>
          )}
        </form>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <MaterialTable
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                const index = tableData.indexOf(rowData);
                handleEditContact(rowData, index);
              },
            },
            {
              icon: () => <DeleteIcon />,
              tooltip: 'Eliminar',
              onClick: (event, rowData) => handleDeleteContact(rowData),
            },
          ]}
          columns={[
            { title: 'Nombre', field: 'name' },
            { title: 'Apellido', field: 'lastname' },
            { title: 'Correo', field: 'email' },
            { title: 'Teléfono', field: 'cel_number' },
          ]}
          data={tableData}
          options={{
            actionsColumnIndex: -1,
            pageSize: 5,
          }}
          title="Contactos"
        />
      </Grid>
      <Grid
        item
        xl={12}
        xs={12}
        style={
          {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1rem'
          }
        }
      >
        <Button
          color="secondary"
          fullWidth
          onClick={props.prevStep}
          style={{ marginTop: '20px', width: '45%' }}
          variant="outlined"
        >
          Regresar
        </Button>
        <Button
          color="secondary"
          disabled={!tableData.length}
          fullWidth
          onClick={() => sendRules(tableData)}
          style={{ marginTop: '20px', width: '45%' }}
          variant="contained"
        >
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
}

export default UserConcentratorForm;