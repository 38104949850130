export default theme => ({
  root: {},
  form: {},
  thumbnail: {
    objectFit: 'cover'
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing.unit * 2
  },
  inline: {
    display: 'inline',
  },
  item: {
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#DEDEDE'
    }
  },
  currentVideo: {
    backgroundColor: '#DEDEDE'
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});
