/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormGroup, FormHelperText, FormLabel, InputAdornment, makeStyles, OutlinedInput } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField,
  Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { getChargeList, updateDataGroup } from 'js/actions/groupActions';
import { useIntl } from 'react-intl';
import theme from 'theme';
import { AddCircleOutline, Delete } from '@material-ui/icons';
import Swal from 'sweetalert2';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}



export default function GroupForm(props) {
  const intl = useIntl()
  const {group} = props.location.state
  
  const { getValues, register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {meterId, corpId} = props.match.params;
  const {charge_types} = useSelector(state => state.groups);
  const [unificar, setUnificar] = React.useState(group.bill && group.bill === 1 ? {yes: true, no: false} : {yes: false, no: true})
  const energys = Object.values(charge_types);
  const [iva, setIva] = useState(group.iva ? group.iva : 16);
  const [customProperties, setCustomProperties] = useState(group.custom_fields ? group.custom_fields: []);
  const [typeTariff, setTypeTariff] = useState('percentage')
  const [percentage, setPercentage] = useState('');
  const [amount, setAmount] = useState('');
  
  const getCurrentDay = () => {
    const day = new Date()

    const currentDay = day.getDate()

    return 5
  }



  const tarifas = [
    //'DB1',
    //'DB2',
    'PDBT',
    'GDBT',
    //'RABT',
    //'RAMT',
    //'APBT',
    //'APMT',
    'GDMTO',
    'GDMTH',
    //'DIST',
    //'DIT'
  ];

  useEffect(()=>{
    dispatch(getChargeList());
  },[]);

  const getCustomTariff = (customTariffValidator) => {
    if(  customTariffValidator==null || customTariffValidator=='' ){
      return false;
    }

    switch ( parseInt( customTariffValidator)) {
      case 0:
        return false
      case 1:
        return true
          
    
      default:
        break;
    }
  }

  const [state, setState] = React.useState({
    customTariffValidator: getCustomTariff(group.is_custom_tariff)
  });
  const { customTariffValidator } = state;

  const onSubmit = values => {
    values.rateType = rateType
    values.bill = bill;
    values.type_energy = typeEnergy;
    dispatch(updateDataGroup(values,group, iva, customProperties));
  };

  const handleChargeType = event => {
    setTypeEnergy(event.target.value); 
  };

  const [rateType, setRateType] = React.useState(group.rate_type);
  const [bill, setBill] = React.useState(group.bill ? group.bill : false);
  const [typeEnergy, setTypeEnergy] = React.useState(energys[group.type_energy]);

  const handleRateType = event => {
    setRateType(event.target.value);
  }

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleUnificar = event => {
    const {name, checked} = event.target;
    if(name === 'yes' && checked){
      setUnificar({
        yes: true,
        no: false
      })
      setBill(true);
    }else if(name === 'no' && checked){
      setUnificar({
        yes: false,
        no: true
      });
      setBill(false);
    }
  }

  const handleAddCustomProperties = () => {
    if(customProperties.length < 3){
      setCustomProperties([...customProperties, {name: '', value: 0, type: 'percentage'}])
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Solo se Pueden Agregar 3 Cargos'
      })
    }
  }

  const handleDeleteItem = (index) => {
    setCustomProperties(customProperties.filter((_, i) => i !== index));
  };
  
  const getTariffSelectorComponent = () => {
    if (customTariffValidator === false) {
      return (
        <div>
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                defaultChecked={customTariffValidator}
                inputRef={register}
                name="is_custom_tariff"
                onChange={handleChange('customTariffValidator')}
              />
            }
            label={intl.formatMessage({ id: 'app.groupsCards.editGroup.customRateLbl' })}
          />
          <FormControl
            className={classes.textField}
            variant="outlined"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {intl.formatMessage({ id: 'app.groupsCards.editGroup.rateTypeLbl' })}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              inputRef={register}
              labelId="demo-simple-select-outlined-label"
              name="rateType"
              onChange={handleRateType}
              value={rateType}
            >
              {tarifas.map(el => (
                <MenuItem
                  key={el}
                  value={el}
                >{el}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    } else {
      return (
        <TextField
          className={classes.textField}
          defaultValue={group.custom_tariff}
          inputRef={register}
          label={intl.formatMessage({ id: 'app.groupsCards.editGroup.customRateLbl' })}
          name="custom_tariff"
          variant="filled"
        />
      );
    }
  };


  return (
    <DashboardLayout title="Creacion de Grupos">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.groupsCards.editGroup.title'})} />
            <Typography variant="caption">{intl.formatMessage({id: 'app.groupsCards.editGroup.msg'})}  </Typography>
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={group.name}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.groupNameLbl'})}
                      name="name"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={group.description}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.description'})}
                      name="description"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={group.monitoring_fee}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.monitChargeLbl'})}
                      name="monitoring_fee"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={group.flat_fee}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.fixedChargeLbl'})}
                      name="flat_fee"
                      required
                      //value={description}
                      variant="filled"
                    />
                    { getCurrentDay() > 5 ? (null): ( getTariffSelectorComponent() )}
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2}}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        inputRef={useOnMount}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.typeEnergy'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-label-outlined"
                        onChange={handleChargeType}
                        value={typeEnergy}
                      >
                        {charge_types ? Object.values(charge_types).map(el => (
                          <MenuItem
                            value={el}
                          >{el}</MenuItem>
                        )): (<MenuItem />)}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >{intl.formatMessage({id: 'app.chargesCards.unifyLoad'})}</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.yes}
                              color="secondary"
                              name="yes"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.yes'})}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.no}
                              color="secondary"
                              name="no"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.no'})}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.ivaTitle'})}
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 8}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(8)}}
                            />
                          }
                          label="8%"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 16}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(16)}}
                            />
                          }
                          label="16%"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  {customProperties.length > 0 && (
                    customProperties.map((item, index) => (                 
                      <Grid
                        item
                        key={index}
                        lg={12}
                        md={12}
                        style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: theme.spacing.unit * 2, flexWrap: 'wrap', alignItems: 'center'}}
                        xl={12}
                        xs={12}
                      >
                        <Grid
                          item
                          lg={5}
                          md={12}
                          xl={5}
                          xs={12}
                        >
                          <TextField
                            className={classes.textField}
                            label="Cargos Personalizados"
                            onChange={(e) => {
                              const name = e.target.value;
                              const updatedProperties = customProperties.map((property, i) =>
                                i === index ? { ...property, name } : property
                              );
                              setCustomProperties(updatedProperties);
                            }}
                            value={customProperties[index].name}
                            variant="filled"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                          xl={6}
                          xs={12}
                        >
                          <Grid
                            item
                            lg={5}
                            md={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
                            xl={5}
                            xs={12}
                          >
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={customProperties[index].type === 'percentage'}
                                    color="secondary"
                                    name="percentage"
                                    onChange={() => {
                                      const updatedProperties = customProperties.map((property, i) =>
                                        i === index ? { ...property, type: 'percentage', value: '' } : property
                                      );
                                      setCustomProperties(updatedProperties);
                                    }}
                                  />
                                }
                                label="Porcentaje"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={customProperties[index].type === 'amount'}
                                    color="secondary"
                                    name="amount"
                                    onChange={() => {
                                      const updatedProperties = customProperties.map((property, i) =>
                                        i === index ? { ...property, type: 'amount', value: '' } : property
                                      );
                                      setCustomProperties(updatedProperties);
                                    }}
                                  />
                                }
                                label="Monto"
                              />
                            </FormGroup>
                          </Grid>

                          <Grid
                            item
                            lg={6}
                            md={12}
                            xl={6}
                            xs={12}
                          >
                            {customProperties[index].type === 'percentage' ? (
                              <FormControl
                                fullWidth
                                variant="filled"
                              >
                                <InputLabel htmlFor={`outlined-adornment-percentage-${index}`}>
                                Porcentaje
                                </InputLabel>
                                <OutlinedInput
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                  error={customProperties[index].value > 100}
                                  id={`outlined-adornment-percentage-${index}`}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(',', '.'); // Reemplaza comas por puntos
                                    if (/^-?\d*\.?\d*$/.test(value)) { // Valida que sea un número flotante
                                      value = parseFloat(value) || 0; // Convierte a flotante o 0 si está vacío
                                      if (value >= 0 && value <= 100) { // Verifica que esté dentro del rango
                                        const updatedProperties = customProperties.map((property, i) =>
                                          i === index ? { ...property, value: parseFloat(value) || 0 } : property
                                        );
                                        setCustomProperties(updatedProperties);
                                      }
                                    }
                                  }}
                                  value={customProperties[index].value || ''}
                                />
                                <FormHelperText>
                                  {customProperties[index].value > 100
                                    ? 'El porcentaje no puede exceder 100'
                                    : 'Debe ser un número entre 0 y 100'}
                                </FormHelperText>
                              </FormControl>
                            ) : (
                              <FormControl
                                fullWidth
                                variant="filled"
                              >
                                <InputLabel htmlFor={`outlined-adornment-amount-${index}`}>
                                 Monto
                                </InputLabel>
                                <OutlinedInput
                                  id={`outlined-adornment-amount-${index}`}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(',', '.'); // Reemplaza comas por puntos
                                    if (/^-?\d*\.?\d*$/.test(value)) { // Acepta números con decimales
                                      // Formatea para limitar a 2 decimales si es necesario
                                      const parts = value.split('.');
                                      if (parts.length === 2 && parts[1].length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                      }

                                      const updatedProperties = customProperties.map((property, i) =>
                                        i === index ? { ...property, value } : property
                                      );
                                      setCustomProperties(updatedProperties);
                                    }
                                  }}
                                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                  value={customProperties[index].value || ''}
                                />
                                <FormHelperText>Ingrese un monto válido</FormHelperText>
                              </FormControl>
                            )}
                          </Grid>


                        </Grid>
                        <Grid 
                          item
                          lg={1}
                          md={12}
                          style={{display: 'flex', justifyContent: 'flex-end', color:'red', cursor: 'pointer'}}
                          xl={1}
                          xs={12}
                        >
                          <Delete onClick={() => handleDeleteItem(index)} />
                        </Grid>
                      </Grid>
                    ))
                  ) }
                </Grid>
              </Grid>
            
              <Button
                className={classes.signUpButton}
                color="secondary"
                disabled={customProperties.length < 3 ? false : true}
                onClick={handleAddCustomProperties}
                size="large"
                style={{marginTop:'1.5em'}}
                variant="outlined"
              >
                <AddCircleOutline
                  disabled={customProperties.length < 3 ? false : true}
                  style={{marginRight: theme.spacing.unit, color: theme.palette.secondary.main}}
                />
                ¿Deseas Agregar un Cargo Personalizado?
              </Button>

              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                //onClick={this.handleSubmit}
                style={{marginTop:'2.5em'}}
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.groupsCards.editGroup.registerBtn'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}