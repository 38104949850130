/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { CompareArrows } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import ComponetA from './ComponetA';
import ComponetB from './ComponetB';

class Profit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isLocked: false,
      showMainButton: true,
      infoA: null,
      infoB: null
    };
  }

  handleSearchComplete = () => {
    this.setState({ isLocked: false }); 
  };

  render() {
    const { classes, className, meter, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    
    const { open, isLocked, showMainButton, infoA, infoB } = this.state;

    const handleDialog = () => {
      if(meter){
        this.setState({
          open: true
        });
      }
    };

    const setInfoA = (value) => {
      this.setState({
        infoA: value
      })
    }

    const setInfoB = (value) => {
      this.setState({
        infoB: value
      })
    }


    return (
      <>
        <Paper
          {...rest}
          className={rootClassName}
          onClick={() =>handleDialog()}
        >
          <div className={classes.content}>
            <div className={classes.details}>
              <Typography
                className={classes.title}
                variant="body2"
              >
                Comparativo de Mediciones
              </Typography>
            </div>
            <div className={classes.iconWrapper}>
              <CompareArrows className={classes.icon} />
            </div>
          </div>
        </Paper>
        <Dialog
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth="lg"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
          >
            <Chip
              className={classes.chip}
              label="Comparativo de Mediciones"
            />
          </DialogTitle>
          <DialogContent>
            <Grid
              alignItems="center"
              container
              justify="space-between"
              spacing={2}
            >
              <Grid
                alignItems="center"
                item
                lg={5}
                sm={5}
              >
                <ComponetA
                  meters={meter}
                  onSearchComplete={this.handleSearchComplete}
                  setInfoA={setInfoA}
                  showMainButton={showMainButton}
                />
              </Grid>
              <Divider
                flexItem
                orientation="vertical"
              />
              <Grid
                alignItems="center"
                item
                lg={5}
                sm={5}
              >
                <ComponetB
                  isLocked={isLocked}
                  meters={meter}
                  setInfoB={setInfoB}
                  showMainButton={showMainButton}
                />
              </Grid>
              {showMainButton && (
                <Button
                  color="secondary"
                  disabled={infoA && infoB ? false : true}
                  fullWidth
                  onClick={() => this.setState({showMainButton: false})}
                  style={{marginTop: '1rem'}}
                  type="button"
                  variant="contained"
                >
                  Comparar Mediciones
                </Button>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({
                  open: !open,
                  showMainButton: true,
                  infoA: null,
                  infoB: null
                });
              }}
              type="button"
              variant="contained"
            >Cerrar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Profit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Profit);
