/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { CircularProgress, withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { AttachMoney } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class Users extends Component {
  render() {
    const { classes, className, meters, ...rest } = this.props;

    const totalMeter = meters ? meters.reduce((sum, consume) => {
      if (typeof consume.consumes.total === 'string'){
        return sum + parseFloat(consume.consumes.total.replace(/,/g, ''));
      } else {
        return sum + consume.consumes.total
      }
    }, 0) : 0;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              Importe Estimado Acumulado al Día
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {totalMeter ? formatter.format(totalMeter) : <CircularProgress style={{color: 'white'}} />}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <AttachMoney className={classes.icon} />
          </div>
        </div>
        <div className={classes.footer}>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            *No incluye IVA
          </Typography>
        </div>
      </Paper>
    );
  }
}

Users.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Users);
