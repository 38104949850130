import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { axios } from 'helpers';
import getToken from 'helpers/auth';
import { useIntl } from 'react-intl';
import ReactSpeedometer from 'react-d3-speedometer';

const ComponetA = props => {
  const { meters, showMainButton, setInfoA } = props;
  const intl = useIntl()
  const [meterSelected, setMeterSelected] = useState(null);
  const [groupSelected, setGroupSelected] = useState(null);
  const [chargeSelected, setChargeSelected] = useState(null);
  const [chargeSelectedInfo, setChargeSelectedInfo] = useState(null);
  const [groups, setGroups] = useState(null);
  const [charges, setCharges] = useState(null);
  const [showInfo, setShowInfo] = useState(false)
  const [fpValidation, setFpValidation] = useState(false);
  const [fpInfo, setFpInfo] = useState(null);
  const currentYear = moment().year();
  const currentMonth = moment().month();
  const currentDate = moment().format('YYYYMMDD');
  const [currentButton, setCurrentButton] = useState(true)

  const getConfig=()=>{
    return {
      headers: { Authorization: 'Bearer ' + getToken( ) }
    }
  }

  const getGroups = async (meter) => {
    const response  = await axios.get(
      `https://api.metimur.mx/api/meter/${meter}/groups`,
      getConfig()
    )
    return setGroups(response.data.data)
  };

  const getCharges = async (groupId) => {
    const response = await axios.get(
      `https://api.metimur.mx/api/group/${groupId}/charges`,
      getConfig()
    )
    return setCharges(response.data.data)
  };

  const getCharge = async (chargeId, year, month) => {
    const response = await axios.get(
      `https://api.metimur.mx/api/charge/${chargeId}/${year}/${month}/totals`,
      getConfig()
    )
    return setChargeSelectedInfo(response.data.data);
  }

  const getFpInfo = async (fpId) => {
    const response = await axios.get(
      `https://api.metimur.mx/api/meter/${fpId}/fp`,
      getConfig()
    )
    return setFpValidation(response.data)
  }

  const fpConsume = async (fpId, startDate, endDate, startHour, endHour) => {
    const response = await axios.post(
      'https://api.metimur.mx/api/fp/consumes',
      {
        fp_id: fpId,
        start_date: startDate,
        end_date: endDate,
        start_hour: startHour,
        end_hour: endHour
      },
      getConfig()
    )
    return setFpInfo(response.data.data)
  }

  useEffect(()=>{
    if(meterSelected) {
      getGroups(meterSelected)
      getFpInfo(meterSelected)
    }
  },[meterSelected])

  useEffect(()=>{
    if(groupSelected) {
      getCharges(groupSelected)
      if(fpValidation.success === true){
        fpConsume(fpValidation.data[0].id, currentDate, currentDate, 0,0,)
      }
    }
  },[groupSelected])

  useEffect(()=>{
    if(chargeSelected){
      getCharge(chargeSelected, currentYear, currentMonth)
    }
  },[chargeSelected]);

  useEffect(() => {
    if (!showMainButton) {
      handleShow();
    }
  }, [showMainButton]);

  const handleGroup = (e) => {
    if(groups) {
      setGroups(null)
      setCharges(null)
      setFpValidation(false)
      setChargeSelected(null)
      setFpInfo(null)
      setMeterSelected(e.target.value)
    } else {
      setMeterSelected(e.target.value)
    }

  };

  const handleCharge = (e) => {
    if(charges) {
      setCharges(null)
      setFpValidation(false)
      setFpInfo(null)
      setGroupSelected(e.target.value)
    } else {
      setGroupSelected(e.target.value)
    }
  };

  const handleInfo = (e) => {
    setInfoA(e.target.value);
    setChargeSelected(e.target.value)
  };

  const handleShow = () => {
    setShowInfo(true)
    setCurrentButton(false)
  }

  const handleClean = () => {
    setMeterSelected(null)
    setGroupSelected(null)
    setChargeSelected(null)
    setChargeSelectedInfo(null)
    setFpValidation(false)
    setFpInfo(null)
    setShowInfo(false)
    setGroups([])
    setCharges([])
    setCurrentButton(true)
    setInfoA(null)
  }

  const voltPart = volt => {
    let voltPart0= Math.floor (volt*0.85);
    let voltPart1= Math.floor (volt*0.9  ) ;
    let voltPart2= Math.floor (volt*0.95  ); 
    let voltPart3= Math.floor (volt*1.05  ); 
    let voltPart4= Math.floor (volt*1.1  );
    let voltPart5= Math.floor (volt*1.15  );

    return [voltPart0, voltPart1, voltPart2 , voltPart3 , voltPart4 , voltPart5]
  }

  const getVoltage = (charge) => {
    if(charge){
      if(charge.consumes.voltege){
        return charge.consumes.voltage
      } else {
        return 220
      }
    }
  }

  const segmentFormatter = value => {
    return `${value}%`;
  };

  const formatPercentage = value => {
    if (value == null) {
      return '0.00';
    }
    return value;
  }; 

  return (
    <Grid 
      alignItems="center"
      container
      direction="row"
      justify="space-between"
      spacing={2}
      wrap="wrap"
    >
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel 
            id="demo-simple-select-outlined-label"
            style={{fontSize: '1.2rem'}}
          >Nodo(s)</InputLabel>
          <Select
            id="demo-simple-select-outlined"
            label="Nodo(s)"
            labelId="demo-simple-select-outlined-label"
            onChange={(e)=>handleGroup(e)}
            value={meterSelected}
          >
            {meters && (
              meters.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                >{item.name}</MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            style={{fontSize: '1.2rem'}}  
          >Grupos</InputLabel>
          <Select
            id="demo-simple-select-outlined"
            label="Grupos"
            labelId="demo-simple-select-outlined-label"
            onChange={(e)=>handleCharge(e)}
            value={groupSelected}
          >
            {groups ? (
              groups.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                >{item.name}</MenuItem>
              ))
            ):(<MenuItem />)}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            style={{fontSize: '1.2rem'}}
          >Medición</InputLabel>
          <Select
            id="demo-simple-select-outlined"
            label="Medición"
            labelId="demo-simple-select-outlined-label"
            onChange={(e)=>handleInfo(e)}
            value={chargeSelected}
          >
            {charges ? (
              charges.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                >{item.name}</MenuItem>
              ))
            ): (
              <MenuItem />
            )}
          </Select>
        </FormControl>
      </Grid>
      {!showMainButton && (
        <Box
          display="flex"
          gap="1rem"
          justifyContent="space-between"
          mt="1rem"
          width="100%"
        >
          {currentButton ? (
            <Button
              color="secondary"
              disabled={chargeSelected ? false : true}
              fullWidth
              onClick={() => handleShow()}
              type="button"
              variant="contained"
            >
              Comparar Mediciones
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={chargeSelectedInfo ? false : true}
              fullWidth
              onClick={handleClean}
              type="button"
              variant="contained"
            >
              Limpiar
            </Button>
          )}
        </Box>
      )}
      {chargeSelectedInfo && showInfo && (
        <Card
          style={{margin: '1rem auto', width: '100%'}}
        >
          <CardHeader
            title={chargeSelectedInfo.charge_info.name}
          />
          <CardContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell
                      style={{
                        fontWeight: 500,
                        fontSize: '0.95em'
                      }}
                    >
                      {intl.formatMessage({id: 'app.metersCards.card.gridCard.baseCol'})}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 500,
                        fontSize: '0.95em'
                      }}
                    >
                      {intl.formatMessage({id: 'app.metersCards.card.gridCard.intermidateCol'})}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 500,
                        fontSize: '0.95em'
                      }}
                    >
                      {intl.formatMessage({id: 'app.metersCards.card.gridCard.tipCol'})}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: 500,
                        fontSize: '0.95em'
                      }}
                    >
                      kWh
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#8CBA42',
                        color: '#FFF',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '0.95em'
                      }}
                    >
                      {chargeSelectedInfo ? chargeSelectedInfo.consumes.base.consume_kwh : 0}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EAAB36',
                        color: '#FFF',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '0.95em'
                      }}
                    >
                      {chargeSelectedInfo ? chargeSelectedInfo.consumes.intermedio.consume_kwh : 0}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#D64539',
                        color: '#FFF',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '0.95em'
                      }}
                    >
                      {chargeSelectedInfo ? chargeSelectedInfo.consumes.punta.consume_kwh : 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: 500,
                        fontSize: '0.95em'
                      }}
                    >
                      kW
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#8CBA42',
                        color: '#FFF',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '0.95em'
                      }}
                    >
                      {chargeSelectedInfo ? chargeSelectedInfo.consumes.base.demand_kw : 0}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EAAB36',
                        color: '#FFF',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '0.95em'
                      }}
                    >
                      {chargeSelectedInfo ? chargeSelectedInfo.consumes.intermedio.demand_kw : 0}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#D64539',
                        color: '#FFF',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '0.95em'
                      }}
                    >
                      {chargeSelectedInfo ? chargeSelectedInfo.consumes.punta.demand_kw : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt="1rem"
              width="100%"
            >
              <Typography
                style={{display:'flex', flexDirection: 'row', gap: '1rem'}}
                variant="h5"
              >
                {intl.formatMessage({id:'app.chargesCards.card.estAmtPerDay'})}
                <Typography
                  style={{color:'#218a1d', fontWeight:'600'}}
                  variant="h5"
                >
                  $ {(chargeSelectedInfo.consumes.billing.total_unformat || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography> 
              </Typography>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              mt="1rem"
              width="100%"
            >
              {fpValidation.success && fpInfo && (                
                <Box
                  fontFamily="Lato, Helvetica Neue,Arial"
                  textAlign="center"
                  width="50%"
                >
                  <ReactSpeedometer
                    currentValueText={`${formatPercentage((Math.floor(parseFloat(fpInfo.value) * 100)) || 0).toString()}%`}
                    customSegmentStops={[70, 80, 90, 100]}
                    fontFamily={'Lato, Helvetica Neue,Arial'}
                    height={150}
                    maxValue={100}
                    minValue={70}
                    needleColor={'#000'}
                    needleHeightRatio={0.7}
                    paddingHorizontal={17}
                    paddingVertical={17}
                    ringWidth={30}
                    segmentColors={['#EB0000', '#FAC800', '#00C454']}
                    segmentValueFormatter={segmentFormatter}
                    textColor={'#000'}
                    value={formatPercentage((Math.floor(parseFloat(fpInfo.value) * 100)) || 0).toString()}
                    valueTextFontSize={'16px'}
                    valueTextFontWeight={'600'}
                    width={180}
                  />
                  <Typography
                    style={{  margin :'0px' ,  padding: '0px'  ,   color: '#000' , fontWeight :'600' }}
                    variant="h5"
                  >               
                    {intl.formatMessage({id: 'app.metersCards.card.fpLabel'})}
                  </Typography>
                </Box>
              )}
              <Box
                fontFamily="Lato, Helvetica Neue,Arial"
                textAlign="center"
                width="50%"
              >
                <ReactSpeedometer
                  currentValueText={'' +  getVoltage(chargeSelectedInfo)}
                  customSegmentStops={voltPart(getVoltage(chargeSelectedInfo))}
                  fontFamily={'Arial'}
                  forceRender
                  height={130}
                  labelFontSize={'12px'}
                  maxValue={Math.floor(getVoltage(chargeSelectedInfo) * 1.15)}
                  minValue={Math.floor(getVoltage(chargeSelectedInfo) * 0.85)}
                  needleColor={'#000'}
                  needleHeightRatio={0.7}
                  paddingHorizontal={17}
                  paddingVertical={17}
                  ringWidth={30}
                  segmentColors={['#EB0000', '#FAC800', '#00C454','#FAC800' , '#EB0000' ]}
                  textColor={'#000'}
                  value={getVoltage(chargeSelectedInfo)}
                  valueTextFontSize={'20px'}
                  valueTextFontWeight={'600'}
                  width={150}
                />
                <Typography
                  style={{  margin :'0px' ,  padding: '0px'  ,   color: '#000' , fontWeight :'600' }}
                  variant="h5"
                >               
                  {intl.formatMessage({id: 'app.metersCards.card.voltageLabel'})}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Grid>
  )
}

export default ComponetA