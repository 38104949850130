// Palette
import palette from 'theme/palette';


// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        let value = tooltipItem.value;
        label += `$ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        return label;
      }
    }
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        id: 'A',
        position:'left',
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      },
      // {
      //   id: 'B',
      //   position:'right',
      //   ticks: {
      //     fontColor: "#0080BC",
      //     beginAtZero: true,
      //     min: 0
      //   },
      //   gridLines: {
      //     borderDash: [2],
      //     borderDashOffset: [2],
      //     color: palette.divider,
      //     drawBorder: false,
      //     zeroLineBorderDash: [2],
      //     zeroLineBorderDashOffset: [2],
      //     zeroLineColor: palette.divider
      //   }
      // }
    ]
  }
};
