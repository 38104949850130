import React, { useEffect, useRef, useState } from 'react';

// Material helpers
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
  Budget,
  Users,
  Profit,
  MonthChart,
  Measurements,
  LocationMaps,
  UploadDocuments,
  ConsumptionAndDemandChart,
  MonthWithMostConsumeChart,
  ComparativeYearConsumeChart,
  DayWithMostConsumeChart,
  ChargeWithMostConsumeChart,
  MonthChartMeter,
  FPDailyAvrgChart,
  FPGauge,
  AmountByGroupChart
} from './components';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { showMeters } from 'js/actions/meterActions';
import theme from 'theme';
import { Close } from '@material-ui/icons';
import { useIntl } from 'react-intl';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  item: {
    height: '100%'
  }
});

const useStyle = makeStyles(styles);

export default function DashboardMain(props) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const {corpId} = props.match.params
  const mapContainer = useRef(null);
  const dashboardRef = useRef(null);
  const meters = useSelector(state => state.meters.meters);
  const user = useSelector(state=>state.user.user);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCorp, setSelectedCorp] = useState(null);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [meterSelected, setMeterSelected] = useState(null);
  const [meterFp, setMeterFp] = useState();
  const intl = useIntl();
  const corporative = useSelector( user.adser==1 ? state=>state.user.corporative : state=>state.user.user.corporative );

  useEffect(()=>{
    dispatch(showMeters(corpId))
  },[corpId]);

  return (
    <DashboardLayout
      chargeName={corporative.name}
      title="Dashboard"
    >
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            lg={12}
            ref={mapContainer}
            xs={12}
          >
            <Typography
              style={{ color: '#A53693', marginLeft: theme.spacing.unit * 2 }}
              variant="h2"
            >{corporative.name}</Typography>
          </Grid>
          {/* Inicia Bugets */}
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget
              className={classes.item}
              meters={meters}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Users
              className={classes.item}
              meters={meters}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Profit
              className={classes.item}
              meter={meters}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Measurements
              className={classes.item}
              corpId={corpId}
              meters={meters}
            />
          </Grid>
          {/* Termina Bugets */}
          {/* Mapa de sucursales */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <LocationMaps
              className={classes.item}
              corpId={corpId}
              dashboardRef={dashboardRef}
              mapContainer={mapContainer}
              setMeterSelected={setMeterSelected}
              setOpenDocuments={setOpenDocuments}
              setSelectedCorp={setSelectedCorp}
              setShowDetails={setShowDetails}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <MonthChart 
              className={classes.item}
              meters={meters}  
            />
          </Grid>
          <Grid
            item 
            lg={12}
            md={12}
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
            xl={12}
            xs={12}
          >
            {meterSelected && (
              <Typography
                style={{ color: '#A53693', marginLeft: theme.spacing.unit * 2 }}
                variant="h2"
              >
                {meterSelected.name}
              </Typography>
            )}
            <Grid
              item
              lg={meterSelected ? 8 : 12}
              md={meterSelected ? 8 : 12}
              xl={meterSelected ? 8 : 12}
              xs={meterSelected ? 8 : 12}
            >
              <Divider variant="fullWidth" />
            </Grid>
          </Grid>
          {meterSelected ? (
            <>
              <Grid
                item
                lg={8}
                md={12}
                ref={dashboardRef}
                xl={9}
                xs={12}
              >
                <ConsumptionAndDemandChart
                  className={classes.item}
                  meter={meterSelected}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                xl={3}
                xs={12}
              >
                <MonthWithMostConsumeChart
                  className={classes.item}
                  corpId={corpId}
                  meter={meterSelected}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <ComparativeYearConsumeChart
                  className={classes.item}
                  corpId={corpId}
                  meter={meterSelected}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                xl={3}
                xs={12}
              >
                <div style={{ marginBottom: '10px' }}>
                  <DayWithMostConsumeChart
                    className={classes.item}
                    corpId={corpId}
                    meter={meterSelected}
                  />
                </div>
                <div>
                  <ChargeWithMostConsumeChart
                    className={classes.item}
                    corpId={corpId}
                    meter={meterSelected}
                  />
                </div>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <MonthChartMeter
                  className={classes.item}
                  corpId={corpId}
                  meter={meterSelected}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <FPDailyAvrgChart
                  className={classes.item}
                  corpId={corpId}
                  meter={meterSelected}
                  setMeterFp={setMeterFp}
                />
              </Grid>              
              <Grid
                item
                lg={4}
                md={12}
                xl={3}
                xs={12}
              >
                <FPGauge
                  className={classes.item}
                  corpId={corpId}
                  meter={meterSelected}
                  meterFp={meterFp}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <AmountByGroupChart
                  className={classes.item}
                  corpId={corpId}
                  meter={meterSelected}
                />
              </Grid>
            </>
          ): null}
          <Dialog
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'sm'}
            onClose={() => {
              setShowDetails(false);
            }}
            open={showDetails}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => {
                setShowDetails(false);
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography variant="h6">
                    {selectedCorp ? selectedCorp.name : ''}
                  </Typography>
                </Box>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setShowDetails(false);
                  }}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ marginBottom: '3rem' }}
            >
              {selectedCorp ? (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.descriptLbl'
                      })}
                    </Typography>
                    {selectedCorp.description}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.countryLbl'
                      })}
                    </Typography>
                    {selectedCorp.country}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.stateLbl'
                      })}
                    </Typography>
                    {selectedCorp.state}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.cityLbl'
                      })}
                    </Typography>
                    {selectedCorp.city}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.postalCodeLbl'
                      })}
                    </Typography>
                    {selectedCorp.cp}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.neighborhoodLbl'
                      })}
                    </Typography>
                    {selectedCorp.county}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, textAlign: 'center' }}
                    variant="h6"
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {intl.formatMessage({
                        id: 'app.metersCards.editMeter.addressLbl'
                      })}
                    </Typography>
                    {selectedCorp.address}
                  </Typography>
                  {selectedCorp.contracted_demand && (
                    <Typography
                      style={{ fontWeight: 400, textAlign: 'center' }}
                      variant="h6"
                    >
                      <Typography style={{ fontWeight: 500 }}>
                        {intl.formatMessage({
                          id: 'app.metersCards.editMeter.contractedDemandLbl'
                        })}
                      </Typography>
                      {selectedCorp.contracted_demand} kW
                    </Typography>
                  )}
                </Box>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50vh'
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </DialogContent>
          </Dialog>
          <UploadDocuments
            corporative={corpId}
            open={openDocuments}
            selectedCorp={selectedCorp}
            setOpen={setOpenDocuments}
          />
        </Grid>
      </div>
    </DashboardLayout>
  );
}