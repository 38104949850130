import React from 'react';
import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

export default function ConsumptionAndDemandChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const {className, consumes, rateHorary, ...rest } = props;
  const kw = useSelector(
    state => state.chargeDashboard.generalReport.totals_per_horary_kw
  );
  const kwh = useSelector(
    state => state.chargeDashboard.generalReport.totals_per_horary
  );
  
  const getKw = kw === undefined 
    ? [0, 0, 0]
    : rateHorary === 1 
      ? Object.values(kw).map(value => parseFloat(String(value).replace(/,/g, '')))
      : [Math.max(...Object.values(kw).map(value => parseFloat(String(value).replace(/,/g, ''))))];

  const getKwh = kwh === undefined
    ? [0, 0, 0]
    : rateHorary === 1 
      ? Object.values(kwh).map(value => parseFloat(String(value).replace(/,/g, '')))
      : [Object.values(kwh).reduce((sum, value) => sum + parseFloat(String(value).replace(/,/g, '')), 0)];


  // const object = [
  //    ['kw',consumes.bas_kw,consumes.int_kw,consumes.punt_kw],
  //    ['kwh',consumes.bas_kwh,consumes.int_kwh,consumes.punt_kwh]
  // ]

  const dataConsumes=[  ]
 
  consumes.consumes.forEach(element=>{
    dataConsumes.push((element).filter( e=> e!=='kW' && e!=='kWh'))
  })  

  const labels = rateHorary === 1 
    ? [
      intl.formatMessage({ id: 'app.charge.board.demandAndConsumpChart.dataSet.labels.base' }),
      intl.formatMessage({ id: 'app.charge.board.demandAndConsumpChart.dataSet.labels.interm' }),
      intl.formatMessage({ id: 'app.charge.board.demandAndConsumpChart.dataSet.labels.tip' })
    ]
    : [intl.formatMessage({id: 'app.charge.board.demandAndConsump.title'})];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'kW',
        yAxisID: 'A',
        backgroundColor: rateHorary === 1 ? ['#8CBA42', '#ebc057', '#ED685E'] : ['#00AFEF'],
        data: getKw, 
      },
      {
        label: 'kWh',
        yAxisID: 'B',
        backgroundColor: rateHorary === 1 ? ['#8CBA42', '#ebc057', '#ED685E'] : ['#A53693'],
        data: getKwh, 
      }
    ]
  };

  const options = { responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    },
    layout: { padding: { top:20 }  },
    scales: {
      xAxes: [
        { 
          
          barPercentage: 0.9,
          categoryPercentage: 0.8,
          ticks: {
            fontColor: palette.text.secondary,
            fontSize: 16,
            padding: 20
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position:'left',
          ticks: {
            fontColor: '#730065',
            beginAtZero: true,
            min: 0,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        },
        {
          id: 'B',
          position:'right',
          ticks: {
            fontColor: '#0080BC',
            beginAtZero: true,
            min: 0,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'top';
        ctx.font = '16px Lato, Helvetica Neue, Arial, 700';

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            if( data ){
              ctx.fillText(data.toLocaleString('en-US'), bar._model.x, bar._model.y -20 );
            }

          });
        });
      }
    }

  };


  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={rateHorary === 1 ? intl.formatMessage({id: 'app.charge.board.demandAndConsumpChart.title'}) : intl.formatMessage({id: 'app.charge.board.demandAndConsump.title'})} />
        <PortletToolbar>
          {/*  */}
        </PortletToolbar>
      </PortletHeader>
      <PortletContent>
        {getKw !== 0 && getKwh !== 0 ? (            
          <div className={classes.chartWrapper}>

            <Bar
              data={data}
              options={options}
            />
            {rateHorary === 1 ? (
              <div
                className={classes.labelCharts}
                style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}
              >
                <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '2rem'}}>
                  <Typography>kW</Typography>
                  <Typography>kWh</Typography>
                </div>
                <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                  <Typography>kW</Typography>
                  <Typography>kWh</Typography>
                </div>
                <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingRight: '4rem'}}>
                  <Typography>kW</Typography>
                  <Typography>kWh</Typography>
                </div>
              </div>
            ):(
              <div
                className={classes.labelCharts}
                style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}
              >
                <div style={{ width: '100%',display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '2rem'}}>
                  <Typography>{intl.formatMessage({id: 'app.charge.board.mainHead.maxDemand'})}</Typography>
                  <Typography>{intl.formatMessage({id: 'app.charge.board.highConsumDayCurrMonth.consumption'})}</Typography>
                </div>
              </div>
            )}
            
          </div>
        ):(
            <>
              <CircularProgress />
            </>
        )}
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
    </Portlet>
  );
}
