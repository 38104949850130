import React from 'react';

import ReactPlayer from 'react-player';

// Externals
import classNames from 'classnames';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// Material helpers
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(styles);


function VideoPlayer(props) {
  const classes = useStyles()
  const { className, currentVideo, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader>
        <PortletLabel title={currentVideo ? currentVideo.title : 'Ayuda'}/>
      </PortletHeader>
      <PortletContent noPadding>
        {currentVideo !== null ? (
          <ReactPlayer
            className={classes.video}
            controls
            height="100%"
            playing
            url={currentVideo.videoUrl}
            width="100%"
          />
        ):(
          <div
            className={classes.video}
            style={{
              padding: '5rem 10rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              alt="Metimur Logo"
              height="100%"
              src="./images/_logoSemillero.jpg"
              width="100%"
            />
          </div>
        )}
      </PortletContent>
      {currentVideo && (
        <PortletFooter className={classes.portletFooter}>
          {currentVideo.description}
        </PortletFooter>
      )}
    </Portlet>
  )
}

export default VideoPlayer;
