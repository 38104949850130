import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Add, Edit, Remove } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const FromStrepper = (props) => {
  const intl = useIntl();
  const { formData, setFormData, selectedCharge } = props;
  const [period, setPeriod] = useState({ start: '', end: '', notify_me_at: '' });
  const [contact, setContact] = useState({ name: '', phone: '' });
  const [editingPeriodIndex, setEditingPeriodIndex] = useState(null);
  const [editingContactIndex, setEditingContactIndex] = useState(null);
  const [country, setCountry] = useState('mexico');
  const [formatPhone, setFormatPhone] = useState('');

  const timeSelect = [
    '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM',
    '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM',
    '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM',
    '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM',
    '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM',
    '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
    '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM',
    '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM',
    '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM',
    '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM',
    '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM',
    '11:00 PM', '11:30 PM', '12:00 AM', '12:30 AM',
  ];

  const countryCodes = {
    colombia: { code: '+57', maxLength: 10 },
    mexico: { code: '+52', maxLength: 10 },
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setFormatPhone('');
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      resource_id: selectedCharge.id, // Actualizar el resource_id dinámicamente
    }));
  }, [selectedCharge]);

  const days = [
    intl.formatMessage({ id: 'app.daysOfWeek.sun' }),
    intl.formatMessage({ id: 'app.daysOfWeek.mon' }),
    intl.formatMessage({ id: 'app.daysOfWeek.tue' }),
    intl.formatMessage({ id: 'app.daysOfWeek.wen' }),
    intl.formatMessage({ id: 'app.daysOfWeek.thu' }),
    intl.formatMessage({ id: 'app.daysOfWeek.fri' }),
    intl.formatMessage({ id: 'app.daysOfWeek.sat' }),
  ]
  // Manejo de periodos
  const handleAddOrUpdatePeriod = () => {
    if (period.start && period.end && period.notify_me_at) {
      setFormData((prev) => {
        const updatedPeriods = [...prev.frecuency_config.periods];
        if (editingPeriodIndex !== null) {
          updatedPeriods[editingPeriodIndex] = period;
        } else {
          updatedPeriods.push(period);
        }
        return {
          ...prev,
          frecuency_config: { ...prev.frecuency_config, periods: updatedPeriods },
        };
      });
      setPeriod({ start: '', end: '', notify_me_at: '' });
      setEditingPeriodIndex(null);
    }
  };

  const handlePeriodChange = (field) => (e) => {
    const value = e.target ? e.target.value : 0;

    const toMilitaryTime = (time12h) => {
      // Divide la hora en [hora:minuto] y el meridiano (AM/PM)
      const [time, meridian] = time12h.split(' ');
      let [hours, minutes] = time.split(':').map(Number);
    
      // Si es PM y no es 12 PM, suma 12 a la hora
      if (meridian === 'PM' && hours !== 12) {
        hours += 12;
      }
    
      // Si es AM y es 12 AM, cambia la hora a 0
      if (meridian === 'AM' && hours === 12) {
        hours = 0;
      }
    
      // Formatea la hora para que siempre tenga 2 dígitos
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    if (value) {
      setPeriod((prev) => ({ ...prev, [field]: toMilitaryTime(value) }));
    }
  };

  const to12HourFormat = (militaryTime) => {
    const [hours, minutes] = militaryTime.split(':').map(Number);
    const meridian = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;

    return `${String(adjustedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${meridian}`;
  };

  const handleContactChange = (field) => (e) => {
    const value = e.target.value;
  
    if (field === 'phone') {
      // Agregar automáticamente el prefijo +52 si no está presente
      const rawPhone = e.target.value.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos
      let formattedPhone = rawPhone;
      setContact((prev) => ({ ...prev, phone: countryCodes[country].code + rawPhone }));
      
      // Formatear el número según el país seleccionado
      if (country === 'colombia') {
        formattedPhone = rawPhone.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3'); // Formato (NNN) NNN-NNNN
      } else if (country === 'mexico') {
        formattedPhone = rawPhone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3'); // Formato (NN) NNNN-NNNN
      }

      // Limitar la longitud del número según el país
      if (rawPhone.length <= countryCodes[country].maxLength) {
        setFormatPhone(formattedPhone);
      }
      
    } else {
      setContact((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleDayChange = (dayIndex) => {
    setFormData((prev) => {
      const isSelected = prev.frecuency_config.days.includes(dayIndex);
      const updatedDays = isSelected
        ? prev.frecuency_config.days.filter((d) => d !== dayIndex) // Eliminar día
        : [...prev.frecuency_config.days, dayIndex]; // Agregar día
      return {
        ...prev,
        frecuency_config: {
          ...prev.frecuency_config,
          days: updatedDays,
        },
      };
    });
  };  

  const handleEditPeriod = (index) => {
    setPeriod(formData.frecuency_config.periods[index]);
    setEditingPeriodIndex(index);
  };

  const handleRemovePeriod = (index) => {
    setFormData((prev) => ({
      ...prev,
      frecuency_config: {
        ...prev.frecuency_config,
        periods: prev.frecuency_config.periods.filter((_, i) => i !== index),
      },
    }));
  };

  // Manejo de contactos
  const handleAddOrUpdateContact = () => {
    if (contact.name && contact.phone) {
      setFormData((prev) => {
        const updatedContacts = [...prev.contacts];
        if (editingContactIndex !== null) {
          updatedContacts[editingContactIndex] = contact;
        } else {
          updatedContacts.push(contact);
        }
        return { ...prev, contacts: updatedContacts };
      });
      setContact({ name: '', phone: '' });
      setEditingContactIndex(null);
    }
  };

  const handleEditContact = (index) => {
    setContact(formData.contacts[index]);
    setEditingContactIndex(index);
  };

  const handleRemoveContact = (index) => {
    setFormData((prev) => ({
      ...prev,
      contacts: prev.contacts.filter((_, i) => i !== index),
    }));
  };

  const formatInternationalNumber = (phoneNumber) => {
    if (!phoneNumber.startsWith('+')) {
      throw new Error('El número debe incluir el prefijo \'+\' y el código de país.');
    }
  
    const mexicoRegex = /^\+52(\d{2})(\d{4})(\d{4})$/; // México: +52
    const colombiaRegex = /^\+57(\d{3})(\d{3})(\d{4})$/; // Colombia: +57
  
    if (mexicoRegex.test(phoneNumber)) {
      const [, areaCode, firstPart, secondPart] = phoneNumber.match(mexicoRegex);
      return `+52 (${areaCode}) ${firstPart}-${secondPart}`; // Ejemplo: +52 (81) 8476-9522
    } else if (colombiaRegex.test(phoneNumber)) {
      const [, areaCode, firstPart, secondPart] = phoneNumber.match(colombiaRegex);
      return `+57 (${areaCode}) ${firstPart}-${secondPart}`; // Ejemplo: +57 (300) 123-4567
    } else {
      throw new Error('Número no reconocido como mexicano o colombiano.');
    }
  };

  return (
    <Box style={{ padding: 4, }}>
      {/* Periodos */}
      <Box style={{ marginBottom: '1rem' }}>
        <Typography variant="h6">Periodos</Typography>
        {formData.frecuency_config.periods.map((p, index) => (
          <Grid
            alignItems="center"
            container
            key={index}
            spacing={2}
          >
            <Grid
              item
              xs={3}
            >
              <Typography>{`Inicio: ${to12HourFormat(p.start)}`}</Typography>
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Typography>{`Final: ${to12HourFormat(p.end)}`}</Typography>
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Typography>{`Mandar notificación: ${to12HourFormat(p.notify_me_at)}`}</Typography>
            </Grid>
            <Grid
              item
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
              xs={3}
            >
              <Button
                color="primary"
                onClick={() => handleEditPeriod(index)}
                startIcon={<Edit />}
                style={{width: '45%'}}
                variant="contained"
              >
                Editar
              </Button>
              <Button
                color="secondary"
                onClick={() => handleRemovePeriod(index)}
                startIcon={<Remove />}
                style={{width: '45%'}}
                variant="contained"
              >
                Quitar
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="start-time-label">Hora de Inicio</InputLabel>
              <Select
                id="start-time-select"
                labelId="start-time-label"
                onChange={handlePeriodChange('start')}
                style={{ marginRight: 8 }}
                value={period.start ? to12HourFormat(period.start) : ''}
              >
                {timeSelect.map((time) => (
                  <MenuItem
                    key={time}
                    value={time}
                  >
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> 
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="end-time-label">Hora de Fin</InputLabel>
              <Select
                id="end-time-select"
                labelId="end-time-label"
                onChange={handlePeriodChange('end')}
                style={{ marginRight: 8 }}
                value={period.end ? to12HourFormat(period.end) : ''}
              >
                {timeSelect.map((time) => (
                  <MenuItem
                    key={time}
                    value={time}
                  >
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> 
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="notify_me_at-time-label">Hora de Notificación</InputLabel>
              <Select
                id="notify_me_at-time-select"
                labelId="notify_me_at-time-label"
                onChange={handlePeriodChange('notify_me_at')}
                style={{ marginRight: 8 }}
                value={period.notify_me_at ? to12HourFormat(period.notify_me_at) : ''}
              >
                {timeSelect.map((time) => (
                  <MenuItem
                    key={time}
                    value={time}
                  >
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> 
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Button
              color="secondary"
              disabled={!period.start || !period.end || !period.notify_me_at}
              onClick={handleAddOrUpdatePeriod}
              startIcon={editingPeriodIndex !== null ? <Edit /> : <Add />}
              style={{width: '100%'}}
              variant="contained"
            >
              {editingPeriodIndex !== null ? 'Actualizar' : 'Agregar Periodo'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Días */}
      <Box style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Typography variant="h6">Días</Typography>
        <Grid
          container
          spacing={2}
        >
          {days.map((day, index) => (
            <Grid
              item
              key={index}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.frecuency_config.days.includes(index)}
                    onChange={() => handleDayChange(index)}
                  />
                }
                label={day}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Contactos */}
      <Box style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Typography variant="h6">Contactos</Typography>
        {formData.contacts.map((c, index) => (
          <Grid
            alignItems="center"
            container
            key={index}
            spacing={2}
          >
            <Grid
              item
              xs={5}
            >
              <Typography>{`Nombre: ${c.name}`}</Typography>
            </Grid>
            <Grid
              item
              xs={5}
            >
              <Typography>{`Teléfono: ${formatInternationalNumber(c.phone)}`}</Typography>
            </Grid>
            <Grid
              item
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
              xs={2}
            >
              <Button
                color="primary"
                onClick={() => handleEditContact(index)}
                startIcon={<Edit />}
                style={{width: '45%'}}
                variant="contained"
              >
                Editar
              </Button>
              <Button
                color="secondary"
                onClick={() => handleRemoveContact(index)}
                startIcon={<Remove />}
                style={{width: '45%'}}
                variant="contained"
              >
                Quitar
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={5}
          >
            <TextField
              label="Nombre"
              onChange={handleContactChange('name')}
              style={{width: '100%'}}
              value={contact.name}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <TextField
              fullWidth
              inputProps={{
                maxLength: countryCodes[country].maxLength,  // Limitar la cantidad de caracteres permitidos
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FormControl>
                      <Select
                        value={country}
                        onChange={handleCountryChange}
                        style={{ marginRight: 8 }}
                      >
                        <MenuItem value="colombia">COL +57</MenuItem>
                        <MenuItem value="mexico">MX +52</MenuItem>
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
              label="Número Telefónico"
              onChange={handleContactChange('phone')}
              value={editingContactIndex ? (formData.contacts[editingContactIndex].phone).substring(3) : formatPhone}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <Button
              color="secondary"
              disabled={!contact.name || !contact.phone}
              onClick={handleAddOrUpdateContact}
              startIcon={editingContactIndex !== null ? <Edit /> : <Add />}
              style={{width: '100%'}}
              variant="contained"
            >
              {editingContactIndex !== null ? 'Actualizar' : 'Agregar Contacto'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FromStrepper;